import * as React from 'react';
import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import { ASLinkModel } from './LinkModel';
import { ASLinkWidget } from './LinkWidget.js';

export class ASLinkFactory extends DefaultLinkFactory {
	constructor() {
		super('advanced');
	}

	generateModel() {
		return new ASLinkModel();
	}

	generateReactWidget(event) {
		return <ASLinkWidget link={event.model} diagramEngine={this.engine} />;
	}
}