import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import AscAudio from "../../containers/Elements/Audio/Audio";
import ASSelect from "../Elements/Select/Select";
import Type from "./Type.json";

export default class BusinessSetting extends Component {
    constructor(props) {
        super(props);
        this.read = this.props.read === true;

        this.script_select = this.props.scriptSelect || [];
        this.voice_select = this.props.voiceSelect || [];

        this.hour_list = [
            {value :"00",label:"00"},
            {value :"01",label:"01"},
            {value :"02",label:"02"},
            {value :"03",label:"03"},
            {value :"04",label:"04"},
            {value :"05",label:"05"},
            {value :"06",label:"06"},
            {value :"07",label:"07"},
            {value :"08",label:"08"},
            {value :"09",label:"09"},
            {value :"10",label:"10"},
            {value :"11",label:"11"},
            {value :"12",label:"12"},
            {value :"13",label:"13"},
            {value :"14",label:"14"},
            {value :"15",label:"15"},
            {value :"16",label:"16"},
            {value :"17",label:"17"},
            {value :"18",label:"18"},
            {value :"19",label:"19"},
            {value :"20",label:"20"},
            {value :"21",label:"21"},
            {value :"22",label:"22"},
            {value :"23",label:"23"},
        ];
        // 分
        this.min_list = [
            {value :"00",label:"00"},
            {value :"05",label:"05"},
            {value :"10",label:"10"},
            {value :"15",label:"15"},
            {value :"20",label:"20"},
            {value :"25",label:"25"},
            {value :"30",label:"30"},
            {value :"35",label:"35"},
            {value :"40",label:"40"},
            {value :"45",label:"45"},
            {value :"50",label:"50"},
            {value :"55",label:"55"},
        ];

        this.state = {
            start_hour: this.props.state.business_start_hour && this.props.state.business_start_hour.length === 4 ? this.props.state.business_start_hour.slice(0, 2) : null,
            start_min: this.props.state.business_start_hour && this.props.state.business_start_hour.length === 4 ? this.props.state.business_start_hour.slice(2) : null,
            end_hour: this.props.state.business_end_hour && this.props.state.business_end_hour.length === 4 ? this.props.state.business_end_hour.slice(0, 2) : null,
            end_min: this.props.state.business_end_hour && this.props.state.business_end_hour.length === 4 ? this.props.state.business_end_hour.slice(2) : null
        };
    }

    getSelect(create_string) {
        let select = [];
        for (let key in create_string) {
            select.push({value: key, label: create_string[key]});
        }
        return select;
    }

    getDetailElem = (type) => {
        let action_param;
        let detail_param;
        let voice_param;
        let action_label;
        let voice_list = this.props.voiceSelect || [];
        let script_list = this.props.scriptSelect || [];
        let voice_obj;

        if (type === Type.BUSINESS.START) {
            action_param = "business_hour_action_flag";
            detail_param = "business_hour_action_detail";
            voice_param = "business_hour_voice_file_id";
            action_label = this.props.langText.Body.BusinessHourInBehavior;
        } else {
            action_param = "non_business_hour_action_flag";
            detail_param = "non_business_hour_action_detail";
            voice_param = "non_business_hour_voice_file_id";
            action_label = this.props.langText.Body.BusinessHourOutBehavior;
        }

        voice_obj = voice_list.find(row => row.value === this.props.state[voice_param]);

        return <>
            <ASFieldGroup
                key={action_param}
                label={action_label}
                validationState={this.validationHandle(action_param)}>
                <ASSelect
                    readOnly={this.read}
                    value={this.props.state[action_param]}
                    options={this.getSelect(this.props.actionString)}
                    placeholder={this.props.langText.SelectOption.Placeholder}
                    onChange={e => {
                        this.props.propSetState({
                            [action_param]: e.value,
                            [voice_param]: null,
                            [detail_param]: null
                        });
                    }}
                    isDisabled={this.read}/>
            </ASFieldGroup>

            {this.props.state[action_param] === Type.ACTION.VOICE &&
                <ASFieldGroup
                    key={voice_param}
                    validationState={this.validationHandle(voice_param)}>
                    <Col xs={12} sm={12} style={{paddingLeft: "0"}}>
                        <Col xs={6} sm={6} md={11} lg={11} style={{paddingLeft: "0"}}>
                            <ASSelect
                                readOnly={this.read}
                                style={{width: `100px`}}  
                                value={this.props.state[voice_param]}
                                options={voice_list}
                                placeholder={this.props.langText.SelectOption.Placeholder}
                                onChange={e => {
                                    this.props.propSetState({[voice_param]: e.value});
                                }}
                                isDisabled={this.read}/>
                        </Col>
                        <Col xs={6} sm={6} md={1} lg={1} style={{paddingLeft: "0"}}>
                            {voice_obj &&
                                <AscAudio
                                    key={`${voice_param}-play-audio`}
                                    type="url"
                                    data={voice_obj.url}
                                    className = "set_audio_button_business_calendar" />
                            }
                        </Col>
                    </Col>
                </ASFieldGroup>
            }

            {this.props.state[action_param] === Type.ACTION.SCRIPT &&
                <ASFieldGroup
                    key={detail_param}
                    validationState={this.validationHandle(detail_param)}>
                    <ASSelect
                        readOnly={this.read}
                        value={this.props.state[detail_param]}
                        options={script_list}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                        onChange={e => {
                            this.props.propSetState({[detail_param]: e.value});
                        }}
                        isDisabled={this.read}/>
                </ASFieldGroup>
            }
        </>;
    }

    validationHandle = (param) => {
        switch(param) {
            case "start_hour":
            case "start_min":
            case "end_hour":
            case "end_min":
                return this.read || this.state[param] ? null : this.props.langText.Message.Validation_Select_input;
            case "type":
            case "business_hour_action_flag":
            case "non_business_hour_action_flag":
            case "business_hour_action_detail":
            case "non_business_hour_action_detail":
            case "business_hour_voice_file_id":
            case "non_business_hour_voice_file_id":
                return this.read || this.props.state[param] ? null : this.props.langText.Message.Validation_Select_input;
            default:
                return false;
        }
    }

    render() {
        return <>
            <ASFieldGroup
                key="type"
                label={this.props.langText.Body.Class}
                validationState={this.validationHandle("type")}>
                <ASSelect
                    readOnly={this.read}
                    value={this.props.state.type || ""}
                    options={this.getSelect(this.props.businessString)}
                    placeholder={this.read ? "" : this.props.langText.SelectOption.Placeholder}
                    onChange={e => {
                        if (this.props.state.type !== e.value) {
                            this.props.propSetState({
                                type: e.value,
                                business_start_hour: null,
                                business_end_hour: null,
                                business_hour_action_flag: null,
                                business_hour_action_detail: null,
                                business_hour_voice_file_id: null,
                                non_business_hour_action_flag: null,
                                non_business_hour_action_detail: null,
                                non_business_hour_voice_file_id: null
                            });
                            this.setState({
                                start_hour: null,
                                start_min: null,
                                end_hour: null,
                                end_min: null
                            });
                        }
                    }}
                    isDisabled={this.read}/>
            </ASFieldGroup>

            {this.props.state.type === Type.BUSINESS.START &&
                <>
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={5}>
                            <Col xs={10} sm={10} md={4} lg={4}>
                                <ASFieldGroup
                                    key="start_hour"
                                    validationState={this.validationHandle("start_hour")}>
                                    <ASSelect
                                        readOnly={this.read}
                                        className="hour-select"
                                        value={this.state.start_hour}
                                        options={this.hour_list}
                                        placeholder="---"
                                        onChange={e => {
                                            if (this.state.start_min) {
                                                this.props.propSetState({business_start_hour: `${e.value}${this.state.start_min}`});
                                            }
                                            this.setState({start_hour: e.value});
                                        }}
                                        isDisabled={this.read}/>
                                </ASFieldGroup>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                            </Col>
                            <Col xs={10} sm={10} md={4} lg={4}>
                                <ASFieldGroup
                                    key="start_min"
                                    validationState={this.validationHandle("start_min")}>
                                    <ASSelect
                                        readOnly={this.read}
                                        className="hour-select"
                                        value={this.state.start_min}
                                        options={this.min_list}
                                        placeholder="---"
                                        onChange={e => {
                                            if (this.state.start_hour) {
                                                this.props.propSetState({business_start_hour: `${this.state.start_hour}${e.value}`});
                                            }
                                            this.setState({start_min: e.value});
                                        }}
                                        isDisabled={this.read}/>
                                </ASFieldGroup>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                            </Col>
                        </Col>
                        <Col xs={12} sm={12} md={2} lg={2}>
                            <span className="calendar-Modal_TimeCss">~</span>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5}>
                            <Col xs={10} sm={10} md={4} lg={4}>
                                <ASFieldGroup
                                    key="end_hour"
                                    validationState={this.validationHandle("end_hour")}>
                                    <ASSelect
                                        readOnly={this.read}
                                        className="hour-select"
                                        value={this.state.end_hour}
                                        options={this.hour_list}
                                        placeholder="---"
                                        onChange={e => {
                                            if (this.state.end_min) {
                                                this.props.propSetState({business_end_hour: `${e.value}${this.state.end_min}`});
                                            }
                                            this.setState({end_hour: e.value});
                                        }}
                                        isDisabled={this.read}/>
                                </ASFieldGroup>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                            </Col>
                            <Col xs={10} sm={10} md={4} lg={4}>
                                <ASFieldGroup
                                    key="end_min"
                                    validationState={this.validationHandle("end_min")}>
                                    <ASSelect
                                        readOnly={this.read}
                                        className="hour-select"
                                        value={this.state.end_min}
                                        options={this.min_list}
                                        placeholder="---"
                                        onChange={e => {
                                            if (this.state.end_hour) {
                                                this.props.propSetState({business_end_hour: `${this.state.end_hour}${e.value}`});
                                            }
                                            this.setState({end_min: e.value});
                                        }}
                                        isDisabled={this.read}/>
                                </ASFieldGroup>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                            </Col>
                        </Col>
                    </Row>

                    {this.getDetailElem(Type.BUSINESS.START)}
                </>
            }

            {(this.props.state.type === Type.BUSINESS.START || this.props.state.type === Type.BUSINESS.END) && this.getDetailElem(Type.BUSINESS.END)}
        </>;
    }
}