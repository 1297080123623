export default {
    // 成功
    DataInsertSuccess: "登録しました。",
    DataUpdateSuccess: "更新しました。",
    DataDeleteSuccess: "削除しました。",
    DataSettingSuccess:"設定しました。",

    // 件数
    SuccessDataCount: "成功件数：{0}",
    FailedDataCount: "失敗件数：{0}",

    // 行
    Line: "{0}行：",

    // エラー
    ModalTypeError:	    "送信情報に不具合があり、実行に失敗しました。",
    AuthCheckError:	    "権限が無いため、実行に失敗しました。",
    DataSelectError:    "取得に失敗しました。",
    DataInsertError:    "登録に失敗しました。",
    DataUpdateError:    "更新に失敗しました。",
    DataDeleteError:    "削除に失敗しました。",
    DataSettingError:   "設定に失敗しました。",
    CompanySelectError:	"会社情報が無いため、情報取得に失敗しました。",
    FindDataError:	    "必要な情報が不足しているため、実行に失敗しました。",
    GetDataError:       "データの取得に失敗しました。管理者に問い合わせください。",
    ExecutionFailedError_Placeholder:        "{0}の実行に失敗しました。管理者にお問い合わせください。",
    ProcessingFailedError: "処理に失敗しました。管理者にお問い合わせください。",
    NoDataDeleteError:    "削除する項目がありません。",

    // 画面個別設定
    // ユーザー
    User_DataInsertSuccess: "を登録いたしました。",
    User_InsertRegisteredUserError: "既存ユーザーか過去に登録があるため、登録できませんでした。",
    User_InvalidParameterError: "メールアドレス または パスワード のフォーマットが正しくないため、登録できませんでした。",
    // ログイン
    SignIn_VerifyLink: "ユーザー認証はこちら",
    SignIn_ResetPasswordLink: "パスワードをお忘れの方はこちら",
    SignIn_SignInFailed: "ユーザーIDまたはパスワードが違います。",
    SignIn_NotMatchCodeOrExpiredError: "入力されたコードは間違っているか有効期限が切れています。",
    SignIn_NotMatchCodeError: "入力されたコードが間違っています。",
    SignIn_ExecutionFailedError: "実行に失敗しました。",
    SignIn_UserNotFoundError: "入力されたメールアドレスは登録されていません。",
    SignIn_TooMuchFailsError: "認証エラーの回数が上限を超えました。再度コードを発行してください。",
    SignIn_NotConfirmedUserError: "未認証のユーザーです。ユーザー認証を行ってください。",
    SignIn_VerifySuccess: "認証に成功しました。",
    SignIn_SentVerificationCode: "認証コードを送信しました。",
    SignIn_ResetPasswordSuccess: "パスワードの再設定に成功しました。",
    SignIn_ConfirmResend: "再送信しますか？",
    NoDepartmentError: "所属している拠点またはフロアがありません。\n管理者に所属変更依頼を出してください。",

    // 顧客管理
    Customer_overlappedTelNoError: "{0}番号はすでに登録されているため、追加できません。",
    Customer_overCsvRow: "一括で登録は100件までになります。",
    Customer_telPregCheck: "{0}番号の形は登録できません。",
    // 内線番号管理
    Extension_XnumUpdateClear: "件更新完了しました。",
    Extension_SequenceSame: "下記の内線番号が重複しております。違う内線番号で作成してください。\r\n{0}",
    Extension_SequenceNumberDigits: "数字３桁で入力して下さい。空白は自動採番、３桁未満は先頭０埋めで登録されます。",
    Omniphone_Login_Status_delete:"こちらのログアウト機能はオムニフォンログイン時にログイン情報が残っていてログインできない（内線番号が選択できない）場合にのみ使用してください。\r\n\r\n「OK」ボタンを押下するとオムニフォンのログイン状態が強制的にリセットされます。\r\n\r\n※ 該当内線番号にてログイン状態のオムニフォンがある場合\r\n　 自動的にログアウトされませんのでご注意ください\r\n※ 複数のオムニフォンで同一内線番号にてログインした場合\r\n　 正常に発信・着信ができなくなりますのでご注意ください\r\n",
    Omniphone_Login_Status_delete_result: "ログイン状態をリセットしました。",
    // グループ番号管理
    GroupExtensionNum_overlappedNumberError: "グループ番号{0}はすでに登録されているため、追加できません。",

    // ユーザー管理画面
    User_Delete_Check: "本当に削除しますか?",

    // 共通_ファイルアップロード時
    Upload_sizeLimit: "指定ファイルはサイズが大きすぎるため、アップロードできません。\n {0}のファイルを選択してください。 ",
    //外線設定、拠点設定GW選択してない場合
    ExternalNumber_GwNoSelect: "GWを選択してください。",
    //外線設定、拠点設定10個
    ExternalNumber_DepartmentLimit10: "拠点設定は10個までです。",
    ExternalNumber_SameFloorError:"同じフロアは選択できません。",

    Download_PopupblockCheck: "ダウンロードエラーが発生しました。\n・ご使用のブラウザのポップアップブロックを無効に設定してください。\n・それでも解決しない場合は、時間をおいて実行するか、システム管理者までお問合せください。",
    Download_Failed: "通話履歴が0件です。\n検索条件をご確認ください。",
    File_Download_Failed: "ダウンロードに失敗しました。時間をおいて再度行うか、\n別拡張子でのダウンロードをお願いします。",
    //IEの場合音声アップロード
    Format_Not_Support: "※フォーマットによっては再生できないファイルがあります。",

    //PW関連
    Password_Validation: "英小文字・数字の組み合わせ(8桁以上)で入力してください。",
    Password_Error: "旧パスワードが違います。",

    Email_Format: "メールアドレスを入力してください。例）email@gmail.com",

    // 権限管理
    Permission_BasePermissionDeleteError: "基本権限のため削除できません。",
    Permission_UserCountDeleteError: "利用ユーザーがいる場合は削除できません。",

    UserNotFoundException:"ユーザーが存在しません。システム管理者までお問合せください。",
    TooManyRequestsException:"リクエストが多かったため、削除ができませんでした。後でお試しください。",
    UserNotConfirmedException:"確認されなかったアカウントです。",
    // Validation
    Validation_string_input: `文字を入力してください。`,
    Validation_Number_input: `数字を入力してください。`,
    Validation_Select_input: `項目を選んでください。`,
    Validation_File_input: `ファイルを選んでください。`,
    SignIn_UserNotFoundException:"ユーザーが存在しません。システム管理者までお問合せください。",
    PasswordResetRequiredException:"パースワードがリセットされました。システム管理者までお問合せください。",
    Validation_Telno: `電話番号を入力してください。`,

    //営業時間管理
    Preview_add:"※まだ登録されていません※",
    Preview_message1:"下記の内容で一括設定を行います。",
    Preview_message2:"問題がなければ登録ボタンを押してください。",
    IrrDeleteSuccess:"イレギュラーが削除されました。",
    IrrInsertSuccess:"イレギュラーが登録・更新されました。",

    // 通話履歴
    CallHistory_FindDataError: "開始日・相手先番号のいずれかを入力してください。",
    
    //スクリプト利用中メッセージ
    ScriptUsedMsg: "このスクリプトは利用中のため、削除や編集はできません。営業時間管理をご確認ください。",
    NoMemoMessage:"※該当バージョンはメモがありません。",
    CallChargeMessage: "※通話料金が発生します。",

    Script_input_check_help: `繰返確認時の音声は、入力値読み上げ後の音声を設定してください。設定した正番号の確認も音声に入れてください。012345でよろしいですか。」の場合、「でよろしければ１を、間違っていれば２を押してください。」`,
    DefaultAudioUse: "デフォルト音声を使用します。",
    NoneAudio: "音声なし",

    Default_value_auto: `デフォルトが設定されます。`,
    Start_node_every_input: `開始に繋がっているアイテムの設定は必ず必要です。`,
    Start_node_nothing: `開始と繋がったアイテムがない為、保存できません。\r\n開始とアイテムを繋がった後、保存してください。`,

    DB_Update_Data_Search_None: `更新するデータが存在しません。`,

    InputDataResetBack: `前の画面に戻ります。\r\n※入力（設定）内容が全部消えます。`,
    UserBelongedDepartmentMessage: "※この拠点には{0}人が所属しています。削除すると所属ユーザーでログインができなくなります。",
    UserBelongedFloorMessage: "※このフロアには{0}人が所属しています。削除すると所属ユーザーでログインができなくなります。"

}
