import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import PermissionDeleteModal from "./PermissionDeleteModal";
import PermissionInsertModal from "./PermissionInsertModal";
import PermissionCopyModal from "./PermissionCopyModal";
import PermissionUpdateModal from "./PermissionUpdateModal";
import PermissionReadModal from "./PermissionReadModal";
import * as GlobalConst from '../../components/AscConstants';


export default class Permission extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            loading: false,
            filtered: [],

            // modal item
            show: false,
            modalType: null,

            companySelect: [],

        };
    }

    /**
     * 一覧表作成
     */
    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Cell: data => {
                    // 基本権限以外なら会社名を表示
                    if (data.row._original.base_flag === "Y") {
                        return this.props.langText.Body.BasePermissionName
                    } else {
                        return data.row._original.cm12_companies.company_name
                    }
                },
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value,
                    "cm12_companies.company_name")} />
            }, {
                Header: this.props.langText.Body.PermissionName,
                accessor: "name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "name")} />
            }, {
                Header: this.props.langText.Body.UserCount,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.smedium,
                accessor: "cm15_users.user_count",
                Cell: data => {
                    if (data.row._original.cm15_users) {
                        return data.row._original.cm15_users.user_count
                    } else {
                        return 0
                    }
                },
            }, {
                Header: this.props.langText.Body.UpdateUserName,
                accessor: "update_user_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value,
                    "update_user_name")} />
            }, {
                Header: this.props.langText.Body.Modified,
                accessor: "modified",
                filterable: false,
                width: this.props.boardWidth.Date,
                Cell: ({value}) => value ?
                    this.getMomentTime({format: this.props.langText.Body.DateFormat, date: value}) : "",
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.LargeControl,
                Cell: data => {
                    // 権限に応じて各アイコン表示
                    let rowData = [];
                    // 編集権限あれば編集ボタン、編集権限無ければ閲覧ボタン
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                className="control-button"
                                onClick={this.modalShow("update", data.row._original)}
                            >
                                <Glyphicon glyph="pencil"/>
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                className="control-button"
                                onClick={this.modalShow("read", data.row._original)}
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        );
                    }
                    if (this.props.currentPermission.create) {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                className="control-button"
                                onClick={this.modalShow("copy", data.row._original)}
                            >
                                <Glyphicon glyph="copy"/>
                            </Button>
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                className="control-button"
                                onClick={this.modalShow("delete", data.row._original)}
                            >
                                <Glyphicon glyph="minus"/>
                            </Button>
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        ]
    }

    /**
     * 各ボタンから新しいmodal表示する際に使用する関数
     */
    modalShow = (modalType, param) => event => {
        event.preventDefault();

        /**
         * stateに追加
         */
        let setData = {
            permission_id: param && param.id
                ? param.id
                : "",
            permission_name: param && param.name
                ? param.name
                : "",
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.base_flag === "Y"
                    ? this.props.langText.Body.BasePermissionName
                    : param.cm12_companies.company_name
                : "",
            base_flag: param && param.base_flag
                ? param.base_flag
                : "",
            user_count: param && param.cm15_users && param.cm15_users.user_count
                ? param.cm15_users.user_count
                : 0,
            modalType: modalType,
            show: true
        };

        /**
         * stateに権限詳細を追加
         */
        GlobalConst.CONTROLLER_LIST.forEach((row, index) => {
            if (param && param.json_data[index]) {
                setData[row] = param.json_data[index]
            } else {
                setData[row] = this.getPermissionReset(GlobalConst.CONTROLLER_LIST[index])
            }
        })

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.getCommonCompanySelect(this.reactContainerPath)
                .then(res => {
                    this.setState({companySelect: res.data})
                }).catch(err => this.showErrorObjectMesssage(err))
        }
        this.setState(setData);
    };

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => event => {
        let {
            permission_id,
            permission_name,
            company_id,
        } = this.state;

        let permissionList = []
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            permissionList.push(this.state[row])
        })

        switch (modalType) {
            case "copy":
            case "insert":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/insert`, {
                        permission_name,
                        company_id,
                        permissionList
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                this.setState({show: false})
                break
            case "delete":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/delete`, {
                        permission_id,
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataDeleteSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                this.setState({show: false})
                break
            case "update":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/update`, {
                        permission_id,
                        permission_name,
                        permissionList
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                this.setState({show: false})
                break
            default:
                alert(this.getErrorString({code: "modal"}))
        }
    };

    /**
     * 各画面のボタン押下条件
     */
    validationHandle = param => {
        let validation_flag = false;

        switch (param) {
            case "copy":
            case "update":
            case "insert":
                validation_flag = !!(this.state.company_id && this.state.permission_name);
                break
            case "delete":
                if (this.state.base_flag === "N" && this.state.user_count === 0) {
                    validation_flag = true
                }
                break
            case "read":
            default:
                break
        }

        return validation_flag;
    };

    /**
     * permissionData 初期化
     */
    getPermissionReset(controller_id) {
        return {
            all: false,
            edit: false,
            read: false,
            create: false,
            delete: false,
            export: false,
            playback: false,
            scope_code: "floor",
            controller_id: controller_id,
        };
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "delete":
                return (
                    <PermissionDeleteModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            case "insert":
                return (
                    <PermissionInsertModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onNestSetState = {this.onNestSetState}
                        onNestCheckBoxChange = {this.onNestCheckBoxChange}
                        validationHandle = {this.validationHandle}/>
                )
            case "update":
                return (
                    <PermissionUpdateModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onNestSetState = {this.onNestSetState}
                        onNestCheckBoxChange = {this.onNestCheckBoxChange}
                        validationHandle = {this.validationHandle}/>
                )
            case "copy":
                return (
                    <PermissionCopyModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onNestSetState = {this.onNestSetState}
                        onNestCheckBoxChange = {this.onNestCheckBoxChange}
                        validationHandle = {this.validationHandle}/>
                )
            case "read":
                return (
                    <PermissionReadModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}/>
                )
            default:
                break
        }
    }

    render() {
        return (
            <div className="Permission">
                {/*一覧画面*/}
                <Row>
                    <Col xs={12} md={12}>
                        {
                            this.props.currentPermission.create
                            &&
                            <Button
                                id = "permission-insert"
                                className = "table-button"
                                bsStyle = "default"
                                bsSize = "sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        }
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
