import React from "react";
import ReactTable from "react-table-6";
import "./CommonTable.css";
import "react-table-6/react-table.css";

// 参考：https://react-table.js.org/#/story/readme

export default ({
                    talbeRef,
                    className = "",
                    data,
                    columns,
                    defaultPageSize = 20,
                    page,
                    ...props
                }
                ) => {
    return (<ReactTable
        onSortedChange={(newSorted, column, shiftKey) => {
            props.page=0;
        }} 
        ref={talbeRef}
        className={`CommonTable ReactTable -striped -highlight ${className}`}
        data={data}
        columns={columns}
        page={page}
        defaultPageSize={defaultPageSize}
        {...props}
    />);
};