import { NodeModel, PortModelAlignment } from '@projectstorm/react-diagrams';
import { ASPortModel } from '../Port/PortModel';
import CallType from "../../../../../containers/Script/Template/CallType";

export class ASNodeModel extends NodeModel {
	constructor(options = {}, data, events) {
		super({
			type: "ivr",
			cdata: data
		});

		this.addPort(new ASPortModel(PortModelAlignment.LEFT));
		this.addPort(new ASPortModel(PortModelAlignment.RIGHT));

		this.settingOptions(options);
		this.settingEvents(events);
	}

	set widget(data) {
		this._widget = data;
	}

	get widget() {
		return this._widget;
	}

	set c_data(data) {
		this.getOptions().cdata = data;
	}

	get c_data() {
		return this.getOptions().cdata;
	}

	get multiple_link() {
		return this.getOptions().item === CallType.QUESTION;
	}

	get event() {
		return this._event;
	}

	set event(data) {
		this._event = data;
	}

	get models() {
		return Object.values(this.getParent().getModels() || {});
	}

	checkValidation(type, data) {
		return Object.values(this.getOptions().validation[type]).find(f => f(data || this.c_data)) ? false : true;
	}
	
	settingOptions(data) {
		let options = this.getOptions();

		for (const key in data) {
			options[key] = data[key];
		}
	}

	settingEvents(data) {
		for (const key in data) {
			this[key] = (...args) => {
				data[key](this, ...args);
			}
		}
	}

	targetData(target) {
		return target ? (Array.isArray(target) ? target : [target]) : [this];
	}

	widgetChangeColor(color, target) {
		this.targetData(target).forEach(row => {
			if (row.widget) {
				row.widget.setState({
					color: (color || row.widget.default_color),
					borderColor: (color ? "1px solid #818181 !important" : null)
				});
			}
		});
	}

	widgetSettingShow(flag, target) {
		this.targetData(target).forEach(row => {
			if (row.widget) {
				row.widget.setState({
					setting_flag: flag
				});
			}
		});
	}

	removeLink(link) {
		const diagram = this.getParent().getParent();
		const source_port = link.getSourcePort();
		const target_port = link.getTargetPort();
		const model = source_port.getParent();
		const nexts_index = model.c_data.nexts.findIndex(next => next.block_no === model.getOptions().number);

		if (nexts_index !== -1) {
			model.c_data.nexts.splice(nexts_index, 1);
		}

		// CanvasからLinkを削除
		source_port.removeLink(link);
		target_port.removeLink(link);
		diagram.removeLink(link);
	}

	removeLeftLink() {
		const ports = this.getPorts();
		const links = Object.values(ports.left.getLinks() || {});
		links.forEach(link => this.removeLink(link));
	}

	removeRightLink() {
		const ports = this.getPorts();
		const links = Object.values(ports.right.getLinks() || {});
		links.forEach(link => this.removeLink(link));
	}

	removeAllLink() {
		const ports = this.getPorts();
		const links = Object.values(ports.left.getLinks() || {}).concat(Object.values(ports.right.getLinks() || {}));
		links.forEach(link => this.removeLink(link));
	}
}