import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import ExternalNumberModal from './ExternalNumberModal'
import * as GlobalConst from "../../components/AscConstants";


export default class ExternalNumber extends AscComponent {
    constructor(props) {
        super(props)

        this.departmentInfoSet = this.departmentInfoSet.bind(this);
        this.getDepartmentSelect = this.getDepartmentSelect.bind(this);
        this.departmentInfos_onTextChange_Limit = this.departmentInfos_onTextChange_Limit.bind(this);

        // data
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            gatewaySelect: [],
            companySelect: [],
            departmentSelect: [],
            departmentSelectArr: [],
            departmentInfos: [],
            floorSelect: [],
            inboundTypeSelect: this.getSelectOption('inbound_group_type', this.props.langText.SelectOption),
            inboundGroupSelect: [],
            businessCalendarSelect: [],
            waitNumberSoundSelect: [],

            // data item
            external_id: '',
            gateway_id: [],
            gateway_name: '',
            company_id: '',
            company_name: '',
            department_id: '',
            department_name: '',
            floor_id: '',
            floor_name: '',
            display_number: '',
            back_number: '',
            channel_number: '',
            wait_number: '',
            sound_id: '',
            prefix: '',
            memo: '',
            inbound_type: '',
            inbound_group_id: [],
            inbound_group_name: '',
            business_calendar_id: '',
            business_calendar_name: '',
            inboundGroupcount: 0,
            // prefix_searchflag: true,
            // prefix_duplicationflag: false,
            inbound_group_base: [],
            inbound_group_cm51ids: [],

            // button_disabled
            buttondisabledArr: {
                "gateway_id" : [],
                "company_id" : [],
                "department_id" : [],
                "inbound_group_id" : [],
                "business_calendar_id" : [],
                "floor_id" : [],
                "sound_id" : [],
                "gateway_load" : [],
                "department_load" : [],
                "inbound_group_load" : [],
                "business_calendar_load" : [],
                "floor_load" : [],
                "sound_load" : []
            },
            omni_phone_back_ground_display_color_picker: false,
            omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color,
            omni_phone_font_display_color_picker: false,
            omni_phone_font_color: GlobalConst.omni_phone_font_color,
            before_omni_phone_back_ground_color: '',
            before_omni_phone_font_color: '',
        }
        this.floor_load_flag = false;
        this.group_load_flag_intype = false;
        this.group_load_flag_depart = false;
        this.gateway_load_flag = false;
    }

    getColumnsData() {
        let inboundGroupType = this.getSelectOption('inbound_group_type_by_search', this.props.langText.SelectOption);

        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //OEM権限以上は会社名を表示
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}
                />
            });
        }
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)){
            //会社管理者以上は拠点名を表示
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'ct62_assgin_external_department_infos.cm13_departments.department_name',
                // filterable: false,
                sortable: false,
                Cell: data => {
                    let elemArr = [];
                    data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                        elemArr.push(
                            <p key={key}>
                                {row.cm13_departments.department_name}
                            </p>
                        );
                    });
                    return (elemArr);
                },
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.cm13_departments.department_name")}
                />
            });
        }
        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            //システム管理者はSYSTEM_FLOOR_FLGがYの時はフロア名を表示
            //拠点管理者以上OEM以下は、所属会社がフロア管理している場合はフロア名を表示
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'ct62_assgin_external_department_infos.cm13_departments.floor_name',
                // filterable: false,
                sortable: false,
                Cell: data => {
                    let elemArr = [];
                    data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                        elemArr.push(
                            <p key={key}>
                                {row.cm13_departments.floor_name}
                            </p>
                        );
                    });
                    return (elemArr);
                },
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.cm13_departments.floor_name")}
                />
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.ExtensionName,
            accessor: 'memo',
            width: this.props.boardWidth.medium,
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "memo")}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.DisplayNumber,
            accessor: 'display_number',
            width: this.props.boardWidth.CallNumber,
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.PartialMatch}
                onChange={e => this.onFilterChange(e.target.value, "display_number")}
            />
        });
        // itemArr.push({
        //     Header: this.props.langText.Body.ChannelNumber,
        //     accessor: 'channel_number',
        //     Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "channel_number")} />
        // });
        // itemArr.push({
        //     Header: this.props.langText.Body.WaitNumber,
        //     accessor: 'wait_number',
        //     Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "wait_number")} />
        // });
        itemArr.push({
            Header: this.props.langText.Body.Prefix,
            accessor: 'ct62_assgin_external_department_infos.prefix',
            // filterable: false,
            sortable: false,
            Cell: data => {
                let elemArr = [];
                data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                    elemArr.push(
                        <p key={key}>
                            {row.prefix}
                        </p>
                    );
                });
                return (elemArr);
            },
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.prefix")}
            />
        });
        // itemArr.push({
        //         Header: this.props.langText.Body.InboundGroupType,
        //         accessor: 'inbound_group_type',
        //         Cell: ({value}) => {
        //             let findObj = inboundGroupType.find(row => row.value === value);
        //             return findObj ? findObj.label : "";
        //         },
        //         Filter: ({filter}) => {
        //             return (
        //                 <select
        //                     onChange = {e => this.onFilterChange(e.target.selectedOptions[0].value, "inbound_group_type")}
        //                     value = {filter ? filter.label : ""}>
        //                     <option key={0} value=""></option>
        //                     {inboundGroupType.map(row => <option key={row.label} value={row.value}>{row.label}</option>)}
        //                 </select>
        //             )
        //         }
        //     });
        itemArr.push({
            Header: this.props.langText.Body.InboundGroupName,
            accessor: 'ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.inbound_group_name',
            width: this.props.boardWidth.small,
            // filterable: false,
            sortable: false,
            Cell: data => {
                let elemArr = [];
                data.row._original.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.forEach((row, key) => {
                    elemArr.push(
                        <p key={key}>
                            {row.inbound_group_name}
                        </p>
                    );
                });
                return (elemArr);
            },
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.inbound_group_name")}
            />

        });
        itemArr.push({
            Header: this.props.langText.Body.BusinessCalendar,
            accessor: 'cm31_business_calendar_infos.business_calendar_name',
            width: this.props.boardWidth.medium,
            // filterable: false
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "cm31_business_calendar_infos.business_calendar_name")}
            />
        });

        itemArr.push({
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize='xsmall'
                                onClick={this.modalShow('update', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='pencil'/>
                            </Button>
                        )
                    } else {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize='xsmall'
                                onClick={this.modalShow('read', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='eye-open'/>
                            </Button>
                        )
                    }

                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize='xsmall'
                                onClick={this.modalShow('delete', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus'/>
                            </Button>
                        )
                    }

                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            });

        return itemArr;
    }

    validationHandle = (param, index = null) => {
        let nowState = null;
        let {
            gateway_id,
            company_id,
            display_number,
            inbound_type,
            channel_number,
            wait_number,
            inboundGroupcount,
            floor_flg,
        } = this.state;

        switch (param) {
            case 'gateway_id':
                nowState = this.state[param].length > 0
                    ? null
                    : 'error';
                break;
            case 'company_id':
            case 'inbound_type':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;

            case 'display_number':
                nowState = this.state[param].length > 9
                    ? null
                    : 'error'
                break;

            case 'channel_number':
            case 'wait_number':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;
            case 'insert':
            case 'update':
                nowState = (
                    gateway_id.length > 0 &&
                    company_id &&
                    display_number.length > 9 &&
                    inbound_type &&
                    channel_number &&
                    wait_number &&
                    inboundGroupcount <= this.props.charaLimit.ExtenCount
                )

                this.state["departmentInfos"].forEach(row => {
                    if (!row.department_id) {
                        nowState = false;
                    }
                    if (floor_flg) {
                        nowState = (nowState && row.floor_id)
                    }
                    if (row.prefix) {
                        if((row.prefix.length === 0 || row.prefix.length === 3) && row.prefix_duplicationflag === false){}
                        else
                        {
                            // プレフィックスが未記載or3桁じゃない場合は登録禁止
                            nowState = false;
                        }
                    }
                });

                break;
            case 'delete':
                nowState = (
                    gateway_id.length > 0
                    && company_id
                    && display_number.length > 9
                    && inbound_type
                    && channel_number
                    && wait_number
                    && inboundGroupcount <= this.props.charaLimit.ExtenCount
                )
                break;
            case 'department_id':
            case 'floor_id':
                if (this.state["departmentInfos"][index] && this.state["departmentInfos"][index][param]) {
                    nowState = null;
                } else {
                    nowState = "error";
                }
                break;
            case 'prefix':
                if (this.state["departmentInfos"][index][param]) {
                    if(this.state["departmentInfos"][index][param].length === 3 && (this.state["departmentInfos"][index]['prefix_searchflag'] === true || typeof this.state["departmentInfos"][index]['prefix_searchflag'] === "undefined"))
                    {
                        this.usedprefixsearch(true,this.state["departmentInfos"][index]["prefix"],this.state["departmentInfos"][index]["floor_id"]||this.state["departmentInfos"][index]["department_id"],index,this.state["departmentInfos"]);

                    }
                    if(this.state["departmentInfos"][index][param].length != 3)
                    {
                        this.usedprefixsearch(false,this.state["departmentInfos"][index]["prefix"],this.state["departmentInfos"][index]["floor_id"]||this.state["departmentInfos"][index]["department_id"],index,this.state["departmentInfos"]);
                    }
                    nowState = this.state["departmentInfos"][index][param].length === 0 || this.state["departmentInfos"][index][param].length === 3
                    ? null
                    : 'error'
                } else {
                    nowState = null;
                }
                break
            default:
                nowState = null;
                break;
        }

        return nowState;
    }

    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "gateway_id" : [],
                "company_id" : [],
                "department_id" : [],
                "inbound_group_id" : [],
                "business_calendar_id" : [],
                "floor_id" : [],
                "sound_id" : [],
                "gateway_load" : [],
                "department_load" : [],
                "inbound_group_load" : [],
                "business_calendar_load" : [],
                "floor_load" : [],
                "sound_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    usedprefixsearch = (flag,prefix,department_id,index,departmentInfos) => {
        if(flag === true)
        {
            // 検索開始
            this
            .ascAxios('post', `${this.reactContainerPath}/prefixSearch`,
            {prefix,department_id,departmentInfos}
            )
            .then(res => {
                if (departmentInfos[index]){
                    departmentInfos[index]['prefix_duplicationflag'] = res.data.result;
                    departmentInfos[index]['prefix_searchflag'] = false;
                    this.propSetState({departmentInfos: departmentInfos});
                }
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
        else
        {
            // validation内で何度も呼ばれないようにする
            // 注意:これやらないと更新制限かかってエラーになる
            // if(this.state.prefix_searchflag === false)
            if(departmentInfos[index]['prefix_searchflag'] === false)
            {
                departmentInfos[index]['prefix_duplicationflag'] = false;
                departmentInfos[index]['prefix_searchflag'] = true;

                this.propSetState({departmentInfos: departmentInfos});
            }
        }
    }

    departmentInfos_onTextChange_Limit(event,param,index,limitcount,departmentInfos) {

        let value = (event && event.target && event.target.value) ? event.target.value : "";
        if(value.length <= limitcount)
        {
            departmentInfos[index][param] = value;
            this.propSetState({"departmentInfos": departmentInfos});
        }
    }

    departmentInfoSet(event, param, index) {
        let myValue = null;
        if(event != null){
            myValue = event.value || event.target.value;
        }

        if (param =="department_id") {
            if (this.state.floor_flg) {
                this.state["departmentInfos"][index]['floor_id'] = 0;
                this.state["departmentInfos"][index]['parent_department_id'] = myValue;
            }
            this.state["departmentInfos"][index]['prefix'] = "";
            this.propSetState({departmentInfos: this.state["departmentInfos"]});
        }

        let nowStateData = [...this.state["departmentInfos"]];
        nowStateData[index][param] = myValue;

        if (!this.state.floor_flg) {
            this.getDepartmentSelect(nowStateData);
        } else {
            nowStateData.forEach((row, dataIndex) => {
                if (dataIndex !=index) {
                    if (row.floor_id == myValue){
                        alert(this.props.langText.Message.ExternalNumber_SameFloorError);
                        return nowStateData[index]["floor_id"] = 0;
                    }
                }
            });
        }
        this.propSetState({departmentInfos: nowStateData});
        switch (param) {
            case 'department_id':

                this.floor_load_flag = true;
                this.departmentSelectChanged(true ,{
                    department_id: myValue,
                    floor_flg : this.state.floor_flg,
                    company_id: this.state.company_id,
                    gateway_id: this.state.gateway_id,
                    index
                });
                // // 拠点変えたら問答無用で初期化
                // this.setState({prefix: ''});

            case 'floor_id':
            case 'inbound_type':
                this.group_load_flag_depart = true;
                if(this.state.inbound_type)
                {
                    this.group_load_flag_intype = true;
                }

                let floor_id_arr = [];
                let department_id_arr = [];
                this.state["departmentInfos"].forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                });
                let inbound_type = (param === 'inbound_type') ? myValue : this.state.inbound_type;
                let select_dep = this.state.floor_flg ? floor_id_arr : department_id_arr;
                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
                break;

            default:
        }
    }

    getDepartmentSelect(departmentInfos) {
        let selectArr = [],
            template = this.state.departmentSelect,
            selectValues = departmentInfos.map(row => {
                return row.department_id
            });
        selectValues.forEach((row, index) => {
            let nowTemplate = [...template],
                nowSetSelect = [...selectValues];

            // 僕のデータを選択されたArrから削除
            nowSetSelect.splice(index, 1);

            // Templateから選択されたデータ削除
            nowSetSelect.forEach(selected => {
                if (selected) {
                    let selectedIndex = nowTemplate.findIndex(data => data.value === selected);

                    if (selectedIndex !== -1) {
                        nowTemplate.splice(selectedIndex, 1);
                    }
                }
            });

            selectArr.push(nowTemplate);
        });

        this.propSetState({departmentSelectArr: selectArr});
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        try {
            this.blockUI();
            if (param && param.id) {
                let modalInfo = await this.getModalInfo(param.id);
                param = modalInfo.data ? modalInfo.data[0] : null;
            }

            this.floor_load_flag = false;
            this.group_load_flag_intype = false;
            this.group_load_flag_depart = false;
            this.gateway_load_flag = false;
            this.omni_phone_back_ground_display_color_picker = false;
            this.omni_phone_back_ground_color = GlobalConst.omni_phone_back_ground_color;
            this.omni_phone_font_display_color_picker = false;
            this.omni_phone_font_color = GlobalConst.omni_phone_font_color;

            this.modalshow_commonInit();

            this.setState({
                departmentInfos:[],
                inbound_group_base: [],
                inbound_group_cm51ids: [],
            });

            let setData = {
                external_id: param && param.id
                    ? param.id
                    : '',
                gateway_id: param && param.cm91_id
                    ? param.cm91_id
                    : '',
                gateway_name: param && param.cm91_gateway_infos && param.cm91_gateway_infos.gw_name
                    ? param.cm91_gateway_infos.gw_name
                    : '',
                company_id: param && param.cm12_companies && param.cm12_companies.id
                    ? param.cm12_companies.id
                    : '',
                company_name: param && param.cm12_companies && param.cm12_companies.company_name
                    ? param.cm12_companies.company_name
                    : '',
                department_id: param && param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : '',
                department_name: param && param.cm13_departments && param.cm13_departments.department_name
                    ? param.cm13_departments.department_name
                    : '',
                floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                    ? param.cm12_companies.floor_flg === "Y"
                    : null,
                display_number: param && param.display_number
                    ? param.display_number
                    : '',
                back_number: param && param.back_number
                    ? param.back_number
                    : '',
                memo: param && param.memo
                    ? param.memo
                    : '',
                channel_number: param && param.channel_number !== "0"
                    ? param.channel_number
                    : '',
                wait_number: param && param.wait_number !== "0"
                    ? param.wait_number
                    : '',
                sound_id: param && param.cm35_id !== "0"
                    ? param.cm35_id
                    : '',
                prefix: param && param.sequence_number
                    ? param.sequence_number
                    : '',
                inbound_type: param && param.inbound_group_type
                    ? param.inbound_group_type
                    : '',
                inbound_group_id: param && param.ct29_assign_external_number_extension_group_infos && param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos
                    ? param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.map(row => row.id)
                    : '',
                inbound_group_name: param && param.ct29_assign_external_number_extension_group_infos && param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos
                    ? param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.map(row => row.inbound_group_name)
                    : '',
                business_calendar_id: param && param.cm31_business_calendar_infos && param.cm31_business_calendar_infos.id
                    ? param.cm31_business_calendar_infos.id
                    : '',
                business_calendar_name: param && param.cm31_business_calendar_infos && param.cm31_business_calendar_infos.business_calendar_name
                    ? param.cm31_business_calendar_infos.business_calendar_name
                    : '',
                departmentInfos: param && param.ct62_assgin_external_department_infos
                    ? param.ct62_assgin_external_department_infos.map(row => {
                        return {
                            id: row.id,
                            cm61_id: row.cm61_id,
                            cm13_id: row.cm13_departments.id,
                            department_id: row.cm13_departments.id,
                            parent_department_id: row.cm13_departments.parent_department_id,
                            floor_id: row.cm13_departments.floor_id,
                            prefix: row.prefix,
                            full_prefix: row.full_prefix,
                            department_name: row.cm13_departments.department_name,
                            floor_name: row.cm13_departments.floor_name,
                            prefix_duplicationflag: false
                        };
                    })
                    : [],
                omni_phone_back_ground_color: param && param.omni_phone_back_ground_color
                    ? param.omni_phone_back_ground_color
                    : GlobalConst.omni_phone_back_ground_color,
                omni_phone_font_color: param && param.omni_phone_font_color
                    ? param.omni_phone_font_color
                    : GlobalConst.omni_phone_font_color,
                before_omni_phone_back_ground_color: param && param.omni_phone_back_ground_color
                    ? param.omni_phone_back_ground_color
                    : '',
                before_omni_phone_font_color: param && param.omni_phone_font_color
                    ? param.omni_phone_font_color
                    : '',
            };

            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理社以下の場合
                setData.company_id = this.props.userInfo.company_id
                setData.company_name = this.props.userInfo.company_name
                setData.floor_flg = this.props.userInfo.floor_flg
            }

            if (!param && this.props.currentPermission.scope_code === "department") {
                //拠点管理者の場合
                if (setData.floor_flg) {
                    setData.department_id = this.props.userInfo.parent_department_id
                    setData.department_name = this.props.userInfo.parent_department_name
                } else {
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                }
            }

            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                //フロア管理者以下の場合
                setData.department_id = this.props.userInfo.department_id
                setData.department_name = this.props.userInfo.department_name
                setData.floor_id = this.props.userInfo.department_id
                setData.floor_name = this.props.userInfo.floor_name
            }

            this.setState(setData);

            this.getDefaultSelectData(setData);

            this.setState({show: true, modalType});

            this.GatewaySelectChanged(false, {
                gateway_id: setData.gateway_id,
                floor_flg: setData.floor_flg,
                company_id: setData.company_id
            });

            if (setData.departmentInfos && setData.departmentInfos.length > 0) {
                let floor_id_arr = [];
                let department_id_arr = [];

                setData.departmentInfos.forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                    if (setData.floor_flg) {
                        this.departmentSelectChanged(true ,{
                            department_id: row.department_id,
                            company_id: setData.company_id,
                            floor_flg : setData.floor_flg,
                            gateway_id: setData.gateway_id,
                            index
                        });
                    }
                });
                let inbound_type = setData.inbound_type;
                let select_dep = setData.floor_flg ? floor_id_arr : department_id_arr;

                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
            }
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    }

    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    onSelectChange(event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        const floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : false;

        switch (param) {
            case 'company_id':
                this.companySelectChanged(true, {company_id: value});
                this.inboundGroupTypeSelectChanged({
                    department_id: '',
                    inbound_type: this.state.inbound_type
                });

                // 会社変えたら問答無用で初期化
                this.setState({prefix: '', floor_flg});
                break;
            case 'inbound_type':
                if (param === 'inbound_type'){
                    this.group_load_flag_depart = true;
                    if(this.state.inbound_type)
                    {
                        this.group_load_flag_intype = true;
                    }
                }

                let floor_id_arr = [];
                let department_id_arr = [];
                this.state["departmentInfos"].forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                });
                let inbound_type = (param === 'inbound_type') ? value : this.state.inbound_type;
                let select_dep = this.state.floor_flg ? floor_id_arr : department_id_arr;

                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
                break;

            default:
                break;
        }

    }

    onMultiSelectChange(event, param) {
        super.onMultiSelectChange(event, param);
        let value_arr = (event.length > 0) ? [] : "";

        event.forEach((row, key) => {
            value_arr[key] = row['value'];
        });

        switch (param) {
            case 'gateway_id':
                this.GatewaySelectChanged(true, {
                    gateway_id: value_arr,
                    floor_flg: this.state.floor_flg,
                    company_id: this.state.company_id
                });
                break;
            default:
        }

    }

    getDefaultSelectData = ({company_id}) => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.companySelectChanged(false, {company_id})
                this.setState({
                    companySelect: res.data,
                })
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }

    companySelectChanged = (flag, {company_id}) => {
        if (flag) {
            this.setState({
                department_id:        '',
                sound_id:             '',
                business_calendar_id: '',
                gateway_id: [],
                departmentInfos: [],
            })
        }
        if(company_id)
        {
            // this.common_buttondisabledLengthCheck(`department_load`,`push`);
            this.common_buttondisabledLengthCheck(`gateway_load`, `push`);
            this.common_buttondisabledLengthCheck(`business_calendar_load`,`push`);
            this.common_buttondisabledLengthCheck(`sound_load`,`push`);
        }
        else
        {
            // this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            this.common_buttondisabledLengthCheck(`gateway_load`, `pop`);
            this.common_buttondisabledLengthCheck(`business_calendar_load`,`pop`);
            this.common_buttondisabledLengthCheck(`sound_load`,`pop`);
        }
        let langtext_update = this.props.langText.Body.VoiceListUpdate;
        let langtext_create = this.props.langText.Body.VoiceListCreate;
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/gatewayAndBusinessCalendarAndSoundSelect`,
                {company_id,langtext_update,langtext_create}
            )
            .then(res => {
                if(this.state.company_id)
                {
                    // this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`gateway_id`, `push`);
                    this.common_buttondisabledLengthCheck(`business_calendar_id`,`push`);
                    this.common_buttondisabledLengthCheck(`sound_id`,`push`);
                    // this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`gateway_load`, `pop`);
                    this.common_buttondisabledLengthCheck(`business_calendar_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`sound_load`,`pop`);
                }
                else
                {
                    // this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`business_calendar_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`sound_id`,`pop`);
                    // this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                }

                this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                this.setState({
                    // departmentSelect:       res.data.cm13_departments,
                    gatewaySelect: res.data.cm91_gateway_infos,
                    businessCalendarSelect: res.data.cm31_business_calendar_infos,
                    waitNumberSoundSelect:  res.data.cm35_voice_file_infos,
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })

        }

    departmentSelectChanged = (flag, {company_id, department_id, floor_flg, gateway_id, index}) => {
        const floorSelect = this.state.floorSelect;
        if (flag) {
            this.setState({
                floor_id: 0,
            })
        }

        if(this.floor_load_flag === true && department_id && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);

        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }
        if (floor_flg) {
            this.ascAxios('post', `Common/floorSelectByGateWay`, {company_id, department_id, gateway_id})
            .then(res => {
                if(department_id)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                floorSelect[index] = res.data;
                this.setState({
                    floorSelect: floorSelect
                });
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    GatewaySelectChanged = (flag, {company_id, floor_flg, gateway_id}) => {
        if (flag) {
            this.setState({
                department_id: '',
                sound_id: '',
                business_calendar_id: '',
                departmentInfos: [],
            })
        }
        if (company_id) {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        } else {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        let langtext_update = this.props.langText.Body.VoiceListUpdate;
        let langtext_create = this.props.langText.Body.VoiceListCreate;
        this
        .ascAxios(
            'post',
            `${this.reactContainerPath}/departmentSelect`, {
                company_id,
                floor_flg,
                gateway_id
            }
        )
        .then(res => {
            if (this.state.company_id) {
                this.common_buttondisabledLengthCheck(`department_id`,`push`);
                this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            } else {
                this.common_buttondisabledLengthCheck(`department_id`,`pop`);
            }

            this.setState({
                departmentSelect:res.data.cm13_departments,
            })

            this.getDepartmentSelect((this.state["departmentInfos"]) ? this.state["departmentInfos"] : []);
        })
        .catch(err => {
            alert(this.getErrorString(err.response.data))
        })

    }

    getGatewaySelect = (flag, {company_id}) => {
        if (flag) {
            this.setState({
                gateway_id: []
            });
        }
        if(company_id && this.gateway_load_flag === true)
        {
            this.common_buttondisabledLengthCheck(`gateway_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
        }

        this.ascAxios('post', 'Common/companyBygatewaySelect', {company_id})
            .then(res => {
                if(this.state.floor_flg)
                {
                    if(this.state.floor_id)
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`push`);
                        this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);

                    }
                    else
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                    }
                }
                else
                {
                    if(this.state.department_id)
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`push`);
                        this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                    }
                    else
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                    }
                }
                this.setState({
                    gatewaySelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            });
    }

    inboundGroupTypeSelectChanged = ({department_id, inbound_type}) => {
        if(this.group_load_flag_intype === true && this.group_load_flag_depart === true)
        {
            this.common_buttondisabledLengthCheck(`inbound_group_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
        }
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/inboundGroupInfoSelect`,
                {department_id, inbound_type}
            )
            .then(res => {
                let inbound_group_setup = [];

                if(department_id && this.state.inbound_type)
                {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`,`push`);
                    this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
                    this.group_load_flag_intype = false;
                    this.group_load_flag_depart = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
                }

                let countdata = 0;
                if(this.state.inbound_group_id.length != 0)
                {
                    this.state.inbound_group_id.map(row => {
                        for(let i = 0; i < res.data.cm28_inbound_group_infos.length; i++)
                        {
                            if(res.data.cm28_inbound_group_infos[i].value === row)
                            {
                                countdata += res.data.cm28_inbound_group_infos[i].count;
                            }
                        }
                    });
                }
                let drawlabel = JSON.parse(JSON.stringify(res.data.cm28_inbound_group_infos));
                for(let i = 0; i < res.data.cm28_inbound_group_infos.length; i++)
                {
                    if(res.data.cm28_inbound_group_infos[i].count != 0)
                    {
                        drawlabel[i].label = drawlabel[i].label + '(' + drawlabel[i].count + ')';
                    }

                    this.state.inbound_group_id.map(inbound_group_data => {
                        if (res.data.cm28_inbound_group_infos[i].value == inbound_group_data) {
                            inbound_group_setup.push(inbound_group_data);
                        }
                    });
                }
                this.setState({
                    inboundGroupSelect: drawlabel,
                    inboundGroupcount:  countdata,
                    inbound_group_base: drawlabel,
                    inbound_group_cm51ids: res.data.cm28_extensions_ids,
                });

                this.setState({inbound_group_id: inbound_group_setup});
            })
            .catch(err => {
                console.log(err);
                alert(this.getErrorString(err.response.data))
            })
    }


    onClickHandle = modalType => event => {
        let {
            external_id,
            gateway_id,
            company_id,
            department_id,
            display_number,
            back_number,
            channel_number,
            wait_number,
            memo,
            inbound_type,
            inbound_group_id,
            business_calendar_id,
            sound_id,
            prefix,
            departmentInfos,
            omni_phone_back_ground_color,
            omni_phone_font_color,
            floor_flg,
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    external_id,
                    gateway_id,
                    company_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    sound_id,
                    prefix,
                    departmentInfos,
                    omni_phone_back_ground_color,
                    omni_phone_font_color,
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    external_id,
                    gateway_id,
                    company_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    sound_id,
                    departmentInfos,
                    omni_phone_back_ground_color,
                    omni_phone_font_color,
                    floor_flg,
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });

                break;

            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    external_id,
                    gateway_id,
                    company_id,
                    department_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    departmentInfos,
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({ code: "modal" }));
        }

        this.setState({ show: false });
    }

    render() {
        return (
            <div className='ExternalNumber'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="external-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual       = 'manual'
                            columns      = {this.state.columns}
                            data         = {this.state.data}
                            pages        = {this.state.pages}
                            loading      = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData  = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable   = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText     = {this.props.langText.Table.NextText}
                            loadingText  = {this.props.langText.Table.LoadingText}
                            noDataText   = {this.props.langText.Table.NoDataText}
                            rowsText     = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <ExternalNumberModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onIntChange = {this.onIntChange}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onDepartmentChange = {this.departmentInfoSet}
                    validationHandle = {this.validationHandle}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onMultiSelectChangeExternal = {this.onMultiSelectChangeExternal}
                    getDepartmentSelect = {this.getDepartmentSelect}
                    inboundGroupTypeSelectChanged = {this.inboundGroupTypeSelectChanged}
                    departmentInfos_onTextChange_Limit = {this.departmentInfos_onTextChange_Limit}
                    currentPermission = {this.props.currentPermission}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                />
            </div>
        )
    }
}
