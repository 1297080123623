import React from "react";
import {Col, Button} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import AscComponent from "../../../components/AscComponent";

/**
 * スクリプト名表示アイテム
 * state.script_name必要
 */
export class ViewScriptName extends AscComponent {
    render() {
        let {
            state,
            langText
        }=this.props;

        return (
            <InlineForm.static
                key="script-name"
                controlId="script-name"
                label={langText.Body.ScriptName}
                children={state.script_name}
            />
        );
    }
}

/**
 * バージョン選択アイテム
 * state.version, state.version_list必要
 */
export class SelectVersion extends AscComponent {
    render() {
        let {
            state,
            langText,
            onSelectChange
        }=this.props;

        return (
            <InlineForm
                key="version"
                controlId="version"
                label={langText.Body.CurrentVersion}
                type="select"
                value={state.version}
                options={state.version_list}
                onChange={e => onSelectChange(e, 'version')}
                validationState={state.version ? null : "error"}
                placeholder = {langText.SelectOption.Placeholder}
            />
        );
    }
}

/**
 * バージョンメモ表示アイテム
 * state.version_memo必要
 */
export class ViewVersionMemo extends AscComponent {
    render() {
        let {
            state,
            langText
        }=this.props;

        return (
            state.version_memo
                ? <InlineForm.static
                    key="version-memo"
                    controlId="version-memo"
                    label={langText.Body.VersionMemo}
                    children={state.version_memo}
                />
                : <InlineForm.static
                    key="no-memo-message"
                    controlId="no-memo-message"
                    className="no-memo-message"
                    label={langText.Body.VersionMemo}
                    children={langText.Message.NoMemoMessage}
                />
        );
    }
}

/**
 * バージョン登録日表示アイテム
 * state.version_created必要
 */
export class ViewVersionCreated extends AscComponent {
    render() {
        let {
            state,
            langText
        }=this.props;

        return (
            <InlineForm.static
                key="created"
                controlId="created"
                label={langText.Body.Created}
                children={state.version_created}
            />
        );
    }
}


/**
 * バージョン確認用電話番号フォムアイテム
 * state.version_check_tel_no必要
 */
export class CheckVersion extends AscComponent {
    validateTelNumber(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateTelNumber(param);
        }
        return validation_flag;
    }

    render() {
        let {
            state,
            langText,
            onTextChange,
        }=this.props;

        return (
            <>
                <InlineForm
                    key="version-check-tel"
                    controlId="version-check-tel"
                    label={langText.Body.CheckTelNo}
                    type="text"
                    value={state.version_check_tel_no || ""}
                    onChange={e=>onTextChange(e,"version_check_tel_no",langText.Value.tel_number.max)}
                    validationState={this.validateTelNumber(state.version_check_tel_no, true)}
                    placeholder = {langText.Body.ScriptTelNo}
                />
                <div>
                    <Col md={11}>
                        <p className="call-charge-message">
                            {langText.Message.CallChargeMessage}
                        </p>
                    </Col>
                    <Col md={1}>
                        <Button
                            className = 'call-btn'
                            bsStyle = "primary"
                            disabled = {!this.validateTelNumber(state.version_check_tel_no)}
                        >
                            {langText.Body.Call}
                        </Button>
                    </Col>
                </div>
            </>
        );
    }
}