import React, {Component} from "react";
import {Panel, FormGroup, Checkbox, Col, Glyphicon , Radio} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css"

export default class CompanyModal extends Component {
    getPbxToStr = () => {
        let str = "";

        this.props.state.pbxSelect.forEach(row => {
            if (this.props.state.pbx.find(value => value === row.value)) {
                if (str !== "") str += ",";
                str += row.label;
            }
        });

        return str;
    }

    getGatewayInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onCheckBoxChange,
            onCheckBoxChange_Init,
            validationHandle,
            onRadioChange,
            systemFloorFlg,
        } = this.props;
        let itemArr = [];
        let pbxStr = (state.modalType === "delete" || state.modalType === "read")
            ? this.getPbxToStr()
            : "";

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "companyName"
                    controlId = "companyName"
                    label = {langText.Body.CompanyName}
                    type = "text"
                    value = {state.companyName}
                    onChange = {e => onTextChange_Limit(e, "companyName",charaLimit.Company_companyName)}
                    validationState = {validationHandle("companyName")}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "companyName"
                    controlId = "companyName"
                    label = {langText.Body.CompanyName}
                >
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}
                    type = "select"
                    value = {state.pbx}
                    onChange = {e => onMultiSelectChange(e, "pbx")}
                    options = {state.pbxSelect}
                    isMulti = {true}
                    closeMenuOnSelect = {false}
                    validationState = {validationHandle("pbx")}
                    placeholder = {!state.buttondisabledArr.pbx[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.pbx[0]}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}>
                    {pbxStr}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}
                    type = "text"
                    maxLength ={systemFloorFlg === "Y" ? "2" : "3"}
                    value = {state.prefix}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={e => onTextChange_Limit(e, "prefix",charaLimit.Company_prefix)}
                    placeholder = {systemFloorFlg === "Y" ? langText.Body.Length2 : langText.Body.Length3}
                    validationState = {validationHandle("prefix")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}>
                    {state.prefix}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "outboundPrefix"
                    controlId = "outboundPrefix"
                    label = {langText.Body.OutboundPrefix}
                    type = "select"
                    value = {state.outboundPrefix}
                    onChange = {e => onSelectChange(e, "outboundPrefix")}
                    options = {state.outboundPrefixSelect}
                    isClearable = {true}
                    placeholder = {langText.SelectOption.Placeholder}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "outboundPrefix"
                    controlId = "outboundPrefix"
                    label = {langText.Body.OutboundPrefix}>
                    {state.outboundPrefixView ? state.outboundPrefixView.label : ""}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}
                    type = "text"
                    value = {state.password}
                    onChange = {e => onTextChange_Limit(e, "password",charaLimit.Company_password)}
                    disabled = {state.autoPass}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}>
                    {state.password}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <FormGroup
                    key = "autoPass"
                    controlId="autoPass">
                    <InlineForm>
                        <Col smOffset = {0} sm = {3}>
                            <Checkbox
                            className="company_checkbox"
                                checked={state.autoPass}
                                onChange={e => onCheckBoxChange_Init(e, "autoPass","password")}
                            >
                                {langText.Body.AutoCreate}
                            </Checkbox>
                        </Col>
                    </InlineForm>
                </FormGroup>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId="memo"
                    label={langText.Body.Memo}
                    type="text"
                    value={state.memo}
                    onChange={e => onTextChange_Limit(e, "memo",charaLimit.Company_memo)}
                    disabled={state.modalType === "delete"}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "omniURL"
                    controlId = "omniURL"
                    label = {langText.Body.OmniURL}
                    type = "text"
                    value = {state.omniURL}
                    onChange = {e => onTextChange_Limit(e, "omniURL",charaLimit.Company_omniURL)}
                    disabled = {state.modalType === "delete"}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "omniURL"
                    controlId = "omniURL"
                    label = {langText.Body.OmniURL}
                >
                    {state.omniURL}
                </InlineForm.static>
            );
        }
    
        return itemArr;
    }

    // 会社パラメータオプション選択
    onRadioChangeByParameters (event, param) {
        let {
            state,
            propSetState,
        } = this.props;
        // 選択した値
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        // 会社パラメータ全体から検索(CompanyControlParametersOptionの定義を持ってくる)
        let all_parameter_search_function_key = state.system_parameters_all.find(row => row.parameter_id === param);
        // DBで検索した値を確認する
        let param_data = state.companyControlParameters.find(row => row.parameter_id === param);

        // DBの値がある場合
        if (typeof param_data != "undefined") {
            param_data.parameter_value = value;
        // DBの値がない場合
        }  else {
            // 選択した値を定義のデータに入れる（defalut）
            all_parameter_search_function_key.parameter_value = value;
            //会社パラメータに追加する
            state["companyControlParameters"].push(all_parameter_search_function_key);
        }

        propSetState({companyControlParameters: state["companyControlParameters"]});
    }
    setcompanyControlParameterValue (parameter_id) {
        let {
            state,
        } = this.props;
        let return_value = null;

        if (state.companyControlParameters.length > 0){
            let company_control_parameter = state.companyControlParameters.find(row => row.parameter_id === parameter_id);

            if (company_control_parameter && company_control_parameter.parameter_value) {
                return_value = company_control_parameter.parameter_value;
            }
        } 
        return return_value;
    }
    getControlParametersItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onCheckBoxChange,
            onCheckBoxChange_Init,
            validationHandle,
            onRadioChange,
        } = this.props;
        let itemArr = [];

        let call_record_operation = this.setcompanyControlParameterValue("CALL_RECORD_OPTION") !== null ? this.setcompanyControlParameterValue("CALL_RECORD_OPTION") :"Y";

        // モニタリング権限
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "call_record_option"
                    controlId = "call_record_option"
                    >
                    <div>
                        <Col xs={3} >
                            <label 
                            className = "set_control_parameter">
                                {langText.Body.callRecordOption}
                            </label>
                        </Col>
                    </div>
                    {state.system_call_record_option.map((row, key) =>
                        <div key={key}>
                            <Col xs={2} >
                            <Radio
                                name     = "call_record_option"
                                key      = {key}
                                onChange = {e => this.onRadioChangeByParameters(e, "CALL_RECORD_OPTION")}
                                checked  = {row.value === call_record_operation}
                                value    = {row.value}
                            >
                                {row.label}
                            </Radio>
                            </Col>
                        </div>
                    )}
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "call_record_option"
                    controlId = "call_record_option"
                    label = {langText.Body.callRecordOption}
                >
                    {state.system_call_record_option.map((row, key) =>
                        row.value === call_record_operation ? row.label : ""
                    )}
                </InlineForm.static>
            );
        }

        return itemArr;
    }
    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.CompanySetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getGatewayInfoItem()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.chargedOption}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getControlParametersItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}