import React from 'react';

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

export default class Incoming extends AscMenu {
    constructor(props) {
        super(props);

        this.state.extension_group_data = null;
        this.audio_select = [{value: "none", label: this.props.langText.SelectOption.AudioNonePlay}, ...this.selectOptions.audio_type];

        if (this.state.data.queue_audio_type === "file" && !this.state.data.queue_audio_file.length) {
            this.state.data.queue_audio_type = this.audio_select[0].value;
        }
        this.getExtensionGroup();
    }

    getExtensionGroup() {
        this
            .ascAxios(
                "post",
                "InboundGroup/inboundGroupAllSelect",
                {company_id: this.props.company_id}
            )
            .then(result => {
                this.setState({extension_group_data: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_type_select: this.audio_select,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "queue_audio_type",
                    audio_file: "queue_audio_file",
                    audio_file_id: "queue_audio_file_id",
                    audio_gen_language: "queue_audio_gen_language",
                    audio_gen_person: "queue_audio_gen_person",
                    audio_gen_text: "queue_audio_gen_text"
                })}
                <ASFieldGroup
                    label={this.props.langText.Body.InboundGroupName}
                    validationState={this.validation.menu.groups(this.state.data)}>
                    <ASSelect
                        value={this.state.data.groups}
                        options={this.state.extension_group_data}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={e => {
                            this.state.data.groups = e.map(row => row.value);
                            this.setState({data: this.state.data});
                        }}/>
                </ASFieldGroup>
            </>
        );
    }
}