import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import params from "../AscParams.json";
import selectOption from "../containers/Elements/SelectOption/SelectOption";
import { faGrinTongueSquint } from "@fortawesome/free-solid-svg-icons";
import * as GlobalConst from "./AscConstants";
import CompanyControlParametersOption from "../containers/Elements/CompanyControlParametersOption/CompanyControlParametersOption";

const b64DecodeUnicode = str => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
};

const blockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.remove("asc-display-none");
    }
}

const unblockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.add("asc-display-none");
    }
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = params.baseURL;
axios.defaults.timeout = 60 * 1000;

axios.interceptors.response.use(res => {
    if (typeof res.data === "string") {
        try {
            let base64DecodingData = JSON.parse(b64DecodeUnicode(res.data));
            res.data = base64DecodingData;
        } catch (e) {}
    }

    unblockUI();
    return res;
}, err => {
    try {
        if (typeof err.response.data === "string") {
            try {
                let base64DecodingData = JSON.parse(b64DecodeUnicode(err.response.data));
                err.response.data = base64DecodingData;
            } catch (e) {}
        }
    } catch (err) {}

    if ((err.status && err.status === 401) || (err.response && err.response.status && err.response.status === 401)) {
        //alert('sessionが切れました。\r\n画面を作り直します。');
        alert('ログアウトされました。');
        window.location.reload();
    }

    unblockUI();
    return Promise.reject(err);
});

export default class AscComponent extends Component {
    constructor(props) {
        super(props);

        this.table = React.createRef();
        this.baseURL = params.baseURL;
        this.onTextChange = this.onTextChange.bind(this);
        this.onTextChange_Limit = this.onTextChange_Limit.bind(this);
        this.onIntChange = this.onIntChange.bind(this);
        this.onKeyDownIntCheck = this.onKeyDownIntCheck.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onNestSetState = this.onNestSetState.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.onMultiSelectChangeExternal = this.onMultiSelectChangeExternal.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.onCheckBoxChange_Init = this.onCheckBoxChange_Init.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onNestCheckBoxChange = this.onNestCheckBoxChange.bind(this);
        this.setCommonCompanySelect = this.setCommonCompanySelect.bind(this);
        this.getCommonCompanySelect = this.getCommonCompanySelect.bind(this);
        this.getScopeGreaterEqual = this.getScopeGreaterEqual.bind(this);
        this.fetchData = this.reactTableFetchData.bind(this);
        this.getScriptUsingCalendarName = this.getScriptUsingCalendarName.bind(this);

        this.blockUI = blockUI;
        this.unblockUI = unblockUI;

        if (props.location) {
            this.reactContainerPath = props.location.pathname;
            this.reactTableTarget = props.location.pathname + "/board";
        }
    }

    propSetState = state => {
        this.setState(state);
    }

    onTextChange (event, param) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        this.setState({[param]: value});
    }
    onTextChange_Limit (event,param,limitcount) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        if(value.length <= limitcount)
        {
            this.setState({[param]: value});
        }
    }

    onIntChange = (param,maxlength) => event => {
        if(event.target.value.length <= maxlength)
        {
            let value = event ? event.target.value : "";
            value = value.replace(/[^0-9]+/i,'');
            if(value != "")
            {
                value = Number(value);
            }
            this.setState({[param]: value});
        }
    };
    onKeyDownIntCheck = (e) => {
        if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46
        || e.keyCode === 9 || e.keyCode === 16 || (e.keyCode >= 37 && e.keyCode <= 40))
        {
            return true;
        }
        else if (e.ctrlKey)
        {
            if(e.keyCode === 67 || e.keycode === 86)
            {
                return true;
            }
        }
        else
        {
            e.preventDefault();
            return false;
        }
    };

    onSelectChange (event, param) {
        let value = event && event.value ? event && event.value : "";
        this.setState({[param]: value});
    }
    
    /**
     * 編集または削除するスクリプトが使用中かチェック
     * @param {string} cm70_id
     * @returns {promise <array>} UsingList
     */
    async getScriptUsingCalendarName(cm70_id) {
        let cm32UsingList = Array();
        let cm33UsingList = Array();
        let UsingList = Array();
        const yesterday = this.getMomentTime({ format: "YYYY/MM/DD", date: moment().add(-1, "days") });

        await this.ascAxios('post', `Script/CheckCm32ScriptUsed`, { cm70_id })
            //cm32で使用中のスクリプトを取得
            .then(res => {
                cm32UsingList = res.data;
            });

        await this.ascAxios('post', `Script/CheckCm33ScriptUsed`, { cm70_id, irregularDate: yesterday })
            //cm33で使用中のスクリプトを取得
            .then(res => {
                cm33UsingList = res.data;
            });

        // cm32で取得したデータにカレンダータイプを追加
        UsingList = cm32UsingList.map(cm32UsingItem =>({
            cm31_id: cm32UsingItem.cm31_id,
            business_calendar_name: cm32UsingItem.cm31_business_calendar_infos.business_calendar_name,
            type: this.props.langText.Body.BusinessHourSetting
        }));

        // cm33で取得したデータにカレンダータイプを追加、cm32データのマージ
        UsingList = UsingList.concat(
            cm33UsingList.map(cm33UsingItem => ({
                cm31_id: cm33UsingItem.cm31_id,
                business_calendar_name: cm33UsingItem.cm31_business_calendar_infos.business_calendar_name,
                type: this.props.langText.Body.IrregularSetting
            }))
        );

        return UsingList;
    }

    /**
     * stateでネストされている値を変更する
     * param キー
     * param2 ネストされているキー
     */
    onNestSetState (event, param, param2) {
        this.setState({ [param]: { ...this.state[param], [param2]: event.target.value}});
    }

    /**
     * 権限管理
     * stateでネストされているチェックボックスの値を切り替える
     */
    onNestCheckBoxChange (event, param, param2) {
        if (param2 === "all") {
            // 全権限操作時は全て連動
            this.setState({[param]: { ...this.state[param],
                    all: !this.state[param].all,
                    edit: !this.state[param].all,
                    read: !this.state[param].all,
                    create: !this.state[param].all,
                    delete: !this.state[param].all,
                    export: !this.state[param].all,
                    playback: !this.state[param].all
                }})
        } else if (param2 === "read" && this.state[param][param2]) {
            // 閲覧がfalseになった場合は全てfalse
            this.setState({[param]: { ...this.state[param],
                    all: false,
                    edit: false,
                    read: false,
                    create: false,
                    delete: false,
                    export: false,
                    playback: false
                }})
        } else if (this.state[param][param2]) {
            // 何かがfalseになった場合全権限も必ずfalse
            this.setState({[param]: { ...this.state[param],
                    [param2]: false,
                    all: false
                }})
        } else if (!this.state[param][param2] && !this.state.all &&
            Object.values(this.state[param]).filter((v) => v === false).length === 2) {
            // 何かがtrueになりall以外全てがtrueになる場合はallをtrueにする
            this.setState({[param]: { ...this.state[param],
                    [param2]: true,
                    all: true
                }})
        } else if (param2 !== "read" && !this.state[param][param2]) {
            // 何かがtrueになった場合は閲覧も必ずtrue
            this.setState({[param]: { ...this.state[param],
                    [param2]: true,
                    read: true
                }})
        } else {
            this.setState({[param]: { ...this.state[param], [param2]: !this.state[param][param2]}})
        }
    };

    /**
     * 会社一覧取得Commonコントローラー版
     */
    async setCommonCompanySelect (reactContainerPath) {
        try {
            let result = await this.ascAxios('post', `Common/companySelect`, {container: reactContainerPath})
            this.setState({companySelect: result.data})
        } catch (err) {
            throw err;
        }
    }


    /**
     * 会社一覧取得Commonコントローラー版
     */
    async getCommonCompanySelect (reactContainerPath) {
        try {
            return await this.ascAxios('post', `Common/companySelect`, {container: reactContainerPath});
        } catch (err) {
            throw err;
        }
    }

    onMultiSelectChange (event, param) {
        if (Array.isArray(event)) {
            this.setState({[param]: event.map(row => {return row.value})});
        }
    };
    onMultiSelectChangeExternal(event,param){
        if (Array.isArray(event)) {
            /*
                drawarr     : 表示用state変数
                ids         : 全内線グループに所属する全内線番号情報
                selectids   : 現在選択されている内線グループの全内線番号
                unselectids : 現在未選択の内線グループの全内線番号
                inbound_id  : 現在選択してる内線グループのID
                drawlabel   : 各drawarrのlabel要素情報(count表示更新と0の場合括弧削除)
                drawcount   : 各drawarrのcount要素情報(count数値更新)
            */
            let drawarr = JSON.parse(JSON.stringify(this.state.inbound_group_base));
            let ids = JSON.parse(JSON.stringify(this.state.inbound_group_cm51ids));
            let selectids = [];
            let unselectids = [];
            // 選択されているグループID取得
            let inbound_id = event.map(row => {return row.value});
            let drawlabel = '';
            let drawcount = 0;

            // 選択している内線グループの内線情報と選択していないグループ全部の内線情報を仕分け
            for(let cm51idcount = 0; cm51idcount < ids.length; cm51idcount++)
            {
                if(inbound_id.indexOf(ids[cm51idcount].id) != -1)
                {
                    // 選択されてる
                    // 何も入ってないならpush                    
                    if(selectids)
                    {
                        // 何か入ってる+今pushされてる情報の中で存在しない内線ならpush
                        if(selectids.findIndex(({cm51_id}) => cm51_id === ids[cm51idcount].cm51_id) === -1)
                        {
                            selectids.push(ids[cm51idcount]);
                        }
                    }
                    else
                    {
                        selectids.push(ids[cm51idcount]);
                    }
                }
                else
                {
                    // 未選択
                    // 何も入ってないならpush
                    if(unselectids)
                    {
                        // 何か入ってる+今pushされてる情報の中で存在しない内線ならpush
                        if(unselectids.findIndex(({cm51_id}) => cm51_id === ids[cm51idcount].cm51_id) === -1)
                        {
                            unselectids.push(ids[cm51idcount]);
                        }
                    }
                    else
                    {
                        unselectids.push(ids[cm51idcount]);
                    }
                }
            }
            // 全グループ確認
            for(let allgroup = 0; allgroup < drawarr.length; allgroup++)
            {
                // カウント初期化
                drawcount = drawarr[allgroup].count;
                // 選択されているか確認
                if(inbound_id.indexOf(drawarr[allgroup].value) === -1)
                {
                    // 選択されて居ない
                    drawcount = drawarr[allgroup].count;
                    // 現在選択されている内線の数分確認
                    for(let extdata = 0; extdata < unselectids.length; extdata++)
                    {
                        // 現在選択している内線が現在選択している内線グループにあるか確認
                        if(selectids.findIndex(({cm51_id}) => cm51_id === unselectids[extdata].cm51_id) != -1)
                        {
                            // 保険で0切ったら0固定
				            if(drawcount > 0)
				            {
                                // 存在する場合drawcountを-1する
                                drawcount -= 1;
                            }
                            else
                            {
                                drawcount = 0;
                            }
                        }
                    }
                }
                // 選択されてない場合はスルー
                // 該当のグループ情報のカウントが0の場合変更
                /*
                if(drawcount <= 0)
                {
                    drawlabel = drawarr[allgroup].label;
                }
                else
                {
                    drawlabel = drawarr[allgroup].label + "(" + drawcount + ")";
                }
                */
                //drawarr[allgroup].label = drawlabel;
                drawarr[allgroup].count = drawcount;
            }

            // 最後_データセットして終了
            this.setState({[param]: event.map(row => {return row.value})});
            this.setState
            ({
                inboundGroupcount: selectids.length,
                inboundGroupSelect: drawarr,
            });
        }
    };

    onCheckBoxChange (event, param) {
        this.setState({[param]: !this.state[param]});
    };

    onCheckBoxChange_Init (event, param, param2) {
        this.setState({[param]: !this.state[param]});
        this.setState({[param2]: ""});
    };

    onRadioChange (event, param) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        this.setState({[param]: value});
    }

    onFileChange (event, param) {
        let value = event.target.files;
        let size_limit = 0;
        let alert_size = '';
        let alert_message = '';

        // 顧客管理の場合のみ容量制限変更
        if(param === 'csvFile')
        {
            size_limit = 90000;
            alert_size = "1000行以下";
        }
        else
        {
            size_limit = 9000000;
            alert_size = "9MB以下";
        }
        alert_message = this.sprintf(this.props.langText.Message.Upload_sizeLimit,alert_size);
        if(value[0].size >= size_limit)
        {
            event.target.value = "";
            
            alert(alert_message);
            this.setState({[param]: ''});
        }
        else
        {
            this.setState({[param]: value});
        }
    }

    // db dateTime to momentTime
    getMomentTime = ({lag = 9, formatStr = "h", format = "YYYY-MM-DD", date}) => {
        return moment(date).utc().add(lag, formatStr).format(format);
    };

    sprintf = (message, ...args) => {
        args.forEach((arg, key) => {
            message = message.replace(new RegExp("\\{" + key + "\\}", "gi"), arg);
        });

        return message;
    };

    // get error msg
    getErrorString = ({code, args}) => {
        let msg = ''
        args = args || [];

        if (this.props.langText.Message[code]) {
            msg = this.sprintf(this.props.langText.Message[code], ...args);
        } else {
            switch (code) {
                case  1: msg = this.sprintf(this.props.langText.Message.AuthCheckError, ...args);  break;
                case  2: msg = this.sprintf(this.props.langText.Message.DataSelectError, ...args); break;
                case  3: msg = this.sprintf(this.props.langText.Message.DataInsertError, ...args); break;
                case  4: msg = this.sprintf(this.props.langText.Message.DataUpdateError, ...args); break;
                case  5: msg = this.sprintf(this.props.langText.Message.DataDeleteError, ...args); break;
                case  6: msg = this.sprintf(this.props.langText.Message.FindDataError, ...args);   break;
                case 10: msg = this.sprintf(this.props.langText.Message.User_InsertRegisteredUserError, ...args);    break;
                case 11: msg = this.sprintf(this.props.langText.Message.User_InvalidParameterError, ...args);        break;
                case 20: msg = this.sprintf(this.props.langText.Message.SignIn_SignInFailed, ...args);               break;
                case 21: msg = this.sprintf(this.props.langText.Message.SignIn_NotMatchCodeOrExpiredError, ...args); break;
                case 22: msg = this.sprintf(this.props.langText.Message.SignIn_NotMatchCodeError, ...args);          break;
                case 23: msg = this.sprintf(this.props.langText.Message.SignIn_ExecutionFailedError, ...args);       break;
                case 24: msg = this.sprintf(this.props.langText.Message.SignIn_UserNotFoundError, ...args);          break;
                case 25: msg = this.sprintf(this.props.langText.Message.SignIn_TooMuchFailsError, ...args);          break;
                case 26: msg = this.sprintf(this.props.langText.Message.SignIn_NotConfirmedUserError, ...args);      break;
                case 30: msg = this.sprintf(this.props.langText.Message.Customer_overlappedTelNoError, ...args);     break;
                case 31: msg = this.sprintf(this.props.langText.Message.Customer_overCsvRow, ...args);               break;
                case 32: msg = this.sprintf(this.props.langText.Message.Customer_telPregCheck, ...args);             break;
                case 40: msg = this.sprintf(this.props.langText.Message.GroupExtensionNum_overlappedNumberError, ...args); break;
                case 41: msg = this.sprintf(this.props.langText.Message.Extension_SequenceSame, ...args);            break;
                case 42: msg = this.sprintf(this.props.childProps.langText.Message.Download_PopupblockCheck, ...args);            break;
                case 43: msg = this.sprintf(this.props.langText.Message.Download_Failed, ...args);            break;
                case 44: msg = this.sprintf(this.props.langText.Message.File_Download_Failed, ...args); break;
                case 45: msg = this.sprintf(this.props.langText.Message.Password_Validation, ...args);       break;
                case 46: msg = this.sprintf(this.props.langText.Message.Password_Error, ...args);       break;
                case 47: msg = this.sprintf(this.props.langText.Message.SignIn_UserNotFoundException, ...args);     break;
                case 48: msg = this.sprintf(this.props.langText.Message.TooManyRequestsException, ...args);     break;
                case 49: msg = this.sprintf(this.props.langText.Message.UserNotConfirmedException, ...args);     break;
                case 50: msg = this.sprintf(this.props.langText.Message.PasswordResetRequiredException, ...args);     break;
                case 51: msg = this.sprintf(this.props.langText.Message.NoDataDeleteError, ...args); break;
                case 'modal': msg = this.sprintf(this.props.langText.Message.ModalTypeError, ...args);               break;
                
                default: msg = `Code: ${code}`; break;
            }
        }
        
        return msg
    }

    // get select option
    getSelectOption = (column_name, lang) => selectOption(column_name, lang);

    getCompanyControlParametersOption = (column_name, lang) => CompanyControlParametersOption(column_name, lang);

    ascAxios = async (method, ...arg) => {
        return await axios[method](...arg);
    };
    escapeHtml(unsafe) {
        return unsafe
            .replace('_', '\\_'); //アンダーバー対応
    }

    onFilterChange = (value, accessor) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === accessor);

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }
        value = this.escapeHtml(value);

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: accessor,
                value    
            });
        }
        this.table.current.state.page=0;
        
        this.setState({
            filtered: my_filtered,
        });

        setTimeout(() => {
            this.reactTableRefresh();
        }, 300);
    }

    //ユーザー数取得
    async getUserCntByDepartment(cm13_id){
        try {
            let res = await this.ascAxios('post',`Common/userSearchByDeparment`, {cm13_id});
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    async reactTableFetchData(state, instance) {
        this.reactTableObj = {
            state: state,
            instance: instance
        };
        this.setState({loading: true});
        if (this.reactTableTarget) {
            try {
                let res = await this.ascAxios("post", this.reactTableTarget, {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    hidden_filtered: state.hidden_filtered
                });
                // findAndCountAll 内で group by を使うと、.count が array で返ってくるバグがある。
                // .count を length で計算し直すことにより、board のページングが正常に作動する。
                let count =0;
                if (Array.isArray(res.data.count)){
                    count =  res.data.count.length;
                } else {
                    count = res.data.count
                }
                
                this.setState({
                    data: res.data.rows,
                    pages: Math.ceil(count / state.pageSize),
                    count: count,
                    loading: false
                });
            } catch (e) {
                this.setState({
                    data: [],
                    pages: null,
                    count: null,
                    loading: false
                });

                if (!(e.status && e.status === 401) && !(e.response && e.response.status && e.response.status === 401)) {
                    alert("サーバーからデータ取得に失敗しました。");
                }
            }
        }
    }

    reactTableRefresh = () => {
        this.reactTableFetchData(this.table.current.state, this.table.current.instance);
    }

    /**
     * 自身の権限範囲が指定のあった権限範囲以上か判定する
     * @return {Boolean}
     */
    getScopeGreaterEqual = (compareObject) => {
        if (this.props.currentPermission.scope_code) {
            const myScope = this.props.currentPermission.scope_code;
            return GlobalConst.SCOPE_OBJECT[myScope].value <= compareObject.value;
        } else {
            return false;
        }
    }

    /**
     * 自身の権限範囲が指定のあった権限範囲以下か判定する
     * @return {Boolean}
     */
    getScopeLessThanEqual = (compareObject) => {
        if (this.props.currentPermission.scope_code) {
            const myScope = this.props.currentPermission.scope_code;
            return GlobalConst.SCOPE_OBJECT[myScope].value >= compareObject.value;
        } else {
            return false;
        }
    }

    /**
     * エラーメッセージの作成
     * @param error
     * @param {*} defaultMessage デフォルトメッセージ
     */
    showErrorObjectMesssage = (error, defaultMessage = "ProcessingFailedError") => {
        let errorMessage = "";
        
        if (error.response &&
            error.response.data &&
            (error.response.data.code || error.response.data.message)) {
            errorMessage = error.response.data.code || error.response.data.message;
            //サーバーで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (error.message) {
            errorMessage = error.message;
            //クライアントで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (typeof error === "string") {
            //文字列エラーを出力
            errorMessage = error;
        } else {
            errorMessage = defaultMessage;
        }
        alert(
            this.props.langText.Message[errorMessage] || this.props.langText.Message[defaultMessage]
        );
    }
}
