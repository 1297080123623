import React, { Component } from "react";
import { Glyphicon, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

/**
 * 吹き出しを表示するボタンを表示
 */
export default class SetButtonWithSpeechBubble extends Component {
    /**
     * マウスオーバーで説明が表示されるボタンコンポーネントを作成
     * @return {component}
     */
    setDisplayData = () => {
        const speechBubbleData = (
            <Tooltip id="tooltip">
                {this.props.speechBubble}
            </Tooltip>
        );
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={speechBubbleData}
                delayShow={300}>
                <span>
                    <Button
                        bsSize={this.props.bsSize}
                        className={this.props.className}
                        onClick={this.props.onClick}
                    >
                        <Glyphicon glyph={this.props.DisplayIcon} />
                    </Button>
                </span>
            </OverlayTrigger>
        );
    }

    render() {
        return (
            <span>
                {this.setDisplayData()}
            </span>
        );
    }
}
