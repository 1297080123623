import React from 'react'
import Component from '../../components/AscComponent'
import {FormGroup, FormControl, NavItem} from 'react-bootstrap'
import Link from 'react-router-bootstrap'
import LoadingButton from '../Elements/Button/LoadingButton'
import SignInModal from './SignInModal'
import './SignIn.css'

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // modal item
            show: false,
            modalType: null,

            // loading button item
            isLoading:         false,

            // data item
            emailLogin:        '',
            emailVerify:       '',
            emailReset:        '',
            password:          '',
            signUpCode:        '',
            resetPasswordCode: '',
            newPassword:       '',
            newPasswordRe:     '',
        }
    }

    modalShow = (modalType) => event => {
        event.preventDefault()

        let setData = {
            emailVerify: '',
            emailReset: '',
            signUpCode: '',
            resetPasswordCode: '',
            newPassword: '',
            newPasswordRe: '',
            show: true,
            modalType,
        }

        this.setState(setData)
    }

    validationHandle = param => {
        let nowState = null

        let {
            emailVerify,
            signUpCode,
            emailReset,
            resetPasswordCode,
            newPassword,
            newPasswordRe,
        } = this.state

        let exp = {
            // 英数字それぞれ 1 文字ずつ含む 8 桁以上 99 桁以下
            password: /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,99}$/i,
        }

        switch (param) {
            case 'emailVerify':
            case 'signUpCode':
            case 'emailReset':
            case 'resetPasswordCode':
                nowState = this.state[param]
                ? null
                : 'error'
            break

            case 'newPassword':
                nowState = (
                    this.state[param]
                    && exp.password.test(this.state[param]))
                    ? null
                    : 'error'
            break

            case 'newPasswordRe':
                nowState = (
                    this.state[param]
                    && exp.password.test(this.state[param])
                    && this.state[param] === newPassword)
                    ? null
                    : 'error'
            break

            case 'verify':
                nowState = (
                    emailVerify
                    && signUpCode
                )
                break

            case 'resetPassword':
                nowState = emailReset
                break

            case 'submitNewPassword':
                nowState = (
                    emailReset
                    && resetPasswordCode
                    && newPassword
                    && exp.password.test(newPassword)
                    && newPasswordRe
                    && newPassword === newPasswordRe
                )
                break

            default:
        }

        return nowState
    }

    validationForm = () => {
        return this.state.emailLogin.length > 0 && this.state.password.length > 0
    }

    onClickHandle = modalType => event => {
        let {
            emailVerify,
            emailReset,
            signUpCode,
            resetPasswordCode,
            newPassword,
        } = this.state

        switch (modalType) {
            case 'verify':
                this
                    .ascAxios('post', '/Sign/verify', {
                        emailVerify,
                        signUpCode,
                    })
                    .then(result => {
                        alert(this.props.langText.Message.SignIn_VerifySuccess)
                    })
                    .catch(err => {
                        let errCode = err.response.data.code

                        if (errCode === 24) {
                            // Entered email address was not found.
                            alert(this.getErrorString(err.response.data))

                        } else if (errCode === 21) {
                            // Entered validation code was not matched or expired.
                            // Resend validation code to entered mail address.
                            if (window.confirm(this.getErrorString(err.response.data) + this.props.langText.Message.SignIn_ConfirmResend)) {
                                this
                                    .ascAxios('post', '/Sign/resendCode', {
                                        emailVerify
                                    })
                                    .then(result => {
                                        // show message that successed to send code.
                                        alert(this.props.langText.Message.SignIn_SentVerificationCode)
                                    })
                                    .catch(err => {
                                        // show message that failed to send code.
                                        alert(this.getErrorString(err.response.data))
                                    })
                            }
                        } else {
                            alert(this.getErrorString(err.response.data))
                        }
                    })
                break

            case 'resetPassword':
                this
                    .ascAxios('post', '/Sign/resetPassword', {
                        emailReset
                    })
                    .then(result => {
                        alert(this.props.langText.Message.SignIn_SentVerificationCode)
                        this.setState({
                            modalType: 'submitNewPassword',
                        })
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data))
                    })
                break

            case 'submitNewPassword':
                this
                    .ascAxios('post', '/Sign/submitNewPassword', {
                        emailReset,
                        resetPasswordCode,
                        newPassword
                    })
                    .then(result => {
                        alert(this.props.langText.Message.SignIn_ResetPasswordSuccess)
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data))
                    })
                break

            default:
                alert(this.getErrorString({code: 'modal'}))
                break
        }

        if (modalType !== 'resetPassword') {
            this.setState({show: false})
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({isLoading: true})

        try {
            let response = await this.ascAxios('post', '/Sign/login', this.state)

            if (response.status === 200) {
                this.props.userHasAuthenticated(true, response.data)
                this.props.historyPush('/')
            } else {
                alert(this.props.langText.Message.SignIn_SignInFailed)
                this.setState({isLoading: false })
            }
        } catch (err) {
            //下記２つ以外のエラーにはデフォルトエラーメッセージ表示
            if(err.response.data.code !== 'NoDepartmentError' && err.response.data.code !== 'UserNotConfirmedException'){
                alert(this.getErrorString({code : "SignIn_SignInFailed"}));
            } else {
                alert(this.getErrorString(err.response.data));
            }
            this.setState({isLoading: false});

            //ユーザーの所属がない場合、エラー出した後リロード
            if(err.response.data.code == "NoDepartmentError"){
                window.location.reload();
            }
        }
    }

    render() {
        return (
            <div className='signIn'>
                <form class="form-block" onSubmit={this.handleSubmit}>
                    <FormGroup controlId='emailLogin' bsSize='large'>
                        <FormControl
                            autoFocus
                            type        = 'text'
                            value       = {this.state.emailLogin}
                            onChange    = {e => this.onTextChange(e, "emailLogin")}
                            placeholder = {this.props.langText.Body.EmailAddressLogIn}
                        />
                    </FormGroup>
                    <FormGroup controlId='password' bsSize='large'>
                        <FormControl
                            value       = {this.state.password}
                            onChange    = {e => this.onTextChange(e, "password")}
                            type        = 'password'
                            placeholder = {this.props.langText.Body.PasswordLogIn}
                        />
                    </FormGroup>
                    <div className='signIn-box'>
                    {/*<NavItem
                        className      = 'signIn-link'
                        componentClass = {Link}
                        onClick        = {this.modalShow('verify')}
                    >
                        {this.props.langText.Message.SignIn_VerifyLink}
                    </NavItem>*/}
                    <NavItem
                        className      = 'signIn-link'
                        componentClass = {Link}
                        onClick        = {this.modalShow('resetPassword')}
                    >
                        {this.props.langText.Message.SignIn_ResetPasswordLink}
                    </NavItem>
                    <LoadingButton
                        className   = 'signIn-button'
                        disabled    = {!this.validationForm()}
                        type        = 'submit'
                        isLoading   = {this.state.isLoading}
                        text        = {this.props.langText.Body.LogIn}
                        loadingText = 'loging in ......'
                    >
                    </LoadingButton>
                    </div>
                </form>

                <SignInModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onClick = {this.onClickHandle}
                    onTextChange = {this.onTextChange}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                />
            </div>
        )
    }
}