export default(column_name, lang) => {


    let CompanyControlParametersOption = {
        ALL_PARAMETERS: [
            {
                function_id: "CALL_RECORD", 
                parameter_id: "CALL_RECORD_OPTION",
                parameter_value: "N",
                memo: `${lang.CallRecordingOptionAllowed}`,
            },
        ],
        CALL_RECORD_OPTION : [
            {value :"Y",label:"ON"},
            {value :"N",label:"OFF"},
        ],
    };

    return CompanyControlParametersOption[column_name];
}