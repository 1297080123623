import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import AscAudio from "../../containers/Elements/Audio/Audio";
import VoiceFileModal from "./VoiceModal";
import * as GlobalConst from "../../components/AscConstants";

export default class VoiceFile extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],

            // data item
            voiceId: "",
            companyId: "",
            companyName: "",
            audioName: "",
            audioType: "",
            audioFile: [],
            audioURL: "",
            audioStream: [],
            audioSec: 0,
            memo: "",
            // button_disabled
            buttondisabledArr: {
                "company_id" : []
            },
        };

        
    }

    getColumnsData() {
        let returnArr = [];

        returnArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.id,
        });

        // oem以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            returnArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }

        returnArr.push({
            Header: this.props.langText.Body.AudioName,
            accessor: "voice_name",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "voice_name")} />
        });

        returnArr.push({
            Header: this.props.langText.Body.Memo,
            accessor: "memo",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
        });

        returnArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            accessor: "voice_file_url",
            width: this.props.boardWidth.XLargeControl,
            Cell: data => {
                let rowData = [];
                if (this.props.currentPermission.playback) {
                    rowData.push(
                        <AscAudio
                            key = {0}
                            type = "url"
                            title = {data.row.voice_name}
                            data = {data.value}
                            className = "control-button"/>
                    );
                }
                if (this.props.currentPermission.export) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={this.downloadData(data.value)}
                            className="control-button"
                        >
                            <Glyphicon glyph="download-alt"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={3}
                            bsSize="xsmall"
                            onClick={this.modalShow('update', data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={3}
                            bsSize="xsmall"
                            onClick={this.modalShow('read', data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={4}
                            bsSize="xsmall"
                            onClick={this.modalShow('delete', data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    );
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                );
            }
        });

        return returnArr;
    }

    // 共通処理
    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    downloadData = (path) => event => {
        this.ascAxios('get', this.baseURL + `/Download/s3SoundDownload/${encodeURIComponent(path)}`)
        .then(res => {
            const downLoadLink = document.createElement("a");
            downLoadLink.href = res.data;
            downLoadLink.click();
            downLoadLink.remove();
        })
        //失敗の場合
        .catch(err => {
            alert(this.getErrorString({code:44}));
        })
    };

    modalShow = (modalType, param) => event => {
        event.preventDefault();

        let setData = {
            voiceId: param && param.id
                ? param.id
                : "",
            companyId: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : "",
            audioName: param && param.voice_name
                ? param && param.voice_name
                : "",
            audioType: param && param.voice_type
                ? param.voice_type
                : "fileUpload",
            audioURL: param && param.voice_file_url
                ? param.voice_file_url
                : "",
            audioSec: param && param.audioSec
                ? param.audioSec
                : 0,
            memo: param && param.memo
                ? param.memo
                : "",
            audioFile: [],
            audioStream: [],
            modalType,
            show: true
            
        };

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.companyId = this.props.userInfo.company_id
            setData.companyName = this.props.userInfo.company_name
        }

        this.setState(setData);
        this.getCompanySelect();
    };

    audioDownload = (name, url) => event => {
        let myA = document.createElement("A");
        myA.download = name;
        myA.href = url;
        myA.click();
    };

    onFileChange(event, param) {
        let $this = this;
        super.onFileChange(event, param);

        if (param === "audioFile" && event.target.files[0]) {
            let audioElem = document.createElement("AUDIO");
            audioElem.preload = "metadata";
            audioElem.src = URL.createObjectURL(event.target.files[0]);

            audioElem.onloadedmetadata = function () {
                $this.setState({
                    audioSec: Math.round(audioElem.duration)
                })
            }
        }
    }

    onClickHandle = modalType => event => {
        let {
            voiceId,
            companyId,
            audioName,
            audioType,
            audioFile,
            audioURL,
            audioStream,
            audioSec,
            memo
        } = this.state;

        let myForm = document.createElement("FORM");
        myForm.enctype = "multipart/form-data";

        let uploadForm = new FormData(myForm);
        uploadForm.append("voiceId", voiceId);
        uploadForm.append("companyId", companyId);
        uploadForm.append("audioName", audioName);
        uploadForm.append("audioType", audioType);
        uploadForm.append("files", audioFile[0]);
        uploadForm.append("audioSec", audioSec);
        uploadForm.append("audioURL", audioURL);
        uploadForm.append("memo", memo);

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/insert`, uploadForm)
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;

            case "update":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/update`, uploadForm)
                    .then(result => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;

            case "delete":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/delete`, uploadForm)
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;

            default:
                alert(this.getErrorString({code: 'modal'}))
                break;
        }

        this.setState({
            show: false
        });
    };

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(result => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({
                    companySelect: result.data
                });
            })
            .catch(err => {
                alert(this.getErrorString(err))
            });
    };
    /*
        音声ファイルの指定云々は構成不明のため
        バリデーションから外してます
            →構成解析後導入するか含め検討します
    */
    validationHandle = param => {
        let nowState = null;
        switch(param){
            case "companyId":
            case "audioName":
                nowState = this.state[param]
                ? null
                : "error";
            break;
            case "insert":
            case "update":
            case "delete":
                let {companyId,audioName,audioFile} = this.state;
                if(param === "insert")
                {
                    nowState = (companyId && audioName && audioFile != '');
                }
                else
                {
                    nowState = (companyId && audioName);
                }
                break;
            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="VoiceFile">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id = "voiceFile-insert"
                                className = "table-button"
                                bsStyle = "default"
                                bsSize = "sm"
                                onClick = {this.modalShow("insert")}>
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <VoiceFileModal
                    loginUserId = {this.props.userInfo.user_id}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    onFileChange = {this.onFileChange}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    validationHandle = {this.validationHandle}/>
            </div>
        );
    }
}