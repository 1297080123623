import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {ViewCompanyElement} from "../Elements/AscElements/CompanyElements";
import {ViewPermissionName, ViewPermissionDetail} from "../Elements/AscElements/PermissionElements";


export default class PermissionReadModal extends Component {

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.PermissionInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <ViewCompanyElement
                            state={this.props.state}
                            langText={this.props.langText}>
                        </ViewCompanyElement>
                        <ViewPermissionName
                            state={this.props.state}
                            langText={this.props.langText}>
                        </ViewPermissionName>
                        <ViewPermissionDetail
                            state={this.props.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}>
                        </ViewPermissionDetail>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
