import React, {Component} from "react"
import {Panel,Glyphicon,Row, Col, Button} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import chara_limit from "../../chara_limit";
import AscAudio from "../../containers/Elements/Audio/Audio";
import "../Elements/FromGroup/form_select.css"
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import * as GlobalConst from "../../components/AscConstants";
import omniphone_example_left from "../../image/omniphone_example_left.png";
import omniphone_example_right from "../../image/omniphone_example_right.png";

export default class ExternalNumberModal extends Component {
    getExternalNumberInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    value = {state.company_id}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert"  || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))) {
            itemArr.push(
                <InlineForm
                    key = "gateway_id"
                    controlId = "gateway_id"
                    label = {langText.Body.GatewayName}
                    type = "select"
                    onChange = {e => onMultiSelectChange(e, "gateway_id")}
                    options = {state.gatewaySelect}
                    value = {state.gateway_id}
                    isClearable = {true}
                    validationState = {validationHandle("gateway_id")}
                    isMulti = {true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.gateway_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.gateway_id[0]}
                    />
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}
                    type = "text"
                    onChange = {e =>onTextChange_Limit(e, "memo",chara_limit.External_memo)}
                    value = {state.memo}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "display_number"
                    controlId = "display_number"
                    label = {langText.Body.DisplayNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e =>onTextChange_Limit(e, "display_number",chara_limit.External_display_number)}
                    value = {state.display_number}
                    validationState = {validationHandle("display_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "display_number"
                    controlId = "display_number"
                    label = {langText.Body.DisplayNumber}>
                    {state.display_number}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert"  || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "back_number"
                    controlId = "back_number"
                    label = {langText.Body.BackNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e =>onTextChange_Limit(e, "back_number",charaLimit.External_back_number)}
                    value = {state.back_number}
                    validationState = {validationHandle("back_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "back_number"
                    controlId = "back_number"
                    label = {langText.Body.BackNumber}>
                    {state.back_number}
                </InlineForm.static>
            );
        }

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "channel_number"
                    controlId = "channel_number"
                    label = {langText.Body.ChannelNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("channel_number",charaLimit.External_channel_number)}
                    value = {state.channel_number}
                    validationState = {validationHandle("channel_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "channel_number"
                    controlId = "channel_number"
                    label = {langText.Body.ChannelNumber}>
                    {state.channel_number}
                </InlineForm.static>
            );
        }

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "wait_number"
                    controlId = "wait_number"
                    label = {langText.Body.WaitNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("wait_number",charaLimit.External_wait_number)}
                    value = {state.wait_number}
                    validationState = {validationHandle("wait_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "wait_number"
                    controlId = "wait_number"
                    label = {langText.Body.WaitNumber}>
                    {state.wait_number}
                </InlineForm.static>
            );
        }

        if((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <Row key = {"sound_id"}>
                    <Col xs = {6} sm = {6} md = {11} lg = {11}>
                        <InlineForm
                            controlId = "sound_id"
                            label = {langText.Body.SoundNumber}
                            type = "select"
                            onChange={e => onSelectChange(e, "sound_id")}
                            options = {state.waitNumberSoundSelect}
                            value = {state.sound_id}
                            isClearable = {true}
                            placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                            isDisabled = {!state.buttondisabledArr.sound_id[0]}
                        />
                    </Col>
                    <Col xs = {6} sm = {6} md = {1} lg = {1}>
                        <AscAudio
                            type = "url"
                            data = {
                                    state.waitNumberSoundSelect[state.waitNumberSoundSelect.findIndex((v) => v.value === state.sound_id)] ? 
                                    state.waitNumberSoundSelect[state.waitNumberSoundSelect.findIndex((v) => v.value === state.sound_id)].url
                                    :
                                    `https://omni-cuscon-files-hon.s3-ap-northeast-1.amazonaws.com/asc_waiting_default_audio.gsm`
                                    }
                            className = "set_audio_button"
                        />
                    </Col>
                </Row>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "sound_id"
                    controlId = "sound_id"
                    label = {langText.Body.SoundNumber}>
                    {state.sound_id}
                </InlineForm.static>
            );
        }

        if ((state.modalType === "insert") || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.InboundGroupType}
                    type = "select"
                    onChange = {e => onSelectChange(e, "inbound_type")}
                    options = {state.inboundTypeSelect}
                    value = {state.inbound_type}
                    isClearable = {true}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("inbound_type")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.InboundGroupType}>
                    {
                        state.inbound_type === "normal"
                        ? langText.SelectOption.InboundGroupTypeNormal
                        : state.inbound_type === "android"
                        ? langText.SelectOption.InboundGroupTypeAndroid
                        : state.inbound_type === "all"
                        ? langText.SelectOption.InboundGroupTypeMulti
                        : ""
                    }
                </InlineForm.static>
            );
        }
    
        const styles = reactCSS({
            'default': {
                color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ state.omni_phone_back_ground_color}`,
                },
                swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                },
                popover: {
                position: 'absolute',
                zIndex: '2',
                },
                cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                },
                default_set: {
                width: '60px',
                height: '14px',
                borderRadius: '2px',
                },
                before_set: {
                width: '100px',
                height: '14px',
                borderRadius: '2px',
                },
                paddingLeft30: {
                paddingLeft: '30px',
                },
            },
        });
        const font_styles = reactCSS({
            'default': {
                color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ state.omni_phone_font_color}`,
                },
                font_color:{
                    color: `${ state.omni_phone_font_color}`,
                },
                swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                },
                popover: {
                position: 'absolute',
                zIndex: '2',
                },
                cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                },
                default_set: {
                width: '60px',
                height: '14px',
                borderRadius: '2px',
                },
                before_set: {
                width: '100px',
                height: '14px',
                borderRadius: '2px',
                },
                paddingLeft30: {
                paddingLeft: '30px',
                },
            },
        });

        const example_styles = reactCSS({
            'default': {
                left_font_color:{
                    color: `${ state.omni_phone_font_color}`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    textAlign:"left",
                },
                right_font_color:{
                    color: `${ state.omni_phone_font_color}`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    textAlign:"right",
                },
                flag_color:{
                    color: `${ GlobalConst.omni_phone_font_color}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    cursor: 'pointer',
                },
                background: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: GlobalConst.omni_phone_border_color,
                    height: '60px',
                    width: '250px',
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    cursor: 'pointer',
                    background: `${ state.omni_phone_back_ground_color}`,
                    display:"inline-table",
                },
                div_style: {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    marginTop:"10px",
                },
                div_img_left: {
                    display:"inline-table",
                    padding: "5px",
                },
                div_img_right: {
                    display:"inline-table",
                    padding: "5px",
                },
                img_left: {
                    height: '60px',
                    borderRadius: '10px 0px 0px 10px',
                    marginBottom:"30px",
                    borderWidth: "2px",
                    borderColor: "red",
                    borderStyle: "dashed",
                },
                img_right: {
                    height: '60px',
                    borderRadius: '0px 10px 10px 00px',
                    marginBottom:"30px",
                },
                parents_div:{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                },
                
            },
        });

        if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "OmniPhoneincomingNumberColor"
                        controlId = "OmniPhoneincomingNumberColor"
                        label = {langText.Body.OmniPhoneincomingNumberColor}
                    >
                        <div className="col-sm-1">
                            <div style={ styles.swatch } onClick={e => this.handleClick("back_ground")}>
                            <div style={ styles.color } />
                            </div>
                            { state.omni_phone_back_ground_display_color_picker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={e => this.handleClose("back_ground")}/>
                            <SketchPicker color={ state.omni_phone_back_ground_color } onChange={e =>this.handleChange("back_ground",e)} />
                            </div> : null }
                        </div>

                        <div className="col-sm-1">
                            <div style={ styles.swatch } onClick={e => this.defaultColorSet("back_ground")}>
                                <div style={ styles.default_set }>
                                    {langText.Body.OmniPhoneResetDefaultColorSet}
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-1" style={ styles.paddingLeft30 }>
                            <div style={ styles.swatch } onClick={e => this.beforeColorSet("back_ground")}>
                                <div style={ styles.before_set }>
                                    {langText.Body.OmniPhoneResetBeforeColorSet}
                                </div>
                            </div>
                        </div>
                    </InlineForm>
                );
                
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "OmniPhoneincomingNumberColor"
                    controlId = "OmniPhoneincomingNumberColor"
                    label = {langText.Body.OmniPhoneincomingNumberColor}>
                    <div>
                        <div style={ styles.swatch }>
                            <div style={ styles.color } />
                        </div>
                    </div>
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "OmniPhoneincomingFontColor"
                        controlId = "OmniPhoneincomingFontColor"
                        label = {langText.Body.OmniPhoneincomingFontColor}
                    >
                        <div className="col-sm-1">
                            <div style={ font_styles.swatch } onClick={e => this.handleClick("font")}>
                                <div style={ font_styles.color } />
                            </div>
                            { state.omni_phone_font_display_color_picker ? <div style={ font_styles.popover}>
                            <div style={ font_styles.cover } onClick={e => this.handleClose("font")}/>
                            <SketchPicker color={ state.omni_phone_font_color } onChange={e => this.handleChange("font",e)} />
                            </div> : null }
                        </div>
                        <div className="col-sm-1">
                            <div style={ font_styles.swatch } onClick={e => this.defaultColorSet("font")}>
                                <div style={ font_styles.default_set }>
                                    {langText.Body.OmniPhoneResetDefaultColorSet}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={ font_styles.paddingLeft30 }>
                            <div style={ font_styles.swatch } onClick={e => this.beforeColorSet("font")}>
                                <div style={ font_styles.before_set }>
                                    {langText.Body.OmniPhoneResetBeforeColorSet}
                                </div>
                            </div>
                        </div>
                    </InlineForm>
                );
                
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "OmniPhoneincomingFontColor"
                    controlId = "OmniPhoneincomingFontColor"
                    label = {langText.Body.OmniPhoneincomingFontColor}>
                    <div>
                        <div style={ font_styles.swatch }>
                            <div style={ font_styles.color } />
                        </div>
                    </div>
                </InlineForm.static>
            );
        }

        itemArr.push(
            <InlineForm
                key = "OmniPhoneExampleExplanation"
                controlId = "OmniPhoneExampleExplanation"
                label = {langText.Body.OmniPhoneExampleExplanation}
                style={example_styles.parents_div}
            >
                <div className="col-sm-12" style={example_styles.parents_div}>
                    <div style={ example_styles.div_img_left }>
                        <img style={ example_styles.img_left } src={omniphone_example_left}></img>
                    </div>
                    <div style={ example_styles.background }>
                        <div className="col-sm-12" style={ example_styles.div_style }>
                            <div className="col-sm-5" style={ example_styles.left_font_color }>
                                <label>着信番号<br/>012012345678</label>
                                {/* <label>012012345678</label> */}
                            </div>
                            <div className="col-sm-2" style={ example_styles.flag_color }>
                                <FontAwesomeIcon
                                icon = {faIcon.faChevronLeft}
                                size="2x"/>
                            </div>
                            <div className="col-sm-5" style={ example_styles.right_font_color }>
                                <label>相手先番号<br/>08012345678</label>
                                {/* <label>08012345678</label> */}
                            </div>
                        </div>
                    </div>
                    <div style={ example_styles.div_img_right }>
                        <img style={ example_styles.img_right } src={omniphone_example_right} ></img>
                    </div>
                </div>
            </InlineForm>
        );

        return itemArr;
    }
    defaultColorSet = (type) => {
        let {
            propSetState,
            state
        } = this.props;
        const omni_phone_font_color = state.omni_phone_font_color;
        const omni_phone_back_ground_color= state.omni_phone_back_ground_color;
        switch (type) {
            case "font":
                if (omni_phone_font_color != GlobalConst.omni_phone_font_color) {
                    propSetState({ before_omni_phone_font_color: omni_phone_font_color })
                }
                propSetState({ omni_phone_font_color: GlobalConst.omni_phone_font_color })
                break;
            case "back_ground":
                if (omni_phone_back_ground_color != GlobalConst.omni_phone_back_ground_color) {
                    propSetState({ before_omni_phone_back_ground_color: omni_phone_back_ground_color })
                }
                propSetState({ omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                break
            default:
                if (state.before_omni_phone_font_color != GlobalConst.omni_phone_font_color) {
                    propSetState({ before_omni_phone_font_color: GlobalConst.omni_phone_font_color })
                }
                if (state.before_omni_phone_back_ground_color != GlobalConst.omni_phone_back_ground_color) {
                    propSetState({ before_omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                }
                propSetState({ omni_phone_font_color: GlobalConst.omni_phone_font_color })
                propSetState({ omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                break;
        }
        
    }

    beforeColorSet = (type) => {
        let {
            propSetState,
            state
        } = this.props;

        switch (type) {
            case "font":
                if (state.before_omni_phone_font_color != "") {
                     propSetState({ omni_phone_font_color: state.before_omni_phone_font_color })
                }
                break;
            case "back_ground":
                if (state.before_omni_phone_back_ground_color != "") {
                    propSetState({ omni_phone_back_ground_color: state.before_omni_phone_back_ground_color })
                } 
                break;
            default:
                propSetState({ omni_phone_font_color: state.before_omni_phone_font_color })
                propSetState({ omni_phone_back_ground_color: state.before_omni_phone_back_ground_color })
                break;
        }
    }

    onClickPlusHandle() {
        let {
            state,
            propSetState,
            getDepartmentSelect,
        } = this.props;
        if (state["departmentInfos"].length < 10) {
            if (state.gateway_id.length > 0 ) {
                state["departmentInfos"].push({});
                propSetState({departmentInfos: state["departmentInfos"]});
                if (!this.props.state.floor_flg) {
                    getDepartmentSelect(state["departmentInfos"]);
                }
            } else {
                alert(this.props.langText.Message.ExternalNumber_GwNoSelect);   
            }
        } else {
            alert(this.props.langText.Message.ExternalNumber_DepartmentLimit10);   
        }
        
    
    }

    onClickMinusHandle(index) {
        let {
            state,
            propSetState,
            getDepartmentSelect,
            inboundGroupTypeSelectChanged,
        } = this.props;

        if (state["floorSelect"]) {
            state["floorSelect"].splice(index, 1);
            propSetState({floorSelect: state["floorSelect"]});
        }

        state["departmentInfos"].splice(index, 1);
        propSetState({departmentInfos: state["departmentInfos"]});
        if (!this.props.state.floor_flg) {
            getDepartmentSelect(state["departmentInfos"]);
        }
        if (state["departmentInfos"]) {
            let floor_id_arr = [];
            let department_id_arr = [];
            
            state["departmentInfos"].forEach((row,index) => {
                if (row.department_id) {
                    department_id_arr.push(row.department_id);
                }
                if (row.floor_id) {
                    floor_id_arr.push(row.floor_id);
                }
            });
            let inbound_type = state['inbound_type'];
            let select_dep = this.props.state.floor_flg ? floor_id_arr : department_id_arr;
            
            inboundGroupTypeSelectChanged({
                department_id: select_dep,
                inbound_type
            })
        }
    }

    handleClick = (type) => {
        let {
            state,
            propSetState,
        } = this.props;

        if (type == "font") {
            propSetState({ omni_phone_font_display_color_picker: !state.omni_phone_font_display_color_picker })
        } else if (type == "back_ground") {
            propSetState({ omni_phone_back_ground_display_color_picker: !state.omni_phone_back_ground_display_color_picker })
        }

    };
    
    handleClose = (type) => {
        let {
            propSetState,
        } = this.props;
        
        if (type == "font") {
            propSetState({ omni_phone_font_display_color_picker: false })
        } else if (type == "back_ground") {
            propSetState({ omni_phone_back_ground_display_color_picker: false })
        }
    };

    handleChange = (type,color) => {
        let {
            propSetState,
            state,
        } = this.props;

        if (type == "font") {
            if (state.before_omni_phone_font_color != state.omni_phone_font_color) {
                if (state.before_omni_phone_font_color !="") {
                    propSetState({ before_omni_phone_font_color: state.omni_phone_font_color });
                    
                } else {
                    propSetState({ before_omni_phone_font_color: color.hex });
                }
            }
            propSetState({ omni_phone_font_color: color.hex });
        } else if (type == "back_ground") {
               
            if (state.before_omni_phone_back_ground_color != state.omni_phone_back_ground_color) {
                if (state.before_omni_phone_back_ground_color !="") {
                    propSetState({ before_omni_phone_back_ground_color: state.omni_phone_back_ground_color });
                } else {
                    propSetState({ before_omni_phone_back_ground_color: color.hex });
                }
                
            }
            propSetState({ omni_phone_back_ground_color: color.hex });
        }
        
    };


    getDepartmentSettingInfoItem() {
        let {
            onDepartmentChange,
            state,
            langText,
            charaLimit,
            departmentInfos_onTextChange_Limit,
            onKeyDownIntCheck,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let mainitemArr = [];

        state.departmentInfos.forEach((row, index) => {
            let itemArr = [];

            if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理者以上
                itemArr.push(
                    <Button key="minus"
                        className="multiValueFormMinus"
                        onClick={e => this.onClickMinusHandle(index)}>
                        <Glyphicon glyph="minus"/>
                    </Button>
                );
            }
            if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理者以上
                if(this.props.state.floor_flg){
                    row.department_name = row.parent_department_name || row.department_name;
                }
                itemArr.push(
                    <InlineForm
                        key = "department_id"
                        controlId = "department_id"
                        label = {langText.Body.DepartmentName}
                        type = "select"
                        isClearable = {true}
                        onChange = {e => onDepartmentChange(e, "department_id", index)}
                        options = {state.departmentSelectArr[index] || state.departmentSelect}
                        value = {row.parent_department_id || row.department_id}
                        validationState = {validationHandle("department_id", index)}
                        placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.department_id[0]}
                        />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "department_id"
                        controlId = "department_id"
                        label = {langText.Body.DepartmentName}>
                        {row.department_name}
                    </InlineForm.static>
                );
            }

            if (this.props.state.floor_flg) {
                if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                    //拠点管理者以上
                    itemArr.push(
                        <InlineForm
                            key = "floor_id"
                            controlId = "floor_id"
                            label = {langText.Body.FloorName}
                            type = "select"
                            options = {state.floorSelect[index]|| []}
                            value = {row.floor_id || ""}
                            isClearable = {true}
                            onChange = {e => onDepartmentChange(e, "floor_id", index)}
                            placeholder = {langText.SelectOption.Placeholder}
                            validationState = {validationHandle("floor_id", index)}
                            placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                            isDisabled = {!state.buttondisabledArr.floor_id[0]}
                            />
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key = "floor_id"
                            controlId = "floor_id"
                            label = {langText.Body.FloorName}>
                            {row.floor_name}
                        </InlineForm.static>
                    );
                }
            }
            
            if (state.modalType === "delete" || state.modalType === "read") {
                itemArr.push(
                    <InlineForm.static
                        key = "prefix"
                        controlId = "prefix"
                        label = {langText.Body.Prefix}>
                        {row.prefix}
                    </InlineForm.static>
                );
            }
            else
            {
                itemArr.push(<InlineForm
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e =>departmentInfos_onTextChange_Limit(e, "prefix",index,charaLimit.External_prefix,state.departmentInfos)}
                    value = {row.prefix || ""}
                    validationState = {validationHandle("prefix", index)}
                    placeholder={langText.SelectOption.External_Prefix}
                    disabled={!row.department_id}
                    />
                );
                if(row.prefix_duplicationflag === true)
                {
                    itemArr.push(
                    <p key = {index}>
                        <font color="red">
                            {langText.Body.PrefixDuplicate}
                        </font>
                    </p>
                    );
                }
            }
            
            mainitemArr.push(
            <div key={index}> 
                {itemArr}
            </div>);
        });
        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            //会社管理者以上
            mainitemArr.push(
                <Button key="plus"
                    className="multiValueFormPlus"
                    onClick={e => this.onClickPlusHandle()}>
                    <Glyphicon glyph="plus"/>
                </Button>
            );
        }

        return <div>
            {mainitemArr}

        </div>;
    }

    inbound_group_id_disabled = () => {
        if(!this.props.state.department_id === true && !this.props.state.inbound_type === true)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    getInboundSettingInfoItem = () => {
        let {
            state,
            langText,
            onMultiSelectChangeExternal,
            validationHandle,
            charaLimit,
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "inbound_group_id"
                    controlId = "inbound_group_id"
                    label = {langText.Body.InboundGroupName}
                    type = "select"
                    onChange = {e => onMultiSelectChangeExternal(e, "inbound_group_id")}
                    options = {state.inboundGroupSelect}
                    value = {state.inbound_group_id}
                    isClearable = {true}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("inbound_group_id")}
                    isMulti = {true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.inbound_group_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.inbound_group_id[0]}/>
            );
            let drawdata = '';
            let drawcolor = 'black';
            if(state.inboundGroupcount >= charaLimit.ExtenCount)
            {
                drawdata = state.inboundGroupcount + " / " + charaLimit.ExtenCount + " " + langText.Body.ExtenCountOver_1 + charaLimit.ExtenCount + langText.Body.ExtenCountOver_2;
                drawcolor = 'red';
            }
            else
            {
                drawdata = state.inboundGroupcount + " / " + charaLimit.ExtenCount;
                drawcolor = 'black';
            }
            itemArr.push(
                <InlineForm.static
                    key = "exten_count"
                    controlId = "exten_count"
                    label = {langText.Body.ExtenCount}>
                        <font color={drawcolor}>
                            {drawdata}
                        </font>
                </InlineForm.static>
            )
        } else {
            let drawdata = "";
            for(let i = 0; i < state.inbound_group_name.length;i++){
                if(i === state.inbound_group_name.length - 1){drawdata = drawdata + state.inbound_group_name[i];}
                else{drawdata = drawdata + state.inbound_group_name[i] + ",";}
            }
            itemArr.push(
                <InlineForm.static
                    key = "inbound_group_id"
                    controlId = "inbound_group_id"
                    label = {langText.Body.InboundGroupName}>
                    {drawdata}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    getBusinessCalendarSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "business_calendar_id"
                    controlId = "business_calendar_id"
                    label = {langText.Body.BusinessCalendar}
                    type = "select"
                    onChange = {e => onSelectChange(e, "business_calendar_id")}
                    options = {state.businessCalendarSelect}
                    value = {state.business_calendar_id}
                    isClearable = {true}
                    validationState = {validationHandle("business_calendar_id")}
                    placeholder = {state.buttondisabledArr.business_calendar_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.business_calendar_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "business_calendar_id"
                    controlId = "business_calendar_id"
                    label = {langText.Body.BusinessCalendar}>
                    {state.business_calendar_name}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ExternalNumberSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getExternalNumberInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.DepartmentSetting}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getDepartmentSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.InboundSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getInboundSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.BusinessCalendarSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getBusinessCalendarSettingInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
