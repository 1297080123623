export default {
    InboundGroupTypeNormal: "一般",
    InboundGroupTypeAndroid: "オムニフォン",
    InboundGroupTypeMulti: "同時着信",
    InboundGroupTypeNormalSearch: "オムニコンタクト・ソフト/ハードフォン",
    InboundGroupTypeAndroidSearch: "オムニフォン",
    InboundGroupTypeMultiSearch: "オムニコンタクト・オムニフォン・ソフト/ハードフォン",
    InboundTypeSoftphone: "オムニコンタクト用内線番号",
    InboundTypeHardPhone: "ソフトフォン・ハードフォン用内線番号",
    InboundTypeAndroid: "オムニフォン用内線番号",
    Placeholder: "選択してください。",
    Kddi: "KDDI",
    Rakuten: "楽天",
    Prodelight: "プロディライト",
    Softbank: "ソフトバンク",
    SystemAuth: "システム管理者",
    CompanyAuth: "会社管理者",
    DepartmentAuth: "拠点管理者",
    FloorAuth: "フロア管理者",
    GeneralAuth: "一般",
    Extension: "内線番号",
    InboundGroup: "着信グループ",
    None: "無し",
    MonitoringFlgY: "モニタリングができる",
    MonitoringFlgN: "モニタリングができない",
    MonitoringFlgListY: "◯",
    MonitoringFlgListN: "×",
    External_Prefix: "自動生成します",
    Placeholder_defaultSound: "デフォルト音声を使用します",
    Placeholder_defaultexternal: "全番号",
    Placeholder_allResult: "全結果",
    Placeholder_none: "未選択",
    Placeholder_4_number: "数字4桁",
    Placeholder_None_Select: "初期から作成",
    Download_type: "ダウンロードタイプ",
    IncomingNoAnswer: "不在入電",
    Anonymous:"非通知",
    Clear : "初期化",
    LangJapan: "日本語",
    LangEnglish: "英語",
    AudioClassMizuki: "Mizuki（女性）",
    AudioClassTakumi: "Takumi（男性）",
    CustomColumnTelNo: "電話番号",
    CustomColumnAddress: "住所",
    AudioFileUpload: "音声ファイルアップロード",
    AudioSelect: "音声選択",
    AudioSynthetic: "音声合成",
    Yes: "あり",
    No: "なし",
    AudioNonePlay: "再生しない",
    EndCall: "切電",

    kubunList : [
        {value :1,label:"営業日"},
        {value :2,label:"休日"},
        {value :3,label:"削除"}
    ],
    hourShowList : [
        {value :"00",label:"00"},
        {value :"01",label:"01"},
        {value :"02",label:"02"},
        {value :"03",label:"03"},
        {value :"04",label:"04"},
        {value :"05",label:"05"},
        {value :"06",label:"06"},
        {value :"07",label:"07"},
        {value :"08",label:"08"},
        {value :"09",label:"09"},
        {value :"10",label:"10"},
        {value :"11",label:"11"},
        {value :"12",label:"12"},
        {value :"13",label:"13"},
        {value :"14",label:"14"},
        {value :"15",label:"15"},
        {value :"16",label:"16"},
        {value :"17",label:"17"},
        {value :"18",label:"18"},
        {value :"19",label:"19"},
        {value :"20",label:"20"},
        {value :"21",label:"21"},
        {value :"22",label:"22"},
        {value :"23",label:"23"},
    ],
    // 分
    minuteShowList : [
        {value :"00",label:"00"},
        {value :"05",label:"05"},
        {value :"10",label:"10"},
        {value :"15",label:"15"},
        {value :"20",label:"20"},
        {value :"25",label:"25"},
        {value :"30",label:"30"},
        {value :"35",label:"35"},
        {value :"40",label:"40"},
        {value :"45",label:"45"},
        {value :"50",label:"50"},
        {value :"55",label:"55"},
    ],

    actionFlag : [
        {value :1,label:"着信"},
        {value :2,label:"BUSY"},
        // {value :3,label:"転送"}, AGI修正後に戻す
        {value :4,label:"音声再生"},
    ],

    Floor: "フロア内",
    Department: "拠点内",
    Company: "会社内",
    Oem: "OEM内",
    System: "システム内",

}