import React, {Component} from "react"
import { Panel, Radio, Col, Glyphicon ,Button } from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import Favi from "../Elements/Favi/Favi"
import InlineForm from "../Elements/FromGroup/InlineForm"
import MultiSelect from "../Elements/Select/MultiSelect"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";


export default class ExtensionNumberModal extends Component {
    getExtensionNumberInfoItem = () => {
        let {
            state,
            propSetState,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onRadioChange,
            validationHandle,
            getinboundGroupName,
            getScopeGreaterEqual,
            omniphoneLoginStatusDelete,
        } = this.props;
        let itemArr = [];

        if (
            getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) &&
            (state.modalType === "insert" ||
                state.modalType === "insertBatch" ||
                state.modalType === "copy")
        ) {
            //oem権限以上の場合、会社選択可能
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                    type="select"
                    options={state.companySelect}
                    value={state.company_id}
                    onChange={e => onSelectChange(e, "company_id")}
                    isClearable={true}
                    validationState={validationHandle("company_id")}
                    placeholder={
                        !state.buttondisabledArr.company_id[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.company_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                >
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if (
            getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
            (state.modalType === "insert" ||
                state.modalType === "insertBatch" ||
                state.modalType === "copy")
        ) {
            //会社管理者権限以上の場合、拠点選択可能
            itemArr.push(
                <InlineForm
                    key="department_id"
                    controlId="department_id"
                    label={langText.Body.DepartmentName}
                    type="select"
                    options={state.departmentSelect}
                    value={state.department_id}
                    onChange={e => onSelectChange(e, "department_id")}
                    isClearable={true}
                    validationState={validationHandle("department_id")}
                    placeholder={
                        state.buttondisabledArr.department_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.department_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="department_id"
                    controlId="department_id"
                    label={langText.Body.DepartmentName}
                >
                    {state.department_name}
                </InlineForm.static>
            );
        }
        if (this.props.state.floor_flg) {
            if (
                getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) &&
                (state.modalType === "insert" ||
                    state.modalType === "insertBatch" ||
                    state.modalType === "copy")
            ) {
                //拠点管理者以上の場合、フロア選択可能
                itemArr.push(
                    <InlineForm
                        key="floor_id"
                        controlId="floor_id"
                        label={langText.Body.FloorName}
                        type="select"
                        options={state.floorSelect}
                        value={state.floor_id}
                        onChange={e => onSelectChange(e, "floor_id")}
                        isClearable={true}
                        placeholder={langText.SelectOption.Placeholder}
                        validationState={validationHandle("floor_id")}
                        placeholder={
                            state.buttondisabledArr.floor_load[0] ? (
                                <Glyphicon glyph="refresh" className="spinning" />
                            ) : (
                                langText.SelectOption.Placeholder
                            )
                        }
                        isDisabled={!state.buttondisabledArr.floor_id[0]}
                    />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key="floor_id"
                        controlId="floor_id"
                        label={langText.Body.FloorName}
                    >
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="sequence_number"
                    controlId="sequence_number"
                    label={langText.Body.ExtensionNumber}
                    type="text"
                    value={state.sequence_number}
                    onKeyDown={e => onKeyDownIntCheck(e)}
                    onChange={e => onTextChange_Limit(e, "sequence_number", 3)}
                    validationState={validationHandle("sequence_number")}
                    placeholder={langText.Message.Extension_SequenceNumberDigits}
                />
            );
        }

        if (state.modalType === "insertBatch") {
            itemArr.push(
                <InlineForm
                    key = "insert_count"
                    controlId = "insert_count"
                    label = {langText.Body.InsertCount}
                    type = "text"
                    value = {state.insert_count}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("insert_count",charaLimit.Extensions_insertcount)}
                    validationState = {validationHandle("insert_count")}/>
            );
        }

        if (
            state.modalType === "update" ||
            state.modalType === "delete" ||
            state.modalType === "read"
        ) {
            itemArr.push(
                <InlineForm.static
                    key="extension_number"
                    controlId="extension_number"
                    label={langText.Body.ExtensionNumber}
                >
                    {state.extension_number}
                </InlineForm.static>
            );
        }

        //デフォルト発信番号
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="external_number"
                    controlId="external_number"
                    label={langText.Body.DefaultExternalNum}
                    type="select"
                    options={state.externalNumSelect}
                    value={state.external_number}
                    onChange={e => onSelectChange(e, "external_number")}
                    isClearable={true}
                    validationState={validationHandle("external_number")}
                    placeholder={
                        state.buttondisabledArr.external_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder_none
                        )
                    }
                    isDisabled={!state.buttondisabledArr.external_number[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="external_number"
                    controlId="external_number"
                    label={langText.Body.DefaultExternalNum}
                >
                    {state.external_number_name}
                </InlineForm.static>
            );
        }


        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="inbound_type"
                    controlId="inbound_type"
                    label={langText.Body.ExtensionNumberType}
                    validationState={validationHandle("inbound_type")}
                >
                    {state.inboundTypeRadio.map((row, key) => (
                        <div key={key}>
                            <Col xs={10} className="inboundType-text">
                                <Radio
                                    name="inbound_type_radio"
                                    key={key}
                                    onChange={e => onRadioChange(e, "inbound_type")}
                                    checked={row.value === state.inbound_type}
                                    value={row.value}
                                >
                                    {row.label}
                                </Radio>
                            </Col>
                            <Col xs={2} className="inboundType-icon">
                                <Favi
                                    className="pull-right fa-fw fa-icon fa-icon-static"
                                    icon={row.value}
                                />
                            </Col>
                        </div>
                    ))}
                </InlineForm>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.ExtensionNumberType}>
                    <Favi
                        className="pull-right fa-fw fa-icon fa-icon-static"
                        icon={state.inbound_type}/>
                    {state.inboung_type_name}
                </InlineForm.static>
            );
        }

        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="inbound_group_id"
                    controlId="inbound_group_id"
                    label={langText.Body.InboundGroupName}
                    type="select"
                    options={state.inboundGroupSelect}
                    value={state.inbound_group_id}
                    isClearable={true}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={e => onMultiSelectChange(e, "inbound_group_id")}
                    placeholder={
                        state.buttondisabledArr.inbound_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.inbound_group_id[0]}
                />
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key="inbound_group_id"
                    controlId="inbound_group_id"
                    label={langText.Body.InboundGroupName}
                >
                    {getinboundGroupName()}
                </InlineForm.static>
            );
        }

        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="memo"
                    controlId="memo"
                    label={langText.Body.ExtensionName}
                    type="text"
                    value={state.memo}
                    onChange={e => onTextChange_Limit(e, "memo", charaLimit.Extension_memo)}
                    disabled={state.modalType === "delete"}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        // モニタリング権限
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="monitoring_flg"
                    controlId="monitoring_flg"
                    label={langText.Body.MonitoringFlg}
                    validationState={validationHandle("monitoring_flg")}
                >
                    {state.monitoringFlgRadio.map((row, key) => (
                        <div key={key}>
                            <Col xs={10} className="inboundType-text">
                                <Radio
                                    name="monitoring_flg_radio"
                                    key={key}
                                    onChange={e => onRadioChange(e, "monitoring_flg")}
                                    checked={row.value === state.monitoring_flg}
                                    value={row.value}
                                >
                                    {row.label}
                                </Radio>
                            </Col>
                        </div>
                    ))}
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key="monitoring_flg"
                    controlId="monitoring_flg"
                    label={langText.Body.MonitoringFlg}
                >
                    {state.monitoringFlgRadio.map((row, key) =>
                        row.value === state.monitoring_flg ? row.label : ""
                    )}
                </InlineForm.static>
            );
        }
        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}
                omniphoneLoginStatusDelete = {this.props.omniphoneLoginStatusDelete}
                >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ExtensionNumberSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getExtensionNumberInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
