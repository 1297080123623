import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import FloorModal from "./FloorModal";
import * as GlobalConst from "../../components/AscConstants";

export default class Floor extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            pbxSelect: [],
            departmentSelect: [],
            companySelect: [],

            // data item
            floorId: "",
            departmentId: "",
            departmentName: "",
            floorName: "",
            floorPrefix: "",
            pbx: "",
            memo: "",
            user_cnt: 0,
            // button_disabled
            buttondisabledArr: {
                "departmentId" : [],
                "pbx" : []
            },
        };
    }

    getColumnsData() {
        let columnArr = [];

        columnArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.id,
        });

        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //OEM権限以上は会社名を表示
            columnArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}
                />
            });
        }

        columnArr.push({
            Header: this.props.langText.Body.DepartmentName,
            accessor: "department_name",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "department_name")} />
        });

        columnArr.push({
            Header: this.props.langText.Body.FloorName,
            accessor: "floor_name",
            filterable: false,
        })

        columnArr.push({
            Header: this.props.langText.Body.Pbx,
            accessor: "cm92_pbx_infos.pbx_name",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm92_pbx_infos.pbx_name")} />
        });
        columnArr.push({
            Header: this.props.langText.Body.VoiceLogPassword,
            accessor: "call_record_password",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "call_record_password")} />
        });

        columnArr.push({
            Header: this.props.langText.Body.Memo,
            accessor: "memo",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
        });

        columnArr.push({
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('update', data.row._original, event)}
                                className="bgGray"
                            >
                                <Glyphicon glyph="pencil" className="colorWhite"/>
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('read', data.row._original, event)}
                                className="bgGray"
                            >
                                <Glyphicon glyph="eye-open" className="colorWhite"/>
                            </Button>
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('delete', data.row._original, event)}
                                className="bgGray">
                                <Glyphicon glyph="minus" className="colorWhite"/>
                            </Button>
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            });

        return columnArr;
    }

    modalshow_commonInit() {
        this.setState({
            user_cnt: 0,
            buttondisabledArr: {
                "departmentId" : [],
                "pbx" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    async modalShow (modalType, param, event) {
        try {
            event.preventDefault();
            this.modalshow_commonInit();
    
            let user_cnt = 0;

            if(modalType == "delete"){
                user_cnt = await this.getUserCntByDepartment(param.id);
            }
    
            let setData = {
                departmentId: param && param.id
                    ? param.id
                    : "",
                company_id: param && param.cm12_companies && param.cm12_companies.id
                    ? param.cm12_companies.id
                    : '',
                companyName: param && param.cm12_companies && param.cm12_companies.company_name
                    ? param.cm12_companies.company_name
                    : '',
                departmentName: param && param.department_name
                    ? param.department_name
                    : "",
                floorName: param && param.floor_name
                    ? param.floor_name
                    : "",
                floorPrefix: param && param.floor_prefix
                    ? param.floor_prefix
                    : "",
                pbx: param && param.cm92_pbx_infos && param.cm92_pbx_infos.id
                    ? param.cm92_pbx_infos.id
                    : "",
                call_record_password: param && param.call_record_password
                    ? param.call_record_password
                    : "",
                memo: param && param.memo
                    ? param.memo
                    : "",
                audio_record_password: "",
                user_cnt: user_cnt || 0,
                modalType,
                show: true,
            };
    
            // 会社選択肢セット
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
                this.getCommonCompanySelect(this.reactContainerPath)
                    .then(res => {
                        this.setState({companySelect: res.data.filter(row => row.floor_flg === "Y")})
                    }).catch(err => this.showErrorObjectMessage(err))
            }

            // 新規作成時にcompany以下なら会社固定
            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                setData.company_id = this.props.userInfo.company_id
                setData.companyName = this.props.userInfo.company_name
                setData.floor_flg = this.props.userInfo.floor_flg
            }
            // 新規作成時にdepartment以下なら拠点固定
            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
                if (this.props.userInfo.floor_flg) {
                    setData.departmentId = this.props.userInfo.parent_department_id
                    setData.departmentName = this.props.userInfo.parent_department_name
                } else {
                    setData.departmentId = this.props.userInfo.department_id
                    setData.departmentName = this.props.userInfo.department_name
                }
            }

            if (setData.company_id) {
                this.getPbxSelect({company_id: setData.company_id});
                this.getDepartmentSelect({company_id: setData.company_id});
            }
            this.setState(setData);
        } catch (err) {
            alert(this.getErrorString(err.response.data));
            return false;
        }
    };

    onClickHandle = modalType => event => {
        let {
            company_id,
            departmentId,
            departmentName,
            floorName,
            floorPrefix,
            pbx,
            memo,
            audio_record_password
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/insert`, {
                    company_id,
                    departmentId,
                    departmentName,
                    floorName,
                    floorPrefix,
                    pbx,
                    memo,
                    audio_record_password
                }).then(result => {
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh();
                }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;
            case "update":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/update`, {
                    departmentId,
                    departmentName,
                    floorName,
                    floorPrefix,
                    pbx,
                    memo,
                    audio_record_password
                }).then(result => {
                    if (result.data && result.data[0]) {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                    } else {
                        alert(this.props.langText.Message.DataUpdateError);
                    }
                    this.reactTableRefresh();
                }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;

            case "delete":
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/delete`, {
                    departmentId,
                    departmentName,
                    floorName,
                    floorPrefix,
                    pbx,
                    memo
                }).then(result => {
                    alert(this.props.langText.Message.DataDeleteSuccess);
                    this.reactTableRefresh();
                }).catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;

            default:
                alert(this.getErrorString({code: 'modal'}));
        }

        this.setState({
            show: false
        });
    };

    getDepartmentSelect = ({company_id}) => {

        this.ascAxios('post', `Common/departmentSelect`, {
            company_id,
            container: this.reactContainerPath,
            floor_flg: true
        }).then(result => {
            if ({company_id}) {
                this.common_buttondisabledLengthCheck(`departmentId`,`push`);
            } else {
                this.common_buttondisabledLengthCheck(`departmentId`,`pop`);
            }
            this.setState({
                departmentSelect: result.data
            });
        }).catch(err => {
            alert(this.getErrorString(err.response.data));
        });

    };

    getPbxSelect = ({company_id}) => {
        this.ascAxios('post', `Common/pbxSelect`, {
            companyId: company_id,
            container: this.reactContainerPath
        }).then(result => {
            if({company_id})
            {
                this.common_buttondisabledLengthCheck(`pbx`,`push`);
            }
            else
            {
                this.common_buttondisabledLengthCheck(`pbx`,`pop`);
            }
            this.setState({
                pbxSelect: result.data
            });
        }).catch(err => {
            alert(this.getErrorString(err.response.data));
        });
    }

    onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";


        switch (param) {
            case "company_id":
                this.setState({
                    department_id: ''
                });
                this.getDepartmentSelect({company_id: value});
                this.getPbxSelect({company_id: value});
                this.setState({
                    pbx: "",
                    departmentId: "",
                    [param]: value
                });
                this.setState({[param]: value});
                break;
            default:
                this.setState({[param]: value});
                break;
        }
    }

    validationHandle = param => {
        let nowState = null;

        const {
            departmentId, 
            floorName,
            pbx, 
            audio_record_password,
        } = this.state;

        switch (param) {
            case "departmentId":
            case "departmentName":
            case "floorName":
            case "pbx":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "audio_record_password":
                nowState = !this.state[param] || this.state[param].length === 4
                    ? null
                    : "error";
                break;

            case "floorPrefix":
                if (this.state[param] && this.state[param].length === 2) {
                    nowState = null
                } else {
                    nowState = "error";
                }
                break;
            case 'company_id':
                nowState = !!this.state[param]
                break;
            case "insert":
                nowState = (
                    departmentId && floorName && pbx && (!audio_record_password || audio_record_password.length === 4)
                );
                break;

            case "update":
                let password_check = true;

                if (audio_record_password.length!=0) {
                    password_check = (audio_record_password.length == 4);
                }
            
                nowState = (
                    departmentId && floorName && pbx && password_check
                );
                break;

            case "delete":
                nowState = true;
                break;

            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="Floor">
                <Row>
                    {
                        // 権限25でも新規ボタンを押せる
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="department-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={event => this.modalShow("insert", null, event)}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <FloorModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    validationHandle = {this.validationHandle}
                    sprintf = {this.sprintf}/>
            </div>
        );
    }
}