import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, HelpBlock } from "react-bootstrap";

export default class ASFieldGroup extends Component {
    render() {
        const {
            id,
            label,
            validationState,
            groupStyle = {},
            labelStyle = {},
            readOnly,
            ...props
        } = this.props;

        const help = validationState;
        const vali = validationState ? "error" : null;

        return (
            <FormGroup controlId={id} style={groupStyle} validationState={vali}>
                {label && <ControlLabel style={labelStyle}>{label}</ControlLabel>}
                {props.children ? props.children : readOnly === true ? <FormControl.Static>{this.props.value}</FormControl.Static> : <FormControl {...props} />}
                {help && <HelpBlock color="red">{help}</HelpBlock>}
            </FormGroup>
        );
    }
}