import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css"


export default class ScriptCreateModal extends Component {
    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.Script}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.props.scope_code === "system" &&
                            <InlineForm
                                label = {this.props.langText.Body.CompanyName}
                                type = "select"
                                value = {this.props.state.company_id}
                                onChange = {e => this.props.onSelectChange(e, "company_id")}
                                options = {this.props.state.company_list}
                                validationState = {this.props.state.company_id ? null : "error"}
                                placeholder = {this.props.langText.SelectOption.Placeholder}/>
                        }
                        <InlineForm
                            label = {this.props.langText.Body.ScriptName}
                            type = "text"
                            value = {this.props.state.script_name}
                            onChange = {e => this.props.onTextChange(e, "script_name", this.props.charaLimit.Script_name)}
                            validationState = {this.props.state.script_name ? null : "error"}/>
                        <InlineForm
                            label = {this.props.langText.Body.Description}
                            type = "text"
                            value = {this.props.state.description}
                            onChange = {e => this.props.onTextChange(e, "description", this.props.charaLimit.Script_description)}/>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
