export default {
    AllSelect: "全選択",
    AllDelete: "全解除",
    ExtensionAndMemoSearch: "内線番号・名称検索",
    InboundGroupSearch: "着信グループ名検索",
    BusinessCalendarSearch: "営業カレンダー検索",
    ExtensionSelect: "内線選択",
    InboundGroup: "着信グループ",
    SelectedNum: "件が選択されています。",
    SelectedNum_Delete: "件が所属しています。",
    NoneData: "データが存在しません。",
    SelectDataNumStr_NDelete: "内線番号が選択されました",
    SelectDataNumStr_Delete: "内線番号が所属しています",

}