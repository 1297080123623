export default {
    Wait: "少々お待ちください。",
    // モーダルタイトル
    Title: "タイトル",
    InsertTitle:      "新規登録",
    InsertBatchTitle: "一括登録",
    UpdateTitle:      "編集",
    DeleteTitle:      "削除",
    CopyTitle:      "コピー",
    ReadTitle: "閲覧",
    Version: "バージョン",
    ErrorTitle: "エラー内容",
    NewInsert: "新規作成",

    // ボタン
    Insert: "登録",
    Add:    "追加",
    Update: "更新",
    BatchDelete: "一括削除",
    Delete: "削除",
    Cancel: "キャンセル",
    Set:    "設定",
    Check: "確認",
    Upload: "アップロード",
    Submit: "送信",
    Verify: "ユーザー認証",
    ResetPassword: "パスワード再設定",
    LogIn: "ログイン",
    LogGingIn :"ログイン中",
    LogOut :"ログアウト",
    Search: "検索",
    Copy: "コピー",
    Read: "閲覧",
    Close: "閉じる",
    Miss_Inbound: "未着信",
    Save: "保存",
    Insert2: "挿入",
    AdSet: "詳細設定",
    InsertTelNo: "番号追加",
    Back: "戻る",
    OmniPhoneLogOut: "オムニフォンログイン状態リセット",

    // 一般項目
    Id:             "ID",
    Control:        "操作",
    Memo:           "メモ",
    CompanyName:    "会社名",
    DepartmentName: "拠点名",
    Lang: "言語",
    Text: "テキスト",
    Number: "番号",

    // 特定項目
    AllDepartment: "全拠点",
    AllFloor: "全フロア",
    Audio: "音声",
    AudioInfo: "音声ファイル設定",
    AudioSetting: "音声ファイル設定",
    AudioName: "音声名",
    Auth: "権限",
    AutoCreate:	"自動生成",
    BackNumber: "裏番号",
    BindPort: "BIND Port",
    Created: "登録日",
    CompanySetting:"会社設定",
    CustomerCompanyName: "会社名(顧客)",
    CustomerInfo: "顧客情報",
    CustomerInsert: "顧客登録",
    CustomerName: "顧客名",
    CustomerNameCc: "顧客名(漢字)",
    CustomerNameKana: "顧客名(カナ)",
    DateFormat: "YYYY/MM/DD",
    DepartmentInfo: "拠点情報",
    DepartmentSetting: "拠点設定",
    DisplayNumber: "表示番号",
    Download: "ダウンロード",
    EmailAddress: "メールアドレス",
    EmailAddressLogIn: "Email address",
    EntryUserName: "登録者",
    ExtensionNumber:"内線番号",
    ExtensionNumberInfo: "内線情報",
    ExtensionNumberSetting: "内線番号設定",
    ExtensionNumberNum: "内線番号数",
    ExtensionNumberType: "内線種類",
    ExternalNumberInfo:	"外線情報",
    ExternalNumberSetting:	"外線番号設定",
    ExternalNumber:"外線番号",
    FileUpload:	"ファイルアップロード",
    FirstNameCc: "姓",
    FirstNameKana: "セイ",
    FloorSetting:"フロア設定",
    FtpPort: "FTP Port",
    GatewayInfo: "GW情報",
    GatewaySetting: "GW設定",
    GatewayName: "GW名",
    GatewayRegisterId: "GW登録ID",
    GatewayRegisterPW: "GW登録PW",
    GatewayIpaddress: "GWIP",
    GatewayPort: "GWポート",
    GatewayType: "GW区分",
    GroupName: "グループ名",
    GroupType: "グループ種類",
    HostName: "ホスト名",
    InboundGroupInfo: "内線グループ情報",
    InboundGroupSetting: "内線グループ設定",
    InboundGroupName: "内線グループ",
    InboundGroupType: "内線タイプ",
    GroupExtensionNumberInfo: "グループ番号情報",
    GroupExtensionNumberSetting: "グループ番号設定",
    InboundRejectInfo: "着信拒否情報",
    InboundRejectSetting: "着信拒否設定",
    InboundRejectRule: "着信拒否ルール",
    InboundSetting: "着信設定",
    InsertCount: "登録番号数",
    LastLoginDate: "最終ログイン日",
    LastNameCc:	"名",
    LastNameKana: "メイ",
    Length2: "数字2桁",
    Length3: "数字3桁",
    Modified: "更新日",
    OmniURL: "オムニURL",
    OutboundRejectInfo:	"発信規制情報",
    OutboundRejectSetting:	"発信規制設定",
    OutboundRejectRule: "発信規制ルール",
    Password: "パスワード",
    PasswordLogIn: "Password",
    PasswordNew: "新パスワード",
    PasswordNewRe: "新パスワード(再入力)",
    PasswordOld: "旧パスワード",
    PasswordRe:	"パスワード(再入力)",
    Pbx: "PBX",
    PbxInfo: "PBX情報",
    PbxSetting: "PBX設定",
    PbxName: "PBX名",
    Prefix: "プレフィックス",
    PrivateIP: "ローカルネット",
    PublicIP: "外部IPアドレス",
    Record:	"録音",
    SpeechSynthesis: "音声合成",
    Tag: "タグ",
    Telno: "電話番号",
    RequiredTelno: "電話番号（必須）",
    UpdateUserName:	"更新者",
    UserInfo: "ユーザー情報",
    UserInsert: "ユーザー登録",
    UserName: "ユーザー名",
    UserNameCc: "ユーザー名 (漢字)",
    UserNameKana: "ユーザー名 (カナ)",
    VerificationCode: "認証コード",
    WssPort: "WSS Port",
    SettingType: "設定タイプ",
    InOutType: "発着区分",
    DestUserNumber: "相手先番号",
    DestUserName: "相手先名",
    StartTime: "開始時間",
    AnswerdTime: "応答時間",
    EndTime: "終了時間",
    TalkingTime: "通話時間",
    Result: "結果",
    Floor: "フロア",
    FloorName: "フロア名",
    VoiceLogPassword: "通話録音認証パスワード",
    VoiceLogPasswordNow: "[現]通話録音認証パスワード",
    VoiceLogPasswordNew: "[新]通話録音認証パスワード",
    VoiceLogPasswordNewRe: "[新]通話録音認証パスワード(再入力)",
    OmniPhoneincomingNumberColor: "オムニフォン着信時(背景色)",
    OmniPhoneincomingFontColor: "オムニフォン着信時(文字色)",
    OmniPhoneExampleExplanation: "オムニフォン着信時のイメージ",
    OmniPhoneResetDefaultColorSet: "リセット",
    OmniPhoneResetBeforeColorSet: "以前の色に戻す",
    OemUsed: "OEM使用",
    OemSetting: "OEM設定",
    CsvImport: "CSV投入",
    RunType: "動作",
    Second: "秒",


    // 営業カレンダー
    Sunday: "日",
    Monday: "月",
    Tuesday: "火",
    Wednesday: "水",
    Thursday: "木",
    Friday: "金",
    Saturday: "土",
    Holiday: "祝",
    DayBeforeHoliday: "祝前",
    DayOff: "休日",
    UnknownDay: "曜日不明",
    Date: "日付",
    Hour: "時",
    Minute: "分",
    Day: "選択日",
    Class: "区分",
    BusinessCalendar: "営業カレンダー",
    BusinessCalendarSetting: "営業カレンダー設定",
    BusinessCalendarName: "営業カレンダー名",
    BusinessHourSetting: "営業時間設定",
    BusinessHour: "営業時間",
    BusinessDay: "営業日",
    BusinessHourIn: "営業時間内",
    BusinessHourOut: "営業時間外",
    Behavior: "挙動",
    BehaviorSetting: "挙動設定",
    IrregularSetting: "イレギュラー設定",
    BusinessHourInBehavior: "営業時間内の挙動",
    BusinessHourOutBehavior: "営業時間外の挙動",
    AddIrregular: "イレギュラー追加",
    Inbound: "着信",
    Busy: "BUSY",
    Transfer: "転送",
    PlayVoice: "音声再生",
    MegaCall: "MEGAコール",
    WeekOrnament: "曜日",
    HolidayOrnament: "日",
    AllCheck: "一括選択",
    AllUnCheck: "一括解除",
    Cautioncompanyselect: "会社を選択してください",
    Cautiondatadiff: "選択した曜日のデータが異なりますので、前のデータは上書きされます",
    Cautionyoubiselect: "曜日を選択してください",
    IrregularBatchSetting: "イレギュラー一括設定",
    CheckInput: "入力内容確認",
    BusinessCalendarSelect: "営業カレンダー選択",
    Type: "種類",

    // 会社設定
    OutboundPrefix: "発信開始番号",

    // 外線番号設定
    ChannelNumber: "最大着信数",
    WaitNumber: "最大待ち呼数",
    SoundNumber: "待ち呼音声",
    ExtenCount: "合計内線数(重複除く)",
    ExtenCountOver_1: "登録可能な内線数(",
    ExtenCountOver_2: ")を超過しています",
    PrefixDuplicate: "このプレフィックスは現在使用されています",

    // 内線番号設定
    DefaultExternalNum: "デフォルト発信番号",
    UpdateAllExternalNum: "発信番号一括更新",
    None: "無し",
    ExtensionName: "名称",
    MonitoringFlg: "モニタリング権限",
    // グループ番号設定
    GroupExtensionNumberName: "グループ番号名",
    GroupExtensionNumber: "グループ番号",

    // customer
    CustomerCsvImport: "CSV投入",
    CustomerFormatDownload: "CSVテンプレートダウンロード",
    CustomerCsvCompanyName: "顧客会社名",
    CustomerCsvUserFirstName: "顧客姓",
    CustomerCsvUserLastName: "顧客名",
    CustomerCsvUserFirstNameKana: "顧客姓(カナ)",
    CustomerCsvUserLastNameKana: "顧客名(カナ)",
    CustomerCsvUserMailAddress: "顧客メールアドレス",
    CustomerCsvTel: "電話番号",
    CustomerCsvTel2: "電話番号2",
    CustomerCsvTel3: "電話番号3",
    CustomerCsvTel4: "電話番号4",
    CustomerCsvTel5: "電話番号5",
    CustomerCsvTel6: "電話番号6",
    CustomerCsvTel7: "電話番号7",

    CustomerBoardStart: "開始日",
    CustomerBoardPartner: "相手先番号",
    CustomerBoardExternal: "内線番号",
    CustomerBoardExtension: "外線番号",

    //通話履歴
    Today: "今日",
    InoutResult: "発着区分",
    CallHistory: "通話結果",
    CallHistoryDownload:"通話履歴ダウンロード",
    IncomingNoAnswer: "不在入電",
    Template: "テンプレート",
    
    //通話履歴CSV
    CallHistoryCsvName: "通話履歴",
    InOutTypeCsv: "発着信区分",
    ExternalNumberMemo: "外線番号メモ",
    StartDateTime: "通話開始時間",
    AnswerDateTime: "通話成立時間",
    EndDateTime: "通話終了時間",
    BillSec: "通話時間（秒）",

    // 音声情報
    VoiceListUpdate: "更新者:",
    VoiceListCreate: "作成者:",

    // ユーザー管理
    DownloadFlag: "通話録音ダウンロード",
    DownloadTrue: "ダウンロード可能",
    DownloadFalse: "ダウンロード不可",


    PartialMatch:"部分一致",
    ForwardMatch:"前方一致",

    //スクリプト管理
    Script: "スクリプト",
    ScriptInfo: "スクリプト情報",
    ScriptName: "スクリプト名",
    CurrentVersionHeader: "適用Ver",
    CurrentDescription: "適用バージョンメモ",
    LatestVersionHeader: "最新Ver",
    LatestDescription: "最新バージョンメモ",
    VersionSetting: "バージョン設定",
    VersionSelect: "バージョン選択",
    VersionCheck:"バージョン確認",
    CheckTelNo:"確認用電話番号",
    Description: "メモ",
    CurrentVersion: "適用バージョン",
    LatestVersion: "最新バージョン",
    VersionMemo:"バージョンのメモ",
    Call:"発信",
    ScriptTelNo:"電話番号　例）07012345678",
    CurrentVersionLabel:"（現在適用バージョン）",
    TemplateUpdate: "テンプレート設定",

    // 権限管理
    BasePermissionName: "基本権限",
    PermissionName: "権限名",
    UserCount: "ユーザー数",
    PermissionInfo: "権限情報",
    permissionDetail: "権限詳細",
    PermissionContent: "権限内容",
    PermissionScope: "権限範囲",
    PermissionAll: "全権限",
    PermissionRead: "閲覧",
    PermissionCreate: "作成",
    PermissionEdit: "編集",
    PermissionDelete: "削除",
    PermissionExport: "出力",
    PermissionPlayback: "録音再生",
    PermissionContentList: {
        Gateway: "GW管理",
        Pbx: "PBX管理",
        ExternalNumber: "外線番号管理",
        ExtensionNumber: "内線番号管理",
        InboundGroup: "内線グループ管理",
        GroupExtensionNumber: "グループ番号管理",
        OutboundReject: "発信規制管理",
        InboundReject: "着信拒否管理",
        BusinessCalendar: "営業時間管理",
        VoiceFile: "音声管理",
        Company: "会社管理",
        Department: "拠点管理",
        Floor: "フロア管理",
        User: "ユーザ管理",
        Customer: "顧客管理",
        Script:"スクリプト管理",
        CallHistory: "通話履歴",
        Permission: "権限管理",
    },
    PermissionScopeList: {
        floor: "フロア内",
        department: "拠点内",
        company: "会社内",
        oem: "OEM内",
        system: "システム内"
    },
    chargedOption : "有料オプション",
    callRecordOption : "通話録音オプション",

    // スクリプトテンプレート
    ScriptSesscion: "{0}セクション設定",
    ScriptStart: "開始",
    ScriptPlay: "再生",
    ScriptQuestion: "質問",
    ScriptNumberInput: "番号入力",
    ScriptTransfer: "転送",
    ScriptRecord: "録音",
    ScriptCount: "カウント",
    ScriptJump: "ジャンプ",
    ScriptSMS: "SMS送信",
    ScriptIncoming: "着信",
    ScriptTemplate: "テンプレート",
    ScriptTemplateCreate: "テンプレート作成",
    ScriptNewVersionCreate: "新しいバージョン作成",
    ScriptCreateVersion: "作成元バージョン",
    ScriptAudioSyntheticSetting: "音声合成設定",
    ScriptAudioClass: "音声分類",
    ScriptCustomColumn: "カスタム項目",
    ScriptAnswerNumber: "回答番号",
    ScriptRepeat: "繰り返し",
    ScriptAccept: "有効",
    ScriptAcceptQuestion: "有効質問",
    ScriptNumLength: "桁数",
    ScriptInputDataCheck: "入力後の確認",
    ScriptInputDataCheckSetting: "入力後の確認設定",
    ScriptCallAudio: "呼び出し音声",
    ScriptTimeoutAudio: "タイムアウト音声",
    ScriptTransferTelNumber: "転送先電話番号",
    ScriptTransferSeatNumber: "転送先席数",
    ScriptTransferTimeout: "転送タイムアウト",
    ScriptRecordCancelNumber: "録音停止番号",
    ScriptMaxRecordTime: "最大録音時間",
    ScriptSmsTitle: "SMSタイトル",
    ScriptSmsText: "SMS本文",
    ScriptTimeoutSetting: "タイムアウトの設定",
    ScriptOthersNumberSetting: "その他の番号の設定",
    ScriptNextSession: "次のセクション",
    ScriptZoomToFit: "画面調整",
    AutoCallListManagement: "リスト管理",
    ItemNameManagement: "項目管理",
    ItemNameCount: "有効：",
    ItemNameDeleteCount: "無効：",
    DuplicateTelno: "電話番号重複チェック",

}

