import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl, Modal, Form, Panel} from 'react-bootstrap'
import Component from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import CustomerModal from './CustomerModal'
import * as GlobalConst from '../../components/AscConstants';

export default class Customer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns:          this.getColumnsData(),
            data:             [],
            filtered: [],
            pages:            null,
            loading:          false,

            // modal item
            show:             false,
            modalType:        null,
            errShow:          false,
            errText:          "",

            // select item
            companySelect: [],

            // csv format
            csvHeader: [
                this.props.langText.Body.CustomerCsvTel,
                this.props.langText.Body.CustomerCsvCompanyName,
                this.props.langText.Body.CustomerCsvUserFirstName,
                this.props.langText.Body.CustomerCsvUserLastName,
                this.props.langText.Body.CustomerCsvUserFirstNameKana,
                this.props.langText.Body.CustomerCsvUserLastNameKana,
                this.props.langText.Body.CustomerCsvUserMailAddress,
                this.props.langText.Body.CustomerCsvTel2,
                this.props.langText.Body.CustomerCsvTel3,
                this.props.langText.Body.CustomerCsvTel4,
                this.props.langText.Body.CustomerCsvTel5,
                this.props.langText.Body.CustomerCsvTel6,
                this.props.langText.Body.CustomerCsvTel7
            ],

            // data item
            company_id:          '',
            companyName:         '',
            customerId:          '',
            firstNameCc:         '',
            lastNameCc:          '',
            firstNameKana:       '',
            lastNameKana:        '',
            customerCompanyName: '',
            mailAddress:         '',
            telNo:               [],
            telType:             [],
            csvFile:             [],

            // button_disabled
            buttondisabledArr: {
                "company_id" : []
            },
        }
    }

    getColumnsData() {
        let itemArr = [];
        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });
        // oem以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.CustomerCompanyName,
            accessor: 'company_name',
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "company_name")} />
        });
        itemArr.push({
            Header: this.props.langText.Body.FirstNameCc,
            accessor: 'first_name',
            width: this.props.boardWidth.UserName,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "first_name")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.LastNameCc,
            accessor: 'second_name',
            width: this.props.boardWidth.UserName,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "second_name")} />
        });
        itemArr.push({
            Header: this.props.langText.Body.Telno,
            accessor: 'ct01_customer_number.tel_no',
            width: this.props.boardWidth.CallNumber,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "ct01_customer_number.tel_no")} />
        });
        itemArr.push({
            Header: this.props.langText.Body.EmailAddress,
            accessor: 'mail_address',
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "mail_address")} />
        });
        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={this.modalShow('update', data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    )
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("read", data.row._original)}
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow('delete', data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    )
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                )
            }
        });

        return itemArr;
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault()

        this.modalshow_commonInit();

        let setData = {
            customerId: param && param.id
                ? param.id
                : "",
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : "",
            customerCompanyName: param && param.company_name
                ? param.company_name
                : "",
            firstNameCc: param && param.first_name
                ? param.first_name
                : "",
            lastNameCc: param && param.second_name
                ? param.second_name
                : "",
            firstNameKana: param && param.first_name_kana
                ? param.first_name_kana
                : "",
            lastNameKana: param && param.second_name_kana
                ? param.second_name_kana
                : "",
            mailAddress: param && param.mail_address
                ? param.mail_address
                : "",
            csvFile: [],
        }
        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.company_id = this.props.userInfo.company_id
            setData.companyName = this.props.userInfo.company_name
            setData.floor_flg = this.props.userInfo.floor_flg
        }

        this.setState(setData);

        this.getCompanySelect()

        this.getAllTelNo(setData)

        this.setState({show: true, modalType})

    }

    onClickHandle = modalType => event => {
        let {
            customerId,
            company_id,
            firstNameCc,
            lastNameCc,
            firstNameKana,
            lastNameKana,
            customerCompanyName,
            mailAddress,
            telNo,
            csvFile
        } = this.state

        switch (modalType) {
            case 'insert':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        company_id: company_id,
                        first_name: firstNameCc,
                        second_name: lastNameCc,
                        first_name_kana: firstNameKana,
                        second_name_kana: lastNameKana,
                        customer_company_name: customerCompanyName,
                        mail_address: mailAddress,
                        tel_no: telNo
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataInsertSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                break

            case 'csvInsert':
                this.blockUI();
                let myForm = document.createElement("FORM");
                myForm.enctype = "multipart/form-data";

                let uploadForm = new FormData(myForm);
                uploadForm.append("company_id", company_id);
                uploadForm.append("files", csvFile[0]);

                this
                    .ascAxios('post', `${this.reactContainerPath}/csvInsert`, uploadForm)
                    .then(result => {
                        let message = [];
                        message.push(this.sprintf(this.props.langText.Message.SuccessDataCount, result.data.count));
                        message.push(this.sprintf(this.props.langText.Message.FailedDataCount, result.data.err.count));

                        if (result.data.err.count) {
                            result.data.err.body.forEach(row => {
                                message.push(`${this.sprintf(this.props.langText.Message.Line, row.line + 1)}${this.getErrorString(row)}`);
                            });

                            this.setState({
                                errShow: true,
                                errText: message.join("\r\n")
                            });
                        } else {
                            alert(message.join("\r\n"));
                        }

                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break

            case 'update':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        customer_id: customerId,
                        first_name: firstNameCc,
                        second_name: lastNameCc,
                        first_name_kana: firstNameKana,
                        second_name_kana: lastNameKana,
                        customer_company_name: customerCompanyName,
                        mail_address: mailAddress,
                        tel_no: telNo
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })

                break

            case 'delete':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        customer_id: customerId,
                        company_id: company_id,
                        first_name: firstNameCc,
                        second_name: lastNameCc,
                        first_name_kana: firstNameKana,
                        second_name_kana: lastNameKana,
                        customer_company_name: customerCompanyName,
                        mail_address: mailAddress,
                        tel_no: telNo
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataDeleteSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
         
                break

            default:
                alert(this.getErrorString({code: 'modal'}))
        }

        this.setState({
            show: false
        })
    }

    onMultiValueFormTextChange = (param, index, target, content) => {
        // 文字数制限処理追加
        if(content.length <= this.props.charaLimit.Customer_tag_tel)
        {
            let ArryTemp = this.state[param]

            if (target === 'tag') {
                ArryTemp[index].tag = content
            } else if (target === 'value') {
                ArryTemp[index].value = content
            }

            this.setState({[param]: ArryTemp})
        }
    }

    onMultiValueFormSizeChange = (param, handle, index) => {
        let ArryTemp = this.state[param]

        if (handle === 'plus') {
            let count = 0;
            ArryTemp.forEach(row => {
                if (!row.del_flag || row.del_flag !== "Y") {
                    count++;
                }
            });
            if (count < 5) {
                ArryTemp.push({id:null, tag:'', value:''});
            }
        } else if (handle === 'minus') {
            let count = 0;
            ArryTemp.forEach(row => {
                if (!row.del_flag || row.del_flag !== "Y") {
                    count++;
                }
            });
            if (count >= 2) {
                ArryTemp[index].del_flag = "Y";
            }
        }

        this.setState({[param]: ArryTemp})
    }

    getAllTelNo = ({customerId, company_id}) => {
        this
            .ascAxios('post', `${this.reactContainerPath}/getAllTelNoByCustomerId`, {
                customer_id: customerId,
                company_id: company_id
            })
            .then(res => {
                let tempArry = res.data.ct01_customer_number

                if (tempArry.length === 0) {
                    tempArry.push({id:null, tag:'', value:''})
                }

                this.setState({telNo: tempArry})
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    
    getCompanySelect = () => {
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({
                    companySelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    validationHandle = param => {
        let nowState   = null,
            validTelNo = /^[0-9]{1,}?$/

        let {
            firstNameCc,
            lastNameCc,
            company_id,
            telNo,
            csvFile,
        } = this.state

        switch (param) {
            case 'company_id':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break

            case 'telNo':
                telNo.forEach(row => {
                    if (!row.del_flag || row.del_flag !== "Y") {
                        if (!validTelNo.test(row.value)) {
                            nowState = 'error'
                        }
                    }
                })
                break

            case "insert":
            case "update":
            case "delete":
                nowState = (
                    company_id
                )

                telNo.forEach(row => {
                    if (!row.del_flag || row.del_flag !== "Y") {
                        if (!validTelNo.test(row.value)) {
                            nowState = false
                        }
                    }
                })
                break

            case "csvInsert":
                nowState = company_id && csvFile.length ? true : false
                break

            default:
        }

        return nowState
    }

    getbtnValue = btn => {
        let btnvalue = btn.target.value;
        this.ascAxios('post',`Download/contentDL`, {param:btnvalue})
            .then(res=>{
                let templateURL = res.data.url;
                window.location.href=templateURL;
            })
            .catch(err =>{
                alert(this.getErrorString(err));

            });
    };

    render() {
        return (
            <div className='Customer'>
                <Row>

                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="customer-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                            <Button
                                id="customer-csvInsert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.modalShow('csvInsert')}>
                                {this.props.langText.Body.CustomerCsvImport}
                            </Button>
                        </Col>
                    }

                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = 'manual'
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <CustomerModal
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    currentPermission = {this.props.currentPermission}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onMultiValueFormTextChange = {this.onMultiValueFormTextChange}
                    onMultiValueFormSizeChange = {this.onMultiValueFormSizeChange}
                    onFileChange = {this.onFileChange}
                    validationHandle = {this.validationHandle}
                    getbtnValue={this.getbtnValue}/>

                <Modal
                    show = {this.state.errShow}
                    bsSize = "large"
                    aria-labelledby = "contained-modal-title-lg">
                    <Form horizontal={true}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.props.langText.Body.CustomerCsvImport}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Panel bsStyle="info">
                                <Panel.Body>
                                    <FormControl
                                        id="errTextarea"
                                        componentClass="textarea"
                                        rows="8"
                                        onChange={e => this.onTextChange(e, "errText")}
                                        value={this.state.errText}/>
                                </Panel.Body>
                            </Panel>
                            <Row>
                                <Col className="panel-button pull-right">
                                    <Button
                                        className = 'cancel-execute'
                                        onClick = {e => this.setState({errShow: false})}
                                        variant = 'outline-secondary'>
                                        {this.props.langText.Body.Close}
                                    </Button>
                                    <Button
                                        bsStyle = "primary"
                                        onClick = {e => {
                                            const myText = document.getElementById("errTextarea");

                                            myText.select();
                                            document.execCommand("copy");
                                        }}>
                                        {this.props.langText.Body.Copy}
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Form>
                </Modal>
            </div>
        )
    }
}