import React from "react";
import { PortModelAlignment } from "@projectstorm/react-diagrams";
import * as _ from "lodash";
import * as FAIcons from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { Col, Button } from "react-bootstrap";

import Component from "../../../components/AscComponent";
import { Application } from "../../../components/Diagrams/Application";
import { DragItem } from "../../../components/Diagrams/Widget/DragItem";
import { Canvas } from "../../../components/Diagrams/Canvas/Canvas";

import { ASNodeModel } from "../../../components/Diagrams/Custom/Ivr/Node/NodeModel";
import { ASNodeFactory } from "../../../components/Diagrams/Custom/Ivr/Node/NodeFactory";
import { ASPortFactory } from "../../../components/Diagrams/Custom/Ivr/Port/PortFactory";
import { ASPortModel } from "../../../components/Diagrams/Custom/Ivr/Port/PortModel";
import { ASLinkFactory } from "../../../components/Diagrams/Custom/Ivr/Link/LinkFactory";
import { ASAction } from "../../../components/Diagrams/Custom/Ivr/Action/Action";

import { Item } from "./Item/Item";
import ASMenu from "./Menu";
import TemplateCreateModal from "./TemplateCreateModal";
import CallType from "./CallType";
import SetBreadCrumb from "../../Elements/AscElements/SetBreadCrumb";

const IvrMenu = styled.div`
    width: ${(p) => p.width || 0};
    height: ${(p) => p.height || 0};
    position: fixed;
    right: 0;
    overflow-y: auto;

    transition: 0.5s;
    background-color: ${(p) => p.background || "#eef3f9"};
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    border-radius: 4px;
    z-index: 1;
`;

const IvrContainer = styled.div`
    height: ${(p) => p.height || 0};
    flex-grow: 1;
    top: 0 !important;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
`;

const IvrContant = styled.div`
    display: flex;
    flex-grow: 1;
`;

const IvrLayer = styled.div`
    position: relative;
    flex-grow: 1;
`;

const ItemBox = styled.div`
    position: relative;
    min-width: 200px;
    background: #0656a3;
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
	border-radius: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 5px;
`;

const NormalButton = styled(Button)`
    margin-right: 5px;
`;

const ROUTER_APP_ID = "routes-app";
const ITEM_BOX_ID = "ivr-item-box";
const MENU_WIDTH_PX = "500px";
const NODE_CLICK_COLOR = "#9bafd1";
const IVR_HEADER_ID = "ivr-header";
const IVR_FOOTER_ID = "ivr-footer";

export default class IvrScript extends Component {
    constructor(props) {
        super(props);

        this.company_id = this.props.company_id;
        this.script_id = this.props.script_id;
        this.script_name = this.props.script_name;
        this.version = this.props.version;
        this.template_id = this.props.template_id;
        this.template_description = this.props.template_description;
        this.template_timeout_seconds = this.props.template_timeout_seconds;

        // Dialog設定
        this.app = new Application();
        this.engine = this.app.getDiagramEngine();
        this.engine.getStateMachine().getCurrentState().dragNewLink.config.allowLooseLinks = false;
        this.engine.getPortFactories().registerFactory(new ASPortFactory("ivr", (config) => new ASPortModel(PortModelAlignment.LEFT)));
        this.engine.getNodeFactories().registerFactory(new ASNodeFactory());
        this.engine.getLinkFactories().registerFactory(new ASLinkFactory());
        this.engine.getActionEventBus().registerAction(new ASAction());

        this.drag_item = [
            {item: CallType.START, icon: FAIcons.faArrowRight, data: {title: this.props.langText.Body.ScriptStart}, menu: false, setting: false, left_port: false, move: false, display: false},
            {item: CallType.PLAY, icon: FAIcons.faPlay, data: {title: this.props.langText.Body.ScriptPlay}},
            {item: CallType.QUESTION, icon: FAIcons.faQuestion, data: {title: this.props.langText.Body.ScriptQuestion}},
            {item: CallType.NUMBER, icon: FAIcons.faHandPointUp, data: {title: this.props.langText.Body.ScriptNumberInput}},
            {item: CallType.TRANSFER, icon: FAIcons.faExchangeAlt, data: {title: this.props.langText.Body.ScriptTransfer}},
            {item: CallType.COUNT, icon: FAIcons.faPlus, data: {title: this.props.langText.Body.ScriptCount}},
            {item: CallType.SMS, icon: FAIcons.faSms, data: {title: this.props.langText.Body.ScriptSMS}},
            {item: CallType.INCOMING, icon: FAIcons.faPhoneVolume, data: {title: this.props.langText.Body.ScriptIncoming}}
        ];

        this.selectOptions = this.getSelectOption(null, this.props.langText.SelectOption);

        this.state = {
            menu_show: false,
            block: true,
            menu: null,
            clicked_count: null,
            modal_show: false,
            modal_type: null,

            // 画面のSIZE調整用データ
            menu_height: 0,
            canvas_height: 0
        };
    }

    async componentDidMount() {
        this.setScreenSize();
        // 初期NODE開始を作成
        this.engine.getModel().addNode(this.newNode(0, this.drag_item.find(di => di.item === CallType.START)));
        this.templateDataSetting();
    }

    componentWillMount() {
        window.addEventListener('resize', this.setScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setScreenSize);
    }

    // 画面ELEMのサイズ調整
    setScreenSize = () => {
        const router_elem = document.getElementById(ROUTER_APP_ID);
        const router_style = router_elem.currentStyle || window.getComputedStyle(router_elem);
        const item_box_elem = document.getElementById(ITEM_BOX_ID);
        const item_box_style = item_box_elem.currentStyle || window.getComputedStyle(item_box_elem);
        const ivr_header_elem = document.getElementById(IVR_HEADER_ID);
        const ivr_header_style = ivr_header_elem.currentStyle || window.getComputedStyle(ivr_header_elem);
        const ivr_footer_elem = document.getElementById(IVR_FOOTER_ID);
        const ivr_footer_style = ivr_footer_elem.currentStyle || window.getComputedStyle(ivr_footer_elem);

        const menu_height = `${(router_elem.offsetHeight - parseInt(router_style.paddingTop) - parseInt(router_style.paddingBottom))}px`;
        const canvas_height = `${(parseInt(menu_height) - item_box_elem.offsetHeight - parseInt(item_box_style.marginBottom) - ivr_header_elem.offsetHeight - parseInt(ivr_header_style.marginBottom) - ivr_footer_elem.offsetHeight)}px`;

        this.setState({
            menu_height,
            canvas_height
        });
    }

    newNode(number, drag_item_json, position = {"x": 10, "y": 10}, c_data = null) {
        const item_data = Item[drag_item_json.item](this.selectOptions, this.props.langText);
        const item_c_data = c_data ? Object.assign(item_data.data, drag_item_json.data, c_data) : Object.assign(item_data.data, drag_item_json.data);
        const node = new ASNodeModel({
            number,
            item: drag_item_json.item,
            icon: drag_item_json.icon,
            menu: drag_item_json.menu,
            setting: drag_item_json.setting,
            left_port: drag_item_json.left_port,
            display: drag_item_json.display,
            validation: item_data.validation,
        }, 
        JSON.parse(JSON.stringify(item_c_data)),
        {
            menuEvent: (model) => {
                this.menuOn(model, false);
            },
            removeEvent: (model) => {
                if (window.confirm(`${this.props.langText.Message.User_Delete_Check}`)) {
                    this.menuOff();
                    model.removeAllLink();
                    this.engine.getModel().removeNode(model);
                }
            },
            linkEvent: (model, link_number) => {
                this.menuOn(model, true, link_number);
            }
        });
        node.setPosition(position);

        if (drag_item_json.move === false) {
            node.setLocked(true);
        }

        return node;
    }

    templateDataSetting() {
        if (this.props.template_json && this.props.template_json.length) {
            let push_nodes = [];
            this.props.template_json.forEach(row => {
                const depth = row.depth;
                const number = row.number;
                const type = row.type;
                const position = row.json_data && row.json_data.screen && row.json_data.screen.position ? row.json_data.screen.position : null;
                const node = this.newNode(number, this.drag_item.find(drag => drag.item === type), position, row.json_data);

                // 一番大きいNumberをセット
                this.engine.number = number > this.engine.number ? number : this.engine.number;

                // 画面に配置するNODEを準備
                push_nodes.push(node);

                if (depth === 1) {
                    const start_node = this.engine.getModel().getNodes().find(model => model.getOptions().item === CallType.START);
                    const source_port = start_node.getPorts().right;
                    const target_port = node.getPorts().left;
                    const new_link = source_port.createLinkModel();

                    start_node.c_data.nexts.push({
                        condition: "all",
                        block_no: target_port.getParent().getOptions().number
                    });

                    new_link.addLabel("all");
                    new_link.setSourcePort(source_port);
                    new_link.setTargetPort(target_port);
                    push_nodes.push(new_link);
                }
            });
            this.engine.getModel().addAll(...push_nodes);
            this.engine.getModel().getNodes().forEach(node => {
                let link_create_arr = [];

                node.c_data.nexts.forEach(next => {
                    let la = link_create_arr.find(link => link.block_no === next.block_no);
                    if (la) {
                        la.condition += `,${next.condition}`;
                    } else {
                        link_create_arr.push(Object.assign({}, next));
                    }
                });

                link_create_arr.forEach(create => {
                    const find_node = node.models.find(model => model.getOptions().number === create.block_no);
                    if (find_node) {
                        const source_port = node.getPorts().right;
                        const target_port = find_node.getPorts().left;
                        const new_link = source_port.createLinkModel();

                        new_link.addLabel(create.condition);
                        new_link.setSourcePort(source_port);
                        new_link.setTargetPort(target_port);
                        source_port.reportPosition();
                        target_port.reportPosition();
                        this.engine.getModel().addLink(new_link);
                    }
                });
            });
        }
        this.setState({block: false});
    }

    // 保存
    save() {
        try {
            this.blockUI();
            let audio_data = this.getAudioData();
            let block_data = this.getStartAndEndBlockData();

            if (block_data.length) {
                this.engine.getModel().getNodes().forEach(node => {
                    if (node.getOptions().item !== CallType.START) {
                        const find_node = block_data.find(block => block.number === node.getOptions().number);
                        if (!find_node) {
                            block_data.push({
                                number: node.getOptions().number,
                                type: node.getOptions().item,
                                depth: 999,
                                json_data: Object.assign(
                                    node.c_data,
                                    {
                                        screen: {
                                            position: node.getPosition()
                                        }
                                    }
                                )
                            });
                        }
                    }
                });
                
                // 音声ファイルを送る場合がある為、FORMを使う。
                let s_form = document.createElement("FORM");
                s_form.enctype = "multipart/form-data";
                let form_data = new FormData(s_form);
                form_data.append("template_data", JSON.stringify({
                    company_id: this.company_id,
                    script_name: this.script_name,
                    script_id: this.script_id,
                    description: this.template_description,
                    timeout_seconds: this.template_timeout_seconds,
                    json_data: block_data,
                    audio_target: audio_data.target,
                }));
                audio_data.files.forEach(file => {
                    form_data.append("files", file);
                });
    
                this
                    .ascAxios("post", `${this.reactContainerPath}/templateUpdate`, form_data)
                    .then(result => {
                        const message = `${this.props.langText.Message.DataInsertSuccess}\r\n${this.props.langText.Body.Version}:${result.data.version}`;
                        alert(message);
                        this.props.onHide();
                    })
                    .catch(err => {
                        console.error(err);
                        alert(this.getErrorString(err.response.data.code));
                    });
            } else {
                this.unblockUI();
                alert(this.props.langText.Message.Start_node_nothing);
            }
            
        } catch (err) {
            this.unblockUI();
            console.log(err);
            alert(this.props.langText.Message.Start_node_every_input);
        }
    }

    getAudioData() {
        let audio_files = [];
        let audio_target = [];

        this.engine.getModel().getNodes().forEach(node => {
            const c_data = node.c_data;

            for (let key in c_data) {
                if (key.endsWith("audio_type") && c_data[key] === "file" && c_data[key.replace("audio_type", "audio_file")].length) {
                    audio_files.push(c_data[key.replace("audio_type", "audio_file")][0]);
                    audio_target.push({
                        number: node.getOptions().number,
                        key: {
                            type: key,
                            id: key.replace("audio_type", "audio_file_id"),
                            file: key.replace("audio_type", "audio_file")
                        }
                    });
                }
            }
        });

        return {
            files: audio_files,
            target: audio_target
        };
    }

    getStartAndEndBlockData(arr = [], node = null, depth = 0) {
        if (!node) {
            const find_start_node = this.engine.getModel().getNodes().find(row => row.getOptions().item === CallType.START);
            const start_links = Object.values(find_start_node.getPorts().right.getLinks() || {});
            if (start_links.length) {
                node = start_links[0].getTargetPort().getParent();
            } else {
                return [];
            }
        }
        if (!node.checkValidation("menu", node.c_data)) throw new Error("data set");

        const links = Object.values(node.getPorts().right.getLinks() || {});
        let m_arr = [...arr];

        // データ追加
        depth += 1;
        m_arr.push({
            number: node.getOptions().number,
            type: node.getOptions().item,
            depth,
            json_data: Object.assign(
                node.c_data,
                {
                    screen: {
                        position: node.getPosition()
                    }
                }
            )
        });

        links.forEach(link => {
            const next_node = link.getTargetPort().getParent();
            if (!m_arr.find(block => block.number === next_node.getOptions().number)) {
                m_arr = this.getStartAndEndBlockData(m_arr, next_node, depth);
            }
        });

        return m_arr;
    }

    loop(func, timer) {
        setTimeout(() => {
            if (!func()) this.loop(func, timer);
        }, timer);
    }

    back() {
        if (window.confirm(this.props.langText.Message.InputDataResetBack)) {
            this.props.onHide();
        }
    }

    // MENUの幅設定
    getMenuWidth(menu_show) {
        return menu_show ? MENU_WIDTH_PX : 0;
    }

    // MENUのデータ作成
    getMenuElem(node, link_flag, link_number) {
        return <ASMenu
            width={MENU_WIDTH_PX}
            company_id={this.company_id}
            item={node.getOptions().item}
            linkFlag={link_flag}
            linkNumber={link_number}
            title={this.sprintf(this.props.langText.Body.ScriptSesscion, node.c_data.title)}
            ascAxios={this.ascAxios}
            getErrorString={this.getErrorString}
            model={node}
            onHide={e => this.menuOff()}
            langText={this.props.langText}
            selectOptions={this.selectOptions}/>;
    }

    // メニューを開く
    menuOn(node, link_flag, link_number) {
        if (this.state.clicked_count !== node.getOptions().number) {
            this.menuOff();
            this.loop(() => {
                if (!this.state.menu_show) {
                    node.widgetChangeColor(NODE_CLICK_COLOR);
                    this.setState({
                        menu_show: true,
                        menu: this.getMenuElem(node, link_flag, link_number),
                        clicked_count: node.getOptions().number
                    });
                    return true;
                }
                
                return false;
            }, 50);
        }
    }

    // メニューを閉じる
    menuOff() {
        let find_node = this.engine.getModel().getNodes();
        
        if (find_node[0]) {
            find_node[0].widgetChangeColor(null, find_node[0].models);
            find_node[0].widgetSettingShow(false, find_node[0].models);
        }

        this.setState({
            menu_show: false,
            menu: null,
            clicked_count: null
        });
    }

    modalSave = data => {
        this.template_description = data.description;
        this.template_timeout_seconds = data.timeout_seconds;
        this.save();
        this.modalHide();
    }

    modalHide = () => {
        this.setState({modal_show: false});
    }

    render() {
        const menu_width = this.getMenuWidth(this.state.menu_show);

        return (
            <>
                <Col xs={12} sm={12}>
                    <IvrContainer
                        height={this.state.menu_height}>
                        <div id="ivr-header">
                            <SetBreadCrumb
                                displayItems={[
                                    {onClick: e => this.back(), name: this.props.langText.SideBar.Script},
                                    {name: this.script_name},
                                    {name: `${this.props.langText.Body.ScriptCreateVersion}[${this.version || this.props.langText.Body.None}]`}
                                ]}/>
                            {/* <p>{this.props.langText.Body.ScriptName}：{this.script_name} / {this.props.langText.Body.ScriptCreateVersion}：{this.version || this.props.langText.Body.None}</p> */}
                        </div>
                        <ItemBox id={ITEM_BOX_ID}>
                            {this.drag_item.map((item, key) => {
                                if (item.display !== false) {
                                    return <DragItem key={key} model={item} />;
                                } else {
                                    return null;
                                }
                            })}
                        </ItemBox>
                        <IvrContant>
                            <IvrLayer
                                onDrop={(event) => {
                                    this.engine.number += 1; // ID発行
                                    const drag_item_data = JSON.parse(event.dataTransfer.getData("item-node")); // DragしたITEMのデータ
                                    const node = this.newNode(this.engine.number, drag_item_data, this.engine.getRelativeMousePoint(event));
                                    this.engine.getModel().addNode(node);
                                    this.forceUpdate();
                                }}
                                onDragOver={(event) => {
                                    event.preventDefault();
                                }}>
                                <Canvas engine={this.engine} height={this.state.canvas_height}/>
                            </IvrLayer>
                        </IvrContant>
                        <div id="ivr-footer" className="text-right" style={{marginTop: "5px"}}>
                            <NormalButton
                                className="default-button"
                                onClick={e => this.engine.zoomToFit()}>
                                {this.props.langText.Body.ScriptZoomToFit}
                            </NormalButton>
                            <NormalButton
                                className="default-button"
                                onClick={e => this.back()}>
                                {this.props.langText.Body.Back}
                            </NormalButton>
                            <Button 
                                className="ok-execute-btn"
                                onClick={e => this.setState({modal_show: true, modal_type: "create"})}
                                bsStyle="primary">
                                {this.props.langText.Body.Save}
                            </Button>
                        </div>
                    </IvrContainer>
                </Col>
                <Col xs={0} sm={0}>
                    <IvrMenu
                        width={menu_width}
                        height={this.state.menu_height}>
                        {this.state.menu}
                    </IvrMenu>
                </Col>
                <TemplateCreateModal
                    show={this.state.modal_show}
                    timeout_seconds={this.template_timeout_seconds}
                    langText={this.props.langText}
                    onClick={this.modalSave}
                    onHide={this.modalHide}/>
                {(this.state.menu_show || this.state.block) && <div className="container-block-ui"></div>}
            </>
        );
    }
}