import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAndroid, faStripeS} from '@fortawesome/free-brands-svg-icons'
import "../../../plugin/fontello/css/fontello.css"
// import {faHeading} from '@fortawesome/free-solid-svg-icons'

class Favi extends Component {
    getOmniIcon() {
        let {
            icon,
            className,
            ...props
        } = this.props;

        return <i className={className + " demo-icon icon-omni-contact margin-right-05"} {...props}></i>
    }

    getFontAwesomeIcon() {
        let {
            icon,
            ...props
        } = this.props;
        let fontData = (icon === "android") ? faAndroid : faStripeS;

        return <FontAwesomeIcon
                icon={fontData}
                {...props}
            />
    }

    render() {
        let {
            icon
        } = this.props;

        return (icon === "softphone") ? this.getOmniIcon() : this.getFontAwesomeIcon();
    }
}

export default Favi