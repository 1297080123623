import React from "react";
import Component from "../../../components/AscComponent";
import {Link} from "react-router-dom";
import {Nav, Navbar, NavItem, Button, Glyphicon} from "react-bootstrap";
import "./Header.css";
// Reactで画像表示する場合css上でのbackgroundimage使用不可(画像が呼べない)
// headerとAppのみ特例でStyle直書きしてます

export default class Header extends Component {
    handleMouseOver = event => {
        this.setState({disabled: false});
    };

    handleMouseOut = event => {
        this.setState({disabled: true});
    };

    handleLogout = async event => {
        if (window.confirm("ログアウトしますか?")) {
            await this.ascAxios("post", "/Sign/logout");
            this
                .props
                .childProps
                .userHasAuthenticated(false);
            this
                .props
                .childProps
                .historyPush("/SignIn");
        }
    };

    render() {
        let navbarHeaderElem = <Navbar.Header>
            <Navbar.Brand>
                {/*<Link to="/" className="hover-black margin-left-05 margin-top-03">{this.props.childProps.langText.Header.MainLink}</Link>*/}
                <a className="hover-black margin-left-05 margin-bottom-10"><img src = {this.props.childProps.systemParameters["HEADERLOGO"]} onClick={e => this.props.childProps.historyPush("/")}></img></a>
            </Navbar.Brand>
            <Navbar.Toggle/>
        </Navbar.Header>;

        let navbarCollapseElem = null;

        if (this.props.childProps.isAuthenticated) {
            navbarCollapseElem = <Navbar.Collapse>
                <Nav pullRight={true} className="header-nav">
                    <NavItem className="Header-User-Icon">
                        <Button onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} onClick={this.handleLogout}>
                            <Glyphicon glyph="user"></Glyphicon>
                        </Button>
                    </NavItem>
                    {this.props.children || null}
                </Nav>
            </Navbar.Collapse>
        }

        return (
            <Navbar fluid={true} collapseOnSelect={true} className="Header">
                {navbarHeaderElem}
                {navbarCollapseElem}
            </Navbar>
        );
    }
}
