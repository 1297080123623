import React from "react";
import {Button, Row, Col, Glyphicon, FormControl, DropdownButton, MenuItem} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import AscAudio from "../../containers/Elements/Audio/Audio";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import Select from "react-select";
import CallHistoryModal from './CallHistoryModal';
import { CSVLink } from "react-csv";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";


export default class CallHistory extends AscComponent {
    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            loading: false,
            filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0}).utc().add(9, 'h')
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ],
            hidden_filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0}).utc().add(9, 'h')
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ],

            externalarr: [],
            // modal item
            show:               false,
            modalType:          null,
            download_link :     null,
            csvdata:[]              ,
            blocking:           false,
            download_interval_id:         null,
            download_end_flag:      false,
            companySelect: [],
        };

        if (this.props.isAuthenticated && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            this.getAllExternal(this.props.userInfo.company_id)
        }

        this.inoutSelect = this.getSelectOption('inout_type', this.props.langText.SelectOption);
        this.callResultSelect = this.getSelectOption('call_result', this.props.langText.SelectOption);
        this.downloadFileType = this.getSelectOption('download_file_type', this.props.langText.SelectOption);
        this.downloadFileTypeNoMp3 = this.getSelectOption('download_file_type_no_mp3', this.props.langText.SelectOption);


        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);


        this.table = React.createRef();
        this.myAudio = document.createElement("AUDIO");

        this.csvLink = React.createRef();

        this.headers = [
            {
                label: this.props.langText.Body.DepartmentName,
                key:'cm13_departments.department_name'
            },
            {
                label: this.props.langText.Body.InOutType,
                key: 'inout_type'
            },{
                label: this.props.langText.Body.ExtensionNumber,
                key: 'extension_number'
            },{
                label: this.props.langText.Body.ExternalNumber,
                key: 'ct62_assgin_external_department_infos.cm61_external_numbers.display_number'
            },{
                label: this.props.langText.Body.ExternalNumberMemo,
                key: 'ct62_assgin_external_department_infos.cm61_external_numbers.memo'
            },{
                label: this.props.langText.Body.DestUserNumber,
                key: 'dest_tel_no'
            },{
                label: this.props.langText.Body.DestUserName,
                key: 'dest_tel_no_user_name'
            },{
                label: this.props.langText.Body.StartDateTime,
                key: 'start_datetime'
            },{
                label: this.props.langText.Body.AnswerDateTime,
                key: 'answer_datetime'
            },{
                label: this.props.langText.Body.EndDateTime,
                key: 'end_datetime'
            },{
                label: this.props.langText.Body.BillSec,
                key: 'billsec'
            },{
                label: this.props.langText.Body.Result,
                key: 'disposition'
            }
        ];

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.getCommonCompanySelect(this.reactContainerPath)
                .then(res => {
                    this.setState({companySelect: res.data})
                }).catch(err => this.showErrorObjectMesssage(err))
        }
    }
    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
    ////画面遷移に入った時
    componentDidMount(){
    };
    //画面遷移の時に polling処理を止める
    componentWillUnmount() {
        clearInterval(this.state.download_interval_id);
    }

    downloadData(path){

        //5秒ごとに実行
        let download_interval = setInterval(()=> {
            this.ascAxios('get', this.baseURL + `/Download/s3CallHistoryDownload/${encodeURIComponent(path)}`)
                .then(res => {
                    let download_flag = true;
                    //サーバーから結果が処理中の場合（lockファイルがあるか、変換中か）
                    if (res.data != null && (res.data.download_status == "lock_exist" || res.data.download_status == "converting")) {
                        download_flag = false;
                        //サーバーから結果がエラーの場合
                    } else if (res.data != null && (res.data.download_status == "err" || res.data.download_status == "not_exist_path"|| res.data.download_status == "lambda_status_err")) {
                        throw new Error();
                    } else {
                        download_flag = true;
                    }
                    //成功場合
                    if (download_flag && res.config.url){
                        clearInterval(download_interval);
                        //ダウンロード中に時間がかかってbackgroundで動いた結果が戻ってきたら1回だけダウンロードさせる、
                        if (!this.state.download_end_flag) {
                            this.setState({download_end_flag: true});
                            this.toggleBlocking();
                            const downLoadLink = document.createElement("a");
                            downLoadLink.href = res.data;
                            downLoadLink.click();
                            downLoadLink.remove();
                        }
                    }
                })
                //失敗の場合
                .catch(err => {
                    clearInterval(download_interval);
                    this.toggleBlocking();
                    alert(this.getErrorString({code:44}));
                })
        }, 5000);
        this.setState({download_interval_id: download_interval});

    };
    // funcion_idでパラメータを持ってくる
    getUserCompanyControlParametersData(param) {
        let data = null
        if (this.props.userCompanyControlParameters.length > 0) {
            data = this.props.userCompanyControlParameters.find(function(item) {return item.function_id === param})
        }
        return data;
    }
    getColumnsData() {
        let columns = [];
        //ID
        columns.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            sortable: false,
            width: this.props.boardWidth.id,
        });
        //会社名
        columns.push({
            Header: this.props.langText.Body.CompanyName,
            sortable: false,
            accessor: "cm12_companies.company_name",
            width: this.props.boardWidth.CallHistoryLarge,
        });
        //発着区分
        columns.push({
            Header: this.props.langText.Body.InOutType,
            sortable: false,
            accessor: "inout_type",
            width: this.props.boardWidth.xxsmall,
        });
        //内線番号
        columns.push({
            Header: this.props.langText.Body.ExtensionNumber,
            sortable: false,
            accessor: "extension_number",
            width: this.props.boardWidth.CallNumber,
        });
        //外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: false,
            accessor: "ct62_assgin_external_department_infos.cm61_external_numbers.display_number",
            // accessor: "cm61_external_numbers.display_number",
            width: this.props.boardWidth.CallHistoryLarge,
            Cell: data => {
                let returndata = "";
                if(data.value && data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo != "")
                {
                    returndata = data.value + "(" + data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")";
                }
                else if(data.value)
                {
                    returndata = data.value;
                }
                return returndata;
            }
        });
        //相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: false,
            accessor: "dest_tel_no",
            width: this.props.boardWidth.CallHistoryLarge,
        });
        //開始時間
        columns.push({
            Header: this.props.langText.Body.StartTime,
            sortable: false,
            accessor: "start_datetime",
            width: this.props.boardWidth.large,
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        //通話時間
        columns.push( {
            Header: this.props.langText.Body.TalkingTime,
            sortable: false,
            accessor: "billsec",
            width: this.props.boardWidth.xsmall,
            Cell: data => {
                return this.secToTime(data.value || 0);
            }
        });


        let call_record_operation_flag = true;
        let search_company_parameter_call_record = this.getUserCompanyControlParametersData("CALL_RECORD");

        let result_width = this.props.boardWidth.small;

        // 会社範囲以下は会社パラメタテーブルから操作フラグを決める
        if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
            && (search_company_parameter_call_record !== null &&
                typeof search_company_parameter_call_record !== "undefined")) {
            //コール履歴「操作」表示フラグ
            if (search_company_parameter_call_record.parameter_value === "N") {
                call_record_operation_flag = false;
            }
            result_width = this.props.boardWidth.medium;
        }

        //結果
        columns.push({
            Header: this.props.langText.Body.Result,
            sortable: false,
            accessor: "disposition",
            width: result_width,
        });

        //表示
        //システム管理者かパラメータがない場合
        //パラメータがあり、Yの場合
        if (call_record_operation_flag){
            //操作
            columns.push({
                Header: this.props.langText.Body.Control,
                accessor: "voice_log_url",
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                    if (data.value && data.row.disposition === "ANSWERED") {
                        if(this.props.currentPermission.playback) {
                            rowData.push(
                                <AscAudio
                                    key = {0}
                                    type = "url"
                                    data = {data.value}
                                    className = "control-button"/>
                            );
                        }
                        if(this.props.currentPermission.export) {
                            rowData.push(
                                <Button
                                    key={2}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("download",data)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="download-alt"/>
                                </Button>
                            );
                        }
                    }
                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            });
        }


        return columns;
    }

    getAllExternal(cm12_id) {
        this
            .ascAxios('post', `${this.reactContainerPath}/getallExternal`, {cm12_id: cm12_id})
            .then(result => {
                this.setState({externalarr: result.data});
                return result.data;
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    getFiterData(param) {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";

        // セレクトボックス用。event無い場合はvalue空のまま
        if(event)
        {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }
        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            this.getAllExternal(event.value)
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                externalarr: [],
            })
        }

        let returnValue = [];
        // 日付クリア時に時間情報リセット
        if (param === "start_datetime" && event === null) {
            returnValue = my_filtered.filter(row => {
                return row.id !== "start_ji" && row.id !== "start_hun" && row.id !== "end_ji" && row.id !== "end_hun";
            });
            returnValue.push({id: "start_ji", value: { value: '00', label: '00' }});
            returnValue.push({id: "start_hun", value: { value: '00', label: '00' }});
            returnValue.push({id: "end_ji", value: { value: '23', label: '23' }});
            returnValue.push({id: "end_hun", value: { value: '59', label: '59' }});
        } else {
            returnValue = my_filtered
        }

        this.setState({
            filtered: returnValue
        });
    }

    onSearchDataChange = (event) => {
        // 既存データ初期化
        this.setState({
            filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0}).utc().add(9, 'h'),
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
            ],
            hidden_filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0}).utc().add(9, 'h'),
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
            ]
        })

        switch (event) {
            case "incoming_no_answer":
                // in_out
                this.onChange(this.inoutSelect.find(row => row.value === "in"), "inout_type");

                // call_result
                let call_result = [];
                this.callResultSelect.forEach(row => {
                    if (row.value === "NO ANSWER" || row.value === "BUSY" || row.value === "PLAYBACK" || row.value === "FAILED") {
                        call_result.push(row);
                    }
                });
                this.onChange(call_result, "disposition");
                break;
            case "anonymous":
                this.onChange("anonymous","dest_tel_no");
                break;

            default:
        }
    }

    onSearch = (event) => {
        let filter =[];

        if (!this.state.filtered.some(row => row.id === "start_datetime" || row.id === "dest_tel_no")) {
            // 検索条件不足
            return alert(this.getErrorString({code: "CallHistory_FindDataError"}));
        }

        this.state.filtered.forEach((row, index) => {
            filter.push(row);
        })

        this.setState({
            hidden_filtered: filter
        },()=>{
            //検索時にpageを1ページする
            this.table.current.state.page = 0;
            this.table.current.state.hidden_filtered = filter;
            this.fetchData(this.table.current.state, this.table.current.instance);
        });
    }

    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    callHistoryCsvDL = () => {
        this.toggleBlocking();
        this.ascAxios('post',  `${this.reactContainerPath}/callHistoryCsvDL`,
            {
                sorted: [{id: "start_datetime", desc: true}],
                filtered: this.state.filtered,
            })
            .then(res=>{
                if (res.data.count.length === 0) {
                    alert(this.getErrorString({code: "Download_Failed"}));
                } else {
                    this.setState({csvdata: res.data.rows});
                    this.csvLink.current.link.click();
                }})
            .catch(err=>{
                alert(this.getErrorString(err));})
            .finally(()=>{
                this.toggleBlocking()});
    }

    onRadioChange (event, param) {
        super.onRadioChange(event, param);
        // let value = (event && event.target && event.target.value) ? event.target.value : "";

    }
    modalShow = (modalType, param) => event => {
        event.preventDefault()
        let setData = {
            param: param && param
                ? param
                :"",
            download_link: param && param.value
                ? param.value
                :"",
            downloadFileType: this.downloadFileType,
            downloadFileTypeNoMp3: this.downloadFileTypeNoMp3,
        }
        if (param) {
            setData.file_type = param.file_type
                ? param.file_type
                : this.downloadFileType[0].value

        }
        this.setState(setData);
        // this.modalshow_commonInit();
        this.setState({show: true, modalType})

    }

    onClickHandle = modalType => event => {
        let {
            download_link,
            file_type,
        } = this.state

        switch (modalType) {
            case 'download':
                //ダウンロードする時に画面内で他の動作ができないようにする
                this.toggleBlocking();
                this.setState({download_end_flag: false});
                try{

                    let download_link_file_type = download_link.slice(0,-3).concat(file_type);
                    this.downloadData(download_link_file_type);
                    this.setState({
                        show: false
                    })
                } catch (e) {
                    alert(this.getErrorString(e.response.data));
                }
                break
            default:
                alert(this.getErrorString({code: 'modal'}))
        }

        // this.setState({show: false})
    }
    validationHandle = param => {
        let nowState = null
        let {
            file_type,
        } = this.state

        switch(param) {
            case "download":
                nowState = (
                    file_type )
                break
            default:
        }
        return nowState
    }

    getCsvFileName() {
        let date = this.getFiterData("start_datetime");
        let file_name = "";
        if (!!date) {
            file_name = moment(this.getFiterData("start_datetime")).format("YYYYMMDD") + "_";
        }
        let stringArr = [];
        stringArr.push(this.state.filtered.find(row => {return row.id === "dest_tel_no";}));
        stringArr.forEach(row => {
            if (row) {
                let value = row.value.value ? row.value.value : row.value;
                file_name = file_name + value + "_";
            }
        })

        let nowTime = moment().utc().add(9, 'h').format("YYYYMMDDhhmmss");

        return file_name + this.props.langText.Body.CallHistoryCsvName + "_" + nowTime + ".csv";
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <div className="CallHistory">
                    <Row>
                        {/* OEM以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={12} md={12}>
                                <Col sm={4} md={2} lg={1}>
                                    <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Select
                                        value = {this.getFiterData("cm12_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm12_id")
                                        }
                                        options={this.state.companySelect}
                                        isClearable = {true}
                                        placeholder = {!this.state.companySelect[0] ?
                                            <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                            :
                            null
                        }
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardStart}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <DatePicker
                                    onChange={
                                        e => this.onChange(e, "start_datetime")
                                    }
                                    selected={this.getFiterData("start_datetime")}
                                    dateFormat = "YYYY-MM-DD"
                                    className = "form-control"
                                    todayButton={this.props.langText.Body.Today}
                                    isClearable = {true}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.StartTime}</div>
                            </Col>

                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    // key = "display_number"
                                    value = {this.getFiterData("start_ji")}
                                    onChange = {
                                        e => this.onChange(e, "start_ji")
                                    }
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    options={this.hourShowList}
                                    // isClearable = {true}
                                    placeholder = "---"
                                />
                            </Col>

                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    // key = "display_number"
                                    value = {this.getFiterData("start_hun")}
                                    onChange = {
                                        e => this.onChange(e, "start_hun")
                                    }
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    options={this.minuteShowList}
                                    // isClearable = {true}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.EndTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    // key = "display_number"
                                    value = {this.getFiterData("end_ji")}
                                    onChange = {
                                        e => this.onChange(e, "end_ji")
                                    }
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    options={this.hourShowList}
                                    // isClearable = {true}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    // key = "display_number"
                                    value = {this.getFiterData("end_hun")}
                                    onChange = {
                                        e => this.onChange(e, "end_hun")
                                    }
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    options={this.minuteShowList}
                                    // isClearable = {true}
                                    placeholder = "---"
                                />
                            </Col>



                            <Col sm={12} md={4} lg={2}>
                                <label className="margin-top-05">　検索結果：</label>
                                <label className="margin-top-05 result-count">{this.state.count ? this.state.count: 0}件</label>
                            </Col>

                        </Col>

                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardPartner}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <FormControl
                                    onChange = {
                                        e => this.onChange(e, "dest_tel_no")
                                    }
                                    value = {this.getFiterData("dest_tel_no")}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.InoutResult}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <Select
                                    value = {this.getFiterData("inout_type")}
                                    onChange = {
                                        e => this.onChange(e, "inout_type")
                                    }
                                    options = {this.inoutSelect}
                                    isClearable = {true}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_allResult}
                                />
                            </Col>

                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CallHistory}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <Select
                                    value = {this.getFiterData("disposition")}
                                    onChange = {
                                        e => this.onChange(e, "disposition")
                                    }
                                    options = {this.callResultSelect}
                                    isMulti = {true}
                                    closeMenuOnSelect = {false}
                                    isClearable = {true}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_allResult}
                                />
                            </Col>

                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExternal}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <FormControl
                                    onChange={e => this.onChange(e, "extension_number")}
                                    value = {this.getFiterData("extension_number")}
                                />
                            </Col>

                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExtension}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <Select
                                    key = "display_number"
                                    value = {this.getFiterData("display_number")}
                                    onChange = {
                                        e => this.onChange(e, "display_number")
                                    }
                                    options = {this.state.externalarr}
                                    isClearable = {true}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_defaultexternal}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.Template}</div>
                            </Col>
                            <Col sm={12} md={3} className="margin-top-03">
                                <DropdownButton
                                    title={this.props.langText.Body.Template}
                                    className="margin-right-05"
                                    onSelect={this.onSearchDataChange}>
                                    <MenuItem eventKey="incoming_no_answer">{this.props.langText.SelectOption.IncomingNoAnswer}</MenuItem>
                                    <MenuItem eventKey="anonymous">{this.props.langText.SelectOption.Anonymous}</MenuItem>
                                    <MenuItem eventKey="clear">{this.props.langText.SelectOption.Clear}</MenuItem>
                                </DropdownButton>
                                <Button
                                    id="customer-insert"
                                    className="searchBtn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={e => this.onSearch(e)}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Button
                                id="callhistory-download"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.callHistoryCsvDL}
                            >
                                {this.props.langText.Body.CallHistoryDownload}
                            </Button>
                            <CSVLink
                                data={this.state.csvdata}
                                headers={this.headers}
                                filename={this.getCsvFileName()}
                                className="hidden"
                                ref={this.csvLink}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                talbeRef={this.table}

                                style={{height: this.props.tableHeight-90}}
                                manual="manual"
                                columns={this.state.columns}
                                data={this.state.data}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                defaultSorted={[{id: "start_datetime", desc: true}]}
                                onFetchData={(state, instance) => {
                                    this.fetchData(state, instance);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    <CallHistoryModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onIntChange = {this.onIntChange}
                        onKeyDownIntCheck = {this.onKeyDownIntCheck}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        onRadioChange = {this.onRadioChange}
                        validationHandle   = {this.validationHandle}
                        getinboundGroupName = {this.getinboundGroupName}
                    />
                </div>
            </BlockUi>
        );
    }
}