import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import "../Elements/FromGroup/form_select.css";

export default class ScriptDeleteModal extends Component {
    setUsingList(){
        const usingCalendarItems = this.props.state.usingCalendarItems.map(usingCalendarItem => {
            return(
                <p><li>{usingCalendarItem}</li></p>
            );
        });
        return(
            <div className="text-danger modal-checked-message"><p>{this.props.langText.Message.ScriptUsedMsg}</p>
                <ul>{usingCalendarItems}</ul>
            </div>
        );
    }
    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            <p>{this.props.langText.Body.ScriptInfo}</p>
                            {this.props.state.usingCalendarItems && this.props.state.usingCalendarItems.length > 0 ? this.setUsingList() : false}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.props.getModalScriptItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
