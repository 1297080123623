import React, {Component} from "react";
import {FormGroup, FormControl, ControlLabel, Col} from "react-bootstrap";
import Select from "react-select"

export default class InlineForm extends Component {
    // static
    static static({
                      controlId,
                      labelCol = 12,
                      formCol = 12,
                      label = "",
                      required,
                      children,
                      ...props
                  }) {
        return (
            <FormGroup controlId={controlId}>
                {label !== "" && labelCol !== 0 &&
                <Col componentClass={ControlLabel} sm={labelCol}>
                    {label}
                    {required && "（*）"}
                </Col>
                }
                <Col sm={formCol = 12}>
                    <FormControl.Static {...props}>
                        {children}
                    </FormControl.Static>
                </Col>
            </FormGroup>
        );
    }

    getType({
                children,
                type = ""
            }) {
        let resultData = type;

        if (children) {
            resultData = "children";
        }

        return resultData;
    }

    selectDataSetting({value, options}) {
        let getValue = value;

        if (Array.isArray(getValue)) {
            let tmp = getValue;
            getValue = [];
            tmp.forEach(tmpValue => {
                let findData = options.find(option => option && option.value.toString() === tmpValue.toString());

                if (findData) {
                    getValue.push(findData);
                }
            });
        } else {
            getValue = value !== "" ? options.find(option => option && option.value === getValue) : value;
        }

        return getValue;
    }

    render() {
        let {
            children,
            controlId,
            label = "",
            labelCol = 12,
            formCol = 12,
            required = false,
            value = null,
            validationState,
            ...props
        } = this.props;

        /*validationStateはsuccess,warning,error,nullしか対応しないため、
        validationStateがbooleanのときnullかerrorに変換*/
        if(validationState===true){
            validationState=null
        }else if(validationState===false){
            validationState='error'
        }

        let type = this.getType({children, type: props.type});

        return (
            <FormGroup controlId={controlId} validationState={validationState}>
                {label !== "" && labelCol !== 0 &&
                <Col componentClass={ControlLabel} sm={labelCol}>
                    {label}
                    {required && "*"}
                </Col>
                }
                {/* ラベル表示無し、Col3だけほしい場合 */}
                {label === "" && this.props.nulllabel === "on" &&
                    <Col sm={labelCol}>
                    </Col>
                }
                <Col sm={formCol}>
                    {type === "children" &&
                    <div>
                    {children}
                    </div>
                    }
                    {type === "select" &&
                    <Select
                        id = {controlId}
                        {...props}
                        value={this.selectDataSetting({value, options: this.props.options})}
                    />
                    }
                    {type !== "children" && type !== "select" &&
                    <FormControl
                        value={value}
                        {...props}
                    />
                    }
                </Col>
            </FormGroup>
        );
    }
}