import * as React from 'react';
import styled from '@emotion/styled';
import { CanvasWidget } from '@projectstorm/react-canvas-core';

const Container = styled.div`
	height: ${(p) => p.height || 0};
    background-color: ${(p) => p.background};
	box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
	border-radius: 4px;
    display: flex;

    > * {
		height: 100%;
		min-height: 100%;
		width: 100%;
    }
`;

const StyledCanvas = styled(CanvasWidget)`
	flex: 1 1 auto;

	/* This fixes a problem combining react-diagrams with antd */
	& svg:not(:root) {
    	overflow: initial;
	}
`;

export class Canvas extends React.Component {
	render() {
		return (
			<Container
				id={this.props.id}
				height={this.props.height}
				background={this.props.background || '#f1f6f8'}
				color={this.props.color || 'rgba(255,255,255, 0.05)'}>
				<StyledCanvas engine={this.props.engine}/>
			</Container>
		);
	}
}