import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import UserModal from './UserModal'
import * as GlobalConst from '../../components/AscConstants';


export default class User extends AscComponent {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],

            // data item
            userId:         '',
            company_id:     '',
            companyName:    '',
            department_id:  '',
            department_name: '',
            department_prefix: '',
            floor_id:       '',
            floor_name:     '',
            mailAddress:    '',
            firstNameCc:    '',
            lastNameCc:     '',
            firstNameKana:  '',
            lastNameKana:   '',
            password:       '',
            passwordRe:     '',
            oldPassword:    '',
            newPassword:    '',
            newPasswordRe:  '',
            downloadflag:   '',
            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load": [],
                "floor_load" : []
            },
            permissionSelect: [],
        }

        this.floor_load_flag = false;
    }

    getColumnsData() {
        let itemArr = [];

        // oem以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }

        // company以上は拠点カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'cm13_departments.department_name',
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
            });
        }

        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'cm13_departments.floor_name',
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} />
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.EmailAddress,
            accessor: 'mail_address',
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "mail_address")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.UserName,
            accessor: 'user_name_sei',
            filterable: false,
            width: this.props.boardWidth.medium,
            Cell: data => {
                return `${data.row._original.user_name_sei} ${data.row._original.user_name_mei}`
            },
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "user_name_sei")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.Auth,
            accessor: 'cm73_permissions.name',
            width: this.props.boardWidth.medium,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm73_permissions.name")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.LastLoginDate,
            filterable: false,
            width: this.props.boardWidth.Date,
            Cell: data => {
                let returnData = (
                    data.row._original.ct90_login_histories
                    && data.row._original.ct90_login_histories.created)
                    ? this.getMomentTime({
                        date:   data.row._original.ct90_login_histories.created,
                        format: this.props.langText.Body.DateFormat
                        })
                    : ''

                return returnData
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.LargeControl,
            Cell: data => {
                // 権限に応じて各アイコン表示
                let rowData = [];
                // 編集権限あれば編集ボタン、編集権限無ければ閲覧ボタン
                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("update", data.row._original)}
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("read", data.row._original)}
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }
                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("copy", data.row._original)}
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }
                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("delete", data.row._original)}
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    );
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                );
            }
        });

        return itemArr;
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load": [],
                "floor_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault()

        this.floor_load_flag = false;
        // 条件配列の初期化
        this.modalshow_commonInit();

        let setData = {
            userId: param && param.id
                ? param.id
                : '',
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : '',
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                ? param.cm12_companies.floor_flg === "Y"
                : null,
            department_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            department_name: param && param.cm13_departments && param.cm13_departments.department_name
                ? param.cm13_departments.department_name
                : '',
            department_prefix: param && param.cm13_departments && param.cm13_departments.department_prefix
                ? param.cm13_departments.department_prefix
                : '',
            floor_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            floor_name: param && param.cm13_departments && param.cm13_departments.floor_name
                ? param.cm13_departments.floor_name
                : '',
            mailAddress: param && param.mail_address
                ? param.mail_address
                : '',
            firstNameCc: param && param.user_name_sei
                ? param.user_name_sei
                : '',
            lastNameCc: param && param.user_name_mei
                ? param.user_name_mei
                : '',
            firstNameKana: param && param.user_name_kana_sei
                ? param.user_name_kana_sei
                : '',
            lastNameKana: param && param.user_name_kana_mei
                ? param.user_name_kana_mei
                : '',
            password: '',
            passwordRe: '',
            oldPassword: '',
            newPassword: '',
            newPasswordRe: '',
            permission_id: param && param.cm73_permissions.id
                ? param.cm73_permissions.id
                : '',
            permission_name: param && param.cm73_permissions.name
                ? param.cm73_permissions.name
                : '',
            permissionSelectLoad: true,
        }

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.company_id = this.props.userInfo.company_id
            setData.companyName = this.props.userInfo.company_name
            setData.floor_flg = this.props.userInfo.floor_flg
        }
        // 新規作成時にdepartment以下なら拠点固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
            if (this.props.userInfo.floor_flg) {
                setData.department_id = this.props.userInfo.parent_department_id
                setData.department_name = this.props.userInfo.parent_department_name
                setData.department_prefix = this.props.userInfo.department_prefix
            } else {
                setData.department_id = this.props.userInfo.department_id
                setData.department_name = this.props.userInfo.department_name
            }
        }
        // 新規作成時にfloor以下ならフロア固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
            setData.floor_id = this.props.userInfo.department_id
            setData.floor_name = this.props.userInfo.floor_name
        }

        this.setState(setData)

        this.setPermissionSelect(setData.company_id, setData.floor_flg)

        this.getCompanySelect()
        this.getDepartmentSelect(setData, "update")
        this.getFloorSelect(setData)

        this.setState({show: true, modalType})
    }

    onClickHandle = modalType => event => {
        let {
            userId,
            company_id,
            department_id,
            floor_id,
            mailAddress,
            firstNameCc,
            lastNameCc,
            firstNameKana,
            lastNameKana,
            password,
            oldPassword,
            newPassword,
            downloadflag,
            permission_id,
        } = this.state

        switch (modalType) {
            case 'copy':
            case 'insert':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        mailAddress,
                        firstNameCc,
                        lastNameCc,
                        firstNameKana,
                        lastNameKana,
                        password,
                        company_id,
                        permission_id,
                        department_id: floor_id || department_id,
                        downloadflag,
                    })
                    .then(result => {
                        alert(
                            `${result.data.mailAddress} ${this.props.langText.Message.User_DataInsertSuccess}`
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(
                            this.getErrorString(err.response.data)
                        )
                    })
                break

            case 'update':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        userId,
                        mailAddress,
                        firstNameCc,
                        lastNameCc,
                        firstNameKana,
                        lastNameKana,
                        oldPassword,
                        newPassword,
                        company_id,
                        department_id: floor_id || department_id,
                        downloadflag,
                        permission_id,
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(
                            this.getErrorString(err.response.data)
                        )
                    })
                break

            case 'delete':
                if (window.confirm(`${this.props.langText.Message.User_Delete_Check}`)) {
                    this.blockUI();
                    this
                        .ascAxios('post', `${this.reactContainerPath}/delete`, {
                            userId,
                            mailAddress,
                            company_id,
                            permission_id,
                            department_id: floor_id || department_id,
                        })
                        .then(result => {
                            alert(
                                this.props.langText.Message.DataDeleteSuccess
                            )
                            this.reactTableRefresh()
                        })
                        .catch(err => {
                            alert(
                                this.getErrorString(err.response.data)
                            )
                        })
                }
                break

            default:
                alert(
                    this.getErrorString({code: 'modal'})
                )
        }

        this.setState({
            show: false
        })
    }

    onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let department_prefix = (event && event.department_prefix) ? event.department_prefix : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";
        let permission_json_data = (event && event.json_data) ? event.json_data : "";

        if (param === "company_id") {
            this.setState({
                department_id: '',
                floor_id: '',
                permission_id: '',
                permission_json_data: "",
                floor_flg: floor_flg
            });
            this.getDepartmentSelect({company_id: value, floor_flg: floor_flg});
            this.getFloorSelect({
                company_id: this.state.company_id,
                department_id: value,
                floor_flg: this.state.floor_flg,
                department_prefix: department_prefix
            });
            this.setPermissionSelect(value, floor_flg);
        } else if (param === "department_id") {
            this.floor_load_flag = true;
            this.setState({floor_id: ''});
            this.getFloorSelect({
                company_id: this.state.company_id,
                department_id: value,
                department_prefix: department_prefix,
                floor_flg: this.state.floor_flg,
            });
        } else if (param === "permission_id") {
            this.setState({
                permission_id: value,
                permission_json_data: permission_json_data
            });
        }
    }

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({
                    companySelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    getDepartmentSelect = ({company_id, floor_flg, department_id, department_prefix}, type) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this
            .ascAxios('post', `Common/departmentSelect`, {container: this.reactContainerPath, company_id, floor_flg})
            .then(res => {
                if (type === "update" && floor_flg && department_id && department_prefix) {
                    let my_prefix = department_prefix.slice(0, 2);
                    res.data.forEach(row => {
                        if (row.department_prefix && my_prefix === row.department_prefix) {
                            this.setState({department_id: row.value});
                        }
                    });
                }

                if(company_id)
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                }

                this.setState({
                    departmentSelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    /**
     * 権限一覧取得
     * @param company_id
     * @param floor_flg
     */
    async setPermissionSelect(company_id = null, floor_flg) {

        try {
            let result = await this.ascAxios('post', `Common/getPermissions`, {
                container: this.reactContainerPath,
                company_id: company_id,
                floor_flg: floor_flg
            })
            this.setState({
                permissionSelect: result.data,
                permissionSelectLoad: false
            })
        } catch (err) {
            alert(this.getErrorString(err.response.data))
            return null;
        }
    }

    getFloorSelect = ({company_id, department_id, department_prefix, floor_flg}) => {
        if(this.floor_load_flag === true && department_id && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }
        if (floor_flg) {
            this.ascAxios('post', `Common/floorSelect`, {container: this.reactContainerPath, company_id, department_prefix})
            .then(res => {
                if(this.state.department_id)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                this.setState({
                    floorSelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    onRadioChangebool = (event,param,booltype) => {
        if(booltype === true)
        {
            this.setState({[param]: 'Y'});
        }
        else
        {
            this.setState({[param]: 'N'});
        }
    }

    validationHandle = param => {
        let validation_flag = true;

        let {
            mailAddress,
            firstNameCc,
            lastNameCc,
            password,
            passwordRe,
            oldPassword,
            newPassword,
            newPasswordRe,
            company_id,
            department_id,
            floor_id,
            floor_flg,
            permission_id,
            permission_json_data
        } = this.state

        let emailCheck = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/
        let passwordCheck = /(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

        let adminFlag = false;

        switch (param) {
            case 'mailAddress':
                validation_flag = emailCheck.test(this.state[param]);
                break;
            case 'firstNameCc':
            case 'lastNameCc':
                validation_flag = !!this.state[param];
                break;
            case 'password':
            case 'passwordRe':
                validation_flag = passwordCheck.test(this.state[param]);
                break;

            case 'oldPassword':
            case 'newPassword':
            case 'newPasswordRe':
                if (oldPassword || newPassword || newPasswordRe) {
                    validation_flag = passwordCheck.test(this.state[param]);
                }
                break;

            case 'company_id':
                validation_flag = !!this.state[param]
                break;

            case 'department_id':
            case 'floor_id':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = !!this.state[param] || adminFlag;
                break;
            case 'permission_id':
                validation_flag = !!this.state[param]
                break;

            case 'copy':
            case 'insert':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = (
                    emailCheck.test(mailAddress)
                    && firstNameCc
                    && lastNameCc
                    && passwordCheck.test(password)
                    && password == passwordRe
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (company_id !== null && company_id !== ''))
                    && (adminFlag || (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (department_id !== null && department_id !== '')))
                    && (adminFlag || !floor_flg
                        || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                            || (floor_id !== null && floor_id !== ''))))
                    && permission_id
                )
                break;

            case 'update':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = (
                    firstNameCc
                    && lastNameCc
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (company_id    !== null && company_id    !== ''))
                    && (adminFlag || (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (department_id !== null && department_id !== '')))
                    && (adminFlag || !floor_flg
                        || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                            || (floor_id !== null && floor_id !== ''))))
                    && permission_id
                )
                if (oldPassword || newPassword || newPasswordRe) {
                    validation_flag = (
                        passwordCheck.test(oldPassword)
                        && passwordCheck.test(newPassword)
                        && newPassword == newPasswordRe
                    )
                }
                break;

            case 'delete':
                validation_flag = (
                    firstNameCc
                    && lastNameCc
                    && (company_id !== null && company_id !== '')
                    && (department_id !== null && department_id !== '')
                    && !floor_flg || (floor_flg && (floor_id !== null && floor_id !== ''))
                    && permission_id
                )
                break;

            default:
                validation_flag = true;
                break;
        }
        return validation_flag;
    }

    render() {
        return (
            <div className='User'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id = "user-insert"
                                className = 'table-button'
                                bsStyle = 'default'
                                bsSize = 'sm'
                                onClick = {this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <UserModal
                    getErrorString = {this.getErrorString}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    currentPermission = {this.props.currentPermission}
                    floor_flg = {this.state.floor_flg}
                    loginUserId = {this.props.userInfo.user_id}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    onRadioChangebool = {this.onRadioChangebool}
                    validationHandle = {this.validationHandle}/>
            </div>
        )
    }
}