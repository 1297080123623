import React, { Component } from "react";
import { Row, Col, FormControl, ControlLabel, Panel, Radio, Button } from "react-bootstrap";
import styled from "@emotion/styled";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

const MenuRow = styled(Row)`
    width: 100%;
    margin: 0;
    label {
        margin-bottom: 0;
    }
`;
const TitleCol = styled(Col)`
    padding: 10px;
`;
const ContentCol = styled(Col)`
    padding: 10px;
    margin-bottom: 25px;
`;
const BottomCol = styled(Col)`
    width: ${(p) => p.width || 0};
    background: #eef3f9;
    position: fixed;
    bottom: 19px;
    right: 15px;
    padding: 10px;
    border-bottom-left-radius: 4px;
    transition: 0.5s;
`;
export default class ASMenu extends Component {
    constructor(props) {
        super(props);

        this.model = this.props.model;
        this.number = this.model.getOptions().number;
        this.validation = this.model.getOptions().validation;

        this.ascAxios = this.props.ascAxios;
        this.getErrorString = this.props.getErrorString;
        this.selectOptions = this.props.selectOptions;

        this.placeholder = this.props.langText.SelectOption.Placeholder;

        this.state = {
            data: this.mData(),
            screen_data: {
                audio_custom_column: [
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value
                ]
            },
            audio_select: null,
            footter_width: 0
        }
    }

    componentDidMount() {
        this.setState({footter_width: `${parseInt(this.props.width) - 15}px`});
    }

    mData() {
        let data = JSON.parse(JSON.stringify(this.model.c_data));
        for (let key in this.model.c_data) {
            if (key.endsWith("audio_file")) data[key] = this.model.c_data[key];
        }
        return data;
    }

    getAudioSelect() {
        this
            .ascAxios(
                "post",
                "Common/voiceFileSelectAllType",
                {company_id: this.props.company_id}
            )
            .then(result => {
                this.setState({audio_select: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    // TITLE「ELEM」取得
    getTitleElem() {
        return (
            <ASFieldGroup
                type="text"
                label={this.props.langText.Body.Title}
                value={this.state.data.title}
                onChange={e => this.setState({data: Object.assign(this.state.data, {title: e.target.value})})}
                validationState={this.validation.menu.title(this.state.data)}/>
        );
    }

    // AUDIO「ELEM」取得
    getAudioElem({
        key,
        validation_type="menu",
        audio_type_select=this.selectOptions.audio_type,
        audio_label, //音声LABEL
        synthetic_label, //音声合成LABEL
        audio_type,
        audio_file,
        audio_file_id,
        audio_gen_language,
        audio_gen_person,
        audio_gen_text,
    }) {
        if (!this.state.audio_select) {
            this.getAudioSelect();
        }

        return (
            <>
                <ASFieldGroup
                    label={audio_label}>
                    {audio_type_select.map((row, index) => 
                        <Radio
                            key={index}
                            name={`AudioType-${key}`}
                            value={row.value}
                            checked={this.state.data[audio_type] === row.value}
                            onChange={e => {
                                this.state.data[audio_type] = row.value;
                                this.setState({data: this.state.data});
                            }}
                            style={{marginTop: 0}}>
                            {row.label}
                        </Radio>
                    )}
                </ASFieldGroup>
                {this.state.data[audio_type] === "file" &&
                    <ASFieldGroup
                        type="file"
                        accept="audio/*"
                        onChange={e => {
                            this.state.data[audio_file] = e.target.files;
                            this.setState({data: this.state.data});
                        }}
                        validationState={this.validation[validation_type][audio_file](this.state.data)}/>
                }
                {this.state.data[audio_type] === "select" &&
                    <ASFieldGroup validationState={this.validation[validation_type][audio_file_id](this.state.data)}>
                        <ASSelect
                            value={this.state.data[audio_file_id]}
                            options={this.state.audio_select}
                            onChange={e => {
                                this.state.data[audio_file_id] = e.value;
                                this.setState({data: this.state.data});
                            }}
                            placeholder={this.placeholder}/>
                    </ASFieldGroup>
                }
                {this.state.data[audio_type] === "synthetic" &&
                    this.getSyntheticElem({
                        key,
                        validation_type,
                        synthetic_label,
                        audio_gen_language,
                        audio_gen_person,
                        audio_gen_text
                    })
                }
            </>
        );
    }

    getSyntheticElem({
        key,
        validation_type,
        synthetic_label,
        audio_gen_language,
        audio_gen_person,
        audio_gen_text
    }) {
        return (
            <Panel bsStyle="info">
                <Panel.Heading>
                    <Panel.Title><ControlLabel className="label-color-333333">{synthetic_label}</ControlLabel></Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <ASFieldGroup
                        label={this.props.langText.Body.Lang}
                        validationState={this.validation[validation_type][audio_gen_language](this.state.data)}>
                        <ASSelect
                            value={this.state.data[audio_gen_language]}
                            options={this.selectOptions.audio_gen_language}
                            onChange={e => {
                                this.state.data[audio_gen_language] = e.value;
                                this.setState({data: this.state.data});
                            }}/>
                    </ASFieldGroup>
                    <ASFieldGroup
                        label={this.props.langText.Body.ScriptAudioClass}
                        validationState={this.validation[validation_type][audio_gen_person](this.state.data)}>
                        <ASSelect
                            value={this.state.data[audio_gen_person]}
                            options={this.selectOptions.audio_gen_person}
                            onChange={e => {
                                this.state.data[audio_gen_person] = e.value;
                                this.setState({data: this.state.data});
                            }}/>
                    </ASFieldGroup>
                    <ASFieldGroup
                        label={this.props.langText.Body.Text}
                        groupStyle={{marginBottom: 0}}
                        validationState={this.validation[validation_type][audio_gen_text](this.state.data)}>
                        <Col xs={12} sm={12}>
                            <FormControl
                                componentClass="textarea"
                                value={this.state.data[audio_gen_text]}
                                onChange={e => {
                                    this.state.data[audio_gen_text] = e.target.value;
                                    this.setState({data: this.state.data});
                                }}/>
                        </Col>
                        
                    </ASFieldGroup>
                    {/* <ASFieldGroup
                        label={this.props.langText.Body.ScriptCustomColumn}
                        groupStyle={{paddingLeft: "10%"}}
                        labelStyle={{fontSize: "20%"}}>
                        <Col xs={12} sm={12}>
                            <Col xs={9} sm={9} style={{paddingRight: 0}}>
                                <ASSelect
                                    value={this.state.screen_data.audio_custom_column[key]}
                                    options={this.selectOptions.audio_custom_column}
                                    onChange={e => {
                                        this.state.screen_data.audio_custom_column[key] = e.value;
                                        this.setState({screen_data: this.state.screen_data});
                                    }}/>
                            </Col>
                            <Col xs={3} sm={3}>
                                <Button
                                    bsStyle="default"
                                    bsSize="sm"
                                    style={{marginTop: "3px"}}
                                    onClick={e => {
                                        console.log(e);
                                    }}>
                                    {this.props.langText.Body.Insert2}
                                </Button>
                            </Col>
                        </Col>
                    </ASFieldGroup> */}
                </Panel.Body>
            </Panel>
        );
    }

    getSessionSelect(condition) {
        let target_index = this.state.data.nexts.findIndex(row => row.condition === condition);
        return <ASSelect
            value={target_index !== -1 ? this.state.data.nexts[target_index].block_no : null}
            options={this.getSessions()}
            placeholder={this.props.langText.SelectOption.EndCall}
            isClearable={true}
            onChange={e => {
                if (e) {
                    if (target_index !== -1) {
                        this.state.data.nexts[target_index].block_no = e.value;
                    } else {
                        this.state.data.nexts.push({condition, block_no: e.value});
                    }
                } else {
                    if (target_index !== -1) {
                        this.state.data.nexts.splice(target_index, 1);
                    }
                }
                this.setState({data: this.state.data});
            }}/>;
    }

    saveEvent() {
        let link_create_arr = [];

        this.state.data.nexts.forEach(next => {
            let la = link_create_arr.find(link => link.block_no === next.block_no);
            if (la) {
                la.condition += `,${next.condition}`;
            } else {
                link_create_arr.push(Object.assign({}, next));
            }
        });

        this.model.removeRightLink();
        link_create_arr.forEach(create => {
            const find_model = this.model.models.find(model => model.getOptions().number === create.block_no);
            if (find_model) {
                const diagram = this.model.getParent().getParent();
                const source_port = this.model.getPorts().right;
                const target_port = find_model.getPorts().left;
                const new_link = source_port.createLinkModel();

                new_link.addLabel(create.condition);
                new_link.setSourcePort(source_port);
                new_link.setTargetPort(target_port);
                source_port.reportPosition();
                target_port.reportPosition();
                diagram.addLink(new_link);
            }
        });

        this.model.c_data = Object.assign(this.model.c_data, this.state.data);
        console.log(this.model.c_data);
        this.props.onHide();
    }

    cancelEvent() {
        this.props.onHide();
    }

    getSaveBtn() {
        return (
            <Button
                className="ok-execute-btn"
                bsStyle="info"
                onClick={e => this.saveEvent()}
                disabled={!this.model.checkValidation(this.props.linkFlag ? "link" : "menu", this.state.data)}>
                {this.props.langText.Body.Save}
            </Button>
        );
    }

    getCancelBtn() {
        return (
            <Button
                className="default-button"
                bsStyle="danger"
                style={{marginRight: "5px"}}
                onClick={e => this.cancelEvent()}>
                {this.props.langText.Body.Cancel}
            </Button>
        );
    }

    getSessions() {
        let session_data= [];

        this.model.models.forEach(row => {
            if (this.model.getID() !== row.getID() && row.getOptions().number !== 0) {
                session_data.push({
                    label: `#${row.getOptions().number} ${row.c_data.title}`,
                    value: row.getOptions().number
                });
            }
        });

        return session_data;
    }

    // 一般MENU用ELEM
    getOptionElem() {
        return [];
    }

    // 複数LINK用ELEM
    getLinkElem() {
        return (
            <>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptNextSession}>
                    {this.getSessionSelect("all")}
                </ASFieldGroup>
            </>
        );
    }

    render() {
        return (
            <MenuRow>
                <TitleCol xs={12} sm={12}>
                    <label>#{this.number} {this.state.data.title}</label>
                </TitleCol>

                <ContentCol xs={12} sm={12}>
                    {this.props.linkFlag ? this.getLinkElem() : this.getOptionElem()}
                </ContentCol>

                <BottomCol xs={12} sm={12} width={this.state.footter_width}>
                    <div className="pull-right">
                        {this.getCancelBtn()}
                        {this.getSaveBtn()}
                    </div>
                </BottomCol>
            </MenuRow>
        );
    }
}