import React from "react";
import {Panel, Col} from "react-bootstrap";
import Component from "../../components/AscComponent";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import BusinessSetting from "./BusinessSetting";
import Type from "./Type.json";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/ja";
moment.locale('ja');

export default class BusinessCalendarHourSettingModal extends Component {
    constructor(props) {
        super(props);
        this.day_string = this.props.dayString;
        this.action_string = this.props.actionString;
        this.business_string = this.props.businessString;

        this.script_select = this.props.scriptSelect;
        this.voice_select = this.props.voiceSelect;

        this.state = {
            irregular_date: this.props.detail.irregular_date || moment(),
            type: this.props.detail.type || null,
            business_start_hour: this.props.detail.business_start_hour || null,
            business_end_hour: this.props.detail.business_end_hour || null,
            business_hour_action_flag: this.props.detail.business_hour_action_flag || null,
            business_hour_action_detail: this.props.detail.business_hour_action_detail || null,
            business_hour_voice_file_id: this.props.detail.business_hour_voice_file_id || null,
            non_business_hour_action_flag: this.props.detail.non_business_hour_action_flag || null,
            non_business_hour_action_detail: this.props.detail.non_business_hour_action_detail || null,
            non_business_hour_voice_file_id: this.props.detail.non_business_hour_voice_file_id || null
        };
    }

    validationHandle = (param) => {
        switch(param) {
            case "carendar_set":
            case "carendar_ire":
                return (
                        this.state.type === Type.BUSINESS.END || 
                        (
                            this.state.business_start_hour &&
                            this.state.business_end_hour &&
                            this.state.business_hour_action_flag &&
                            (this.state.business_hour_action_flag !== Type.ACTION.VOICE || this.state.business_hour_voice_file_id) &&
                            (this.state.business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.business_hour_action_detail)
                        )
                    ) &&
                    this.state.non_business_hour_action_flag &&
                    (this.state.non_business_hour_action_flag !== Type.ACTION.VOICE || this.state.non_business_hour_voice_file_id) &&
                    (this.state.non_business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.non_business_hour_action_detail) &&
                    (this.state.modalType !== "carendar_ire" || this.state.irregular_date);
            default:
                return false;
        }
    }

    getModalData = () => {
        let elem = [];

        if (this.props.state.modalType === "carendar_set") {
            elem.push(
                <ASFieldGroup
                    key="setting_days"
                    label={this.props.langText.Body.Day}>
                    <p>{this.props.days.map(row => this.day_string[row]).join(",")}</p>
                </ASFieldGroup>
            );
        } else {
            elem.push(
                <ASFieldGroup
                    key="irregular_date"
                    label={this.props.langText.Body.Date}
                    validationState={this.validationHandle("irregular_date")}>
                    <div>
                        <DatePicker
                            selected={this.state.irregular_date}
                            dateFormat={this.props.langText.Body.DateFormat}  
                            readOnly={false}
                            onChange = {e => this.setState({irregular_date: e})}
                            onKeyDown = {e => {
                                e.preventDefault();
                                return false;
                            }} 
                        />
                    </div>
                    
                </ASFieldGroup>
            );
        }
        

        elem.push(
            <BusinessSetting
                key="business-setting"
                state={this.state}
                propSetState={this.propSetState}
                read={this.props.read}
                dayString={this.day_string}
                actionString={this.action_string}
                businessString={this.business_string}
                scriptSelect={this.script_select}
                voiceSelect={this.voice_select}
                langText={this.props.langText}
                charaLimit={this.props.charaLimit}/>
        );

        return <Col sm={12}>{elem}</Col>;
    }

    getSettingState() {
        let state = JSON.parse(JSON.stringify(this.props.state));
        if (this.props.read) state.modalType = "read";
        return state;
    }

    render() {
        
        return (
            <AscModal
                key="business-calendar-time-setting-modal"
                state={this.getSettingState()}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.validationHandle}
                customFunc={() => {
                    let state = {...this.state};
                    state.irregular_date = this.getMomentTime({date: state.irregular_date, format: this.props.langText.Body.DateFormat});
                    this.props.onClick(state);
                }}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.state.modalType === "carendar_set" ? this.props.langText.Body.BusinessHourSetting : this.props.langText.Body.IrregularSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getModalData()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}