import React, { Component } from "react";
import { Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * 吹き出しを表示するデータを表示
 */
export default class SetDataWithSpeechBubble extends Component {

    /**
     * マウスオーバーでデータ内容が表示されるコンポーネントを作成
     * @return {component}
     */
    setDisplayData = () => {
        let iconElement = "";
        if (this.props.infoIconDisplayFlag) {
            //アイコンを設置する
            iconElement = (
                <FontAwesomeIcon
                    className="info-icon"
                    icon={faInfoCircle}
                    color="silver" size="lg"
                />
            );
        }

        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip">
                        {this.props.speechBubbleData || this.props.displayData}
                    </Tooltip>
                }
                delayShow={300}
            >
                <span>
                    {this.props.displayData}
                    {iconElement}
                </span>
            </OverlayTrigger>
        );
    }

    render() {
        return (
            <Row>
                {this.setDisplayData()}
            </Row>

        );
    }
}
