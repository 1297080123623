export default(column_name, lang) => {
    let selectOptions = {
        inbound_group_type_by_search: [
            {
                value: 'normal',
                label: lang.InboundGroupTypeNormal
            }, {
                value: 'android',
                label: lang.InboundGroupTypeAndroid
            }
        ],
        inbound_group_type: [
            {
                value: 'normal',
                label: `${lang.InboundGroupTypeNormalSearch}`
            }, {
                value: 'android',
                label: `${lang.InboundGroupTypeAndroid}`
            },{
                value: 'all',
                label: `${lang.InboundGroupTypeMulti}(${lang.InboundGroupTypeMultiSearch})`
            }
        ],
        inbound_type: [
            {
                value: 'hardphone',
                label: lang.InboundTypeHardPhone
            }, {
                value: 'softphone',
                label: lang.InboundTypeSoftphone
            }, {
                value: 'android',
                label: lang.InboundTypeAndroid
            }
        ],
        external_setting_type: [
            {
                value: 'extension',
                label: lang.Extension
            },
            {
                value: 'inboundGroup',
                label: lang.InboundGroup
            }
        ],
        gateway_type: [
            {
                value: 'kddi',
                label: lang.Kddi
            }, {
                value: 'rakuten',
                label: lang.Rakuten
            }, {
                value: 'prodelight',
                label: lang.Prodelight
            }, {
                value: 'softbank',
                label: lang.Softbank
            }
        ],
        outbound_prefix: [
            {
                value: '0',
                label: '0'
            }, {
                value: '1',
                label: '1'
            }, {
                value: '2',
                label: '2'
            }, {
                value: '3',
                label: '3'
            }, {
                value: '4',
                label: '4'
            }, {
                value: '5',
                label: '5'
            }, {
                value: '6',
                label: '6'
            }, {
                value: '7',
                label: '7'
            }, {
                value: '8',
                label: '8'
            }, {
                value: '9',
                label: '9'
            }
        ],
        monitoring_flg: [
            {
                value: 'Y',
                label: lang.MonitoringFlgY
            }, {
                value: 'N',
                label: lang.MonitoringFlgN
            },
        ],
        monitoring_flg_list: [
            {
                value: 'Y',
                label: lang.MonitoringFlgListY
            },
            {
                value: 'N',
                label: lang.MonitoringFlgListN
            }
        ],
        inout_type: [
            {
                value: 'in',
                label: 'IN'
            },
            {
                value: 'out',
                label: 'OUT'
            }
        ],
        call_result: [
            {
                value: 'ANSWERED',
                label: 'ANSWERED'
            },
            {
                value: 'NO ANSWER',
                label: 'NO ANSWER'
            },
            {
                value: 'BUSY',
                label: 'BUSY'
            },
            {
                value: 'PLAYBACK',
                label: 'PLAYBACK'
            },
            {
                value: 'FAILED',
                label: 'FAILED'
            }
        ],
        download_file_type: [
            {
                value: 'ogg',
                label: 'OGG'
            },
            {
                value: 'mp3',
                label: 'MP3'
            },
            {
                value: 'm4a',
                label: 'M4a'
            }
        ],
        download_file_type_no_mp3: [
            {
                value: 'ogg',
                label: 'OGG'
            },
            {
                value: 'm4a',
                label: 'M4a'
            }
        ],
        hour_show_list : [
            {value :"00",label:"00"},
            {value :"01",label:"01"},
            {value :"02",label:"02"},
            {value :"03",label:"03"},
            {value :"04",label:"04"},
            {value :"05",label:"05"},
            {value :"06",label:"06"},
            {value :"07",label:"07"},
            {value :"08",label:"08"},
            {value :"09",label:"09"},
            {value :"10",label:"10"},
            {value :"11",label:"11"},
            {value :"12",label:"12"},
            {value :"13",label:"13"},
            {value :"14",label:"14"},
            {value :"15",label:"15"},
            {value :"16",label:"16"},
            {value :"17",label:"17"},
            {value :"18",label:"18"},
            {value :"19",label:"19"},
            {value :"20",label:"20"},
            {value :"21",label:"21"},
            {value :"22",label:"22"},
            {value :"23",label:"23"},
        ],
        minute_show_list : [
            {value :"00",label:"00"},
            {value :"01",label:"01"},
            {value :"02",label:"02"},
            {value :"03",label:"03"},
            {value :"04",label:"04"},
            {value :"05",label:"05"},
            {value :"06",label:"06"},
            {value :"07",label:"07"},
            {value :"08",label:"08"},
            {value :"09",label:"09"},
            {value :"10",label:"10"},
            {value :"11",label:"11"},
            {value :"12",label:"12"},
            {value :"13",label:"13"},
            {value :"14",label:"14"},
            {value :"15",label:"15"},
            {value :"16",label:"16"},
            {value :"17",label:"17"},
            {value :"18",label:"18"},
            {value :"19",label:"19"},
            {value :"20",label:"20"},
            {value :"21",label:"21"},
            {value :"22",label:"22"},
            {value :"23",label:"23"},
            {value :"24",label:"24"},
            {value :"25",label:"25"},
            {value :"26",label:"26"},
            {value :"27",label:"27"},
            {value :"28",label:"28"},
            {value :"29",label:"29"},
            {value :"30",label:"30"},
            {value :"31",label:"31"},
            {value :"32",label:"32"},
            {value :"33",label:"33"},
            {value :"34",label:"34"},
            {value :"35",label:"35"},
            {value :"36",label:"36"},
            {value :"37",label:"37"},
            {value :"38",label:"38"},
            {value :"39",label:"39"},
            {value :"40",label:"40"},
            {value :"41",label:"41"},
            {value :"42",label:"42"},
            {value :"43",label:"43"},
            {value :"44",label:"44"},
            {value :"45",label:"45"},
            {value :"46",label:"46"},
            {value :"47",label:"47"},
            {value :"48",label:"48"},
            {value :"49",label:"49"},
            {value :"50",label:"50"},
            {value :"51",label:"51"},
            {value :"52",label:"52"},
            {value :"53",label:"53"},
            {value :"54",label:"54"},
            {value :"55",label:"55"},
            {value :"56",label:"56"},
            {value :"57",label:"57"},
            {value :"58",label:"58"},
            {value :"59",label:"59"},
        ],

        scope: [
            {
                value: 'floor',
                label: lang.Floor
            },
            {
                value: 'department',
                label: lang.Department
            },
            {
                value: 'company',
                label: lang.Company
            },
            {
                value: 'oem',
                label: lang.Oem
            },
            {
                value: 'system',
                label: lang.System
            }
        ],
        audio_type: [
            { value: "file", label: lang.AudioFileUpload },
            { value: "select", label: lang.AudioSelect },
            { value: "synthetic", label: lang.AudioSynthetic }
        ],
        audio_gen_language: [
            { value: "ja-JP", label: lang.LangJapan },
            { value: "en-EN", label: lang.LangEnglish }
        ],
        audio_gen_person: [
            { value: "Mizuki", label: lang.AudioClassMizuki },
            { value: "Takumi", label: lang.AudioClassTakumi }
        ],
        audio_custom_column: [
            { value: "tel_no", label: lang.CustomColumnTelNo},
            { value: "address", label: lang.CustomColumnAddress}
        ],
        select_number: [
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7" },
            { value: 8, label: "8" },
            { value: 9, label: "9" }
        ],
        push_number: [
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9", label: "9" },
            { value: "0", label: "0" },
            { value: "*", label: "*" },
            { value: "#", label: "#" }
        ],
        next_session_event: [
            { value: "end", label: lang.EndCall },
        ],
    };

    return column_name ? selectOptions[column_name] : selectOptions;
}