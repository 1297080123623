import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {ViewCompanyElement} from "../Elements/AscElements/CompanyElements";
import {ViewPermissionName, ViewPermissionUserCount, ViewPermissionDetail} from "../Elements/AscElements/PermissionElements";


export default class PermissionDeleteModal extends Component {

    render() {

        let ViewCompanyElement_annotation = null;
        if (this.props.state.base_flag === "Y" && this.props.state.modalType === "delete") {
            ViewCompanyElement_annotation = this.props.langText.Message.Permission_BasePermissionDeleteError;
        }

        let ViewPermissionUserCount_annotation = null;
        if (this.props.state.user_count !== 0 && this.props.state.modalType === "delete") {
            ViewPermissionUserCount_annotation = this.props.langText.Message.Permission_UserCountDeleteError;
        }

        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.PermissionInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <ViewCompanyElement
                            state={this.props.state}
                            langText={this.props.langText}
                            annotation={ViewCompanyElement_annotation}>
                        </ViewCompanyElement>
                        <ViewPermissionName
                            state={this.props.state}
                            langText={this.props.langText}>
                        </ViewPermissionName>
                        <ViewPermissionUserCount
                            state={this.props.state}
                            langText={this.props.langText}
                            annotation={ViewPermissionUserCount_annotation}>
                        </ViewPermissionUserCount>
                        <ViewPermissionDetail
                            state={this.props.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}>
                        </ViewPermissionDetail>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
