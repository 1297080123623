import React, {Component} from "react";
import {Panel, Glyphicon} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";


export default class DepartmentModal extends Component {
    getFloorInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onKeyDownIntCheck,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.companySelect[0]
                        ? <Glyphicon glyph="refresh" className="spinning"/>
                        : langText.SelectOption.Placeholder}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push(
                <InlineForm
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.departmentId}
                    onChange = {e => onSelectChange(e, "departmentId")}
                    options = {state.departmentSelect}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("departmentId")}
                    placeholder = {!state.buttondisabledArr.departmentId[0] && state.company_id
                        ? <Glyphicon glyph="refresh" className="spinning"/>
                        : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.departmentId[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}>
                    {state.departmentName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "floorName"
                    controlId = "floorName"
                    label = {langText.Body.FloorName}
                    type = "text"
                    value = {state.floorName}
                    onChange = {e => onTextChange_Limit(e, "floorName",charaLimit.Department_departmentName)}
                    validationState = {validationHandle("floorName")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "floorName"
                    controlId = "floorName"
                    label = {langText.Body.FloorName}>
                    {state.floorName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "floorPrefix"
                    controlId = "floorPrefix"
                    label = {langText.Body.Prefix}
                    type = "text"
                    value = {state.floorPrefix}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e => onTextChange_Limit(e, "floorPrefix", charaLimit.Floor_prefix)}
                    validationState = {validationHandle("floorPrefix")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "floorPrefix"
                    controlId = "floorPrefix"
                    label = {langText.Body.Prefix}>
                    {state.floorPrefix}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}
                    type = "select"
                    value = {state.pbx}
                    onChange = {e => onSelectChange(e, "pbx")}
                    options = {state.pbxSelect}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("pbx")}
                    placeholder = {!state.buttondisabledArr.pbx[0] && state.company_id
                        ? <Glyphicon glyph="refresh" className="spinning"/>
                        : langText.SelectOption.Placeholder}
                    isDisabled = {!state.company_id}/>
            );
        } else {
            let pbxRow = state.pbxSelect.find(row => state.pbx === row.value);
            itemArr.push(
                <InlineForm.static
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}>
                    {pbxRow ? pbxRow.label : ""}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update") {
            itemArr.push(
                <InlineForm.static
                    key = "call_record_password"
                    controlId = "call_record_password"
                    label = {langText.Body.VoiceLogPasswordNow}>
                    {state.call_record_password ? state.call_record_password : ""}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "audio_record_password"
                    controlId = "audio_record_password"
                    label = {state.modalType == "update"?langText.Body.VoiceLogPasswordNew:langText.Body.VoiceLogPassword}
                    type = "text"
                    value = {state.audio_record_password}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e => onTextChange_Limit(e, "audio_record_password", 4)}
                    validationState = {validationHandle("audio_record_password")}
                    placeholder = {langText.SelectOption.Placeholder_4_number}/>
                );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    type = "text"
                    value = {state.memo}
                    onChange = {e => onTextChange_Limit(e, "memo",charaLimit.Department_memo)}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    //削除モーダルに所属ユーザー数表示
    getFloorUserInfo=()=>{
        let message = [];
        let {user_cnt} = this.props.state;
        if(user_cnt > 0){
            message.push(this.props.sprintf(this.props.langText.Message.UserBelongedFloorMessage, user_cnt));
        }
        
        return(
            <p className="user-belonged">
                {message}
            </p>
        )
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.FloorSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getFloorInfoItem()}
                    </Panel.Body>
                </Panel>
                {
                    (this.props.state.modalType === "delete" && this.props.state.user_cnt > 0) &&
                    <div>
                        {this.getFloorUserInfo()}
                    </div>
                }
            </AscModal>
        );
    }
}