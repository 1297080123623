import React, { Component } from "react";
import CallType from "./CallType";

import MenuStart from "./Menus/MenuStart";
import MenuPlay from "./Menus/MenuPlay";
import MenuQuestion from "./Menus/MenuQuestion";
import MenuNumber from "./Menus/MenuNumber";
import MenuTransfer from "./Menus/MenuTransfer";
import MenuRecord from "./Menus/MenuRecord";
import MenuCount from "./Menus/MenuCount";
import MenuSms from "./Menus/MenuSms";
import MenuIncoming from "./Menus/MenuIncoming";
// import MenuTemplate from "./Menus/MenuTemplate";

export default class Menu extends Component {
    render() {
        const {
            item,
            ...props
        } = this.props;

        switch (item) {
            case CallType.START: return <MenuStart {...props} />;
            case CallType.PLAY: return <MenuPlay {...props} />;
            case CallType.QUESTION: return <MenuQuestion {...props} />;
            case CallType.NUMBER: return <MenuNumber {...props} />;
            case CallType.TRANSFER: return <MenuTransfer {...props} />;
            case CallType.RECORD: return <MenuRecord {...props} />;
            case CallType.COUNT: return <MenuCount {...props} />;
            case CallType.SMS: return <MenuSms {...props} />;
            case CallType.INCOMING: return <MenuIncoming {...props} />;
            default: throw new Error("can not found case");
            // case "template": return <MenuTemplate {...props} />;
        }
    }
}