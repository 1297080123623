import CallType from "../CallType";

const VALID_TELNO = /^0([0-9]{1,18})[*]?$/;
const TIMEOUT_DEFAULT = 30;

function getTitle(select_options, lang_text) {
    return {
        data: {
            title: "",
        },
        validation: {
            title: d => d.title.length ? null : lang_text.Message.Validation_string_input
        }
    }
}

function getAudio(select_options, lang_text, prefix = "") {
    if (prefix) prefix += "_";
    const audio_type = `${prefix}audio_type`;
    const audio_file = `${prefix}audio_file`;
    const audio_file_id = `${prefix}audio_file_id`;
    const audio_gen_language = `${prefix}audio_gen_language`;
    const audio_gen_person = `${prefix}audio_gen_person`;
    const audio_gen_text = `${prefix}audio_gen_text`;

    return {
        data: {
            [audio_type]: select_options.audio_type[0].value,
            [audio_file]: [],
            [audio_file_id]: null,
            [audio_gen_language]: select_options.audio_gen_language[0].value,
            [audio_gen_person]: select_options.audio_gen_person[0].value,
            [audio_gen_text]: "",
        },
        validation: {
            [audio_type]: d => d[audio_type] ? null : lang_text.Message.Validation_Select_input,
            [audio_file]: d => (d[audio_type] !== "file" || (d[audio_file] && d[audio_file][0])) ? null : lang_text.Message.Validation_File_input,
            [audio_file_id]: d => (d[audio_type] !== "select" || d[audio_file_id]) ? null : lang_text.Message.Validation_Select_input,
            [audio_gen_language]: d => (d[audio_type] !== "synthetic" || d[audio_gen_language]) ? null : lang_text.Message.Validation_Select_input,
            [audio_gen_person]: d => (d[audio_type] !== "synthetic" || d[audio_gen_person]) ? null : lang_text.Message.Validation_Select_input,
            [audio_gen_text]: d => (d[audio_type] !== "synthetic" || d[audio_gen_text]) ? null : lang_text.Message.Validation_string_input,
        }
    };
}

export const Item = {
    [CallType.START]: (select_options, lang_text) => {
        return {
            data: {
                nexts: []
            },
    
            validation: {
                menu: {},
                link: {}
            }
        };
    },

    [CallType.PLAY]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const audio = getAudio(select_options, lang_text);
    
        return {
            data: Object.assign({
                nexts: []
            }, title.data, audio.data),
    
            validation: {
                menu: Object.assign({}, title.validation, audio.validation),
                link: {}
            }
        };
    },

    [CallType.QUESTION]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const audio = getAudio(select_options, lang_text);
        const next_audio = getAudio(select_options, lang_text, "next_other_num_select");
        const timeout_audio = getAudio(select_options, lang_text, "timeout");
    
        return {
            data: Object.assign({
                loop_cnt: select_options.select_number[0].value,
                push_nums: [{ number: select_options.push_number[0].value, text: "" }],
                next_other_num_select_event: select_options.next_session_event[0].value,
                timeout_event: select_options.next_session_event[0].value,
                timeout_seconds: TIMEOUT_DEFAULT,
                nexts: []
            }, title.data, audio.data, next_audio.data, timeout_audio.data),
    
            validation: {
                menu: Object.assign({
                    loop_cnt: d => Number.isInteger(d.loop_cnt) ? null : lang_text.Message.Validation_Number_input,
                    push_nums: d => d.push_nums.length ? null : lang_text.Message.Validation_Select_input,
                    timeout_seconds: d => d.timeout_seconds > 0 ? null : lang_text.Message.Validation_Number_input,
                }, title.validation, audio.validation, next_audio.validation, timeout_audio.validation),
    
                link: {}
            }
        };
    },

    [CallType.NUMBER]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const audio = getAudio(select_options, lang_text);
        const input_check_audio = getAudio(select_options, lang_text, "input_check");
        const timeout_audio = getAudio(select_options, lang_text, "timeout");
    
        return {
            data: Object.assign({
                digits: 1,
                input_check_flag: false,
                input_check_decision_number: select_options.select_number[0].value,
                next_other_num_select_event: select_options.next_session_event[0].value,
                timeout_event: select_options.next_session_event[0].value,
                timeout_seconds: TIMEOUT_DEFAULT,
                nexts: []
            }, title.data, audio.data, input_check_audio.data, timeout_audio.data),
    
            validation: {
                menu: Object.assign({
                    digits: d => Number.isInteger(d.digits) ? null : lang_text.Message.Validation_Number_input,
                    input_check_audio_type: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_type(d) : null,
                    input_check_audio_file: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_file(d) : null,
                    input_check_audio_file_id: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_file_id(d) : null,
                    input_check_audio_gen_language: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_gen_language(d) : null,
                    input_check_audio_gen_person: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_gen_person(d) : null,
                    input_check_audio_gen_text: d => d.input_check_flag ? input_check_audio.validation.input_check_audio_gen_text(d) : null,
                    timeout_seconds: d => d.timeout_seconds > 0 ? null : lang_text.Message.Validation_Number_input,
                }, title.validation, audio.validation, timeout_audio.validation),
                link: {}
            }
        };
    },

    [CallType.TRANSFER]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const audio = getAudio(select_options, lang_text);
        const transfer_audio = getAudio(select_options, lang_text, "transfer");
    
        return {
            data: Object.assign({
                transfer_tel_number: "",
                transfer_timeout_seconds: null,
                nexts: []
            }, title.data, audio.data, transfer_audio.data),
    
            validation: {
                menu: Object.assign({
                    transfer_tel_number: d => VALID_TELNO.test(d.transfer_tel_number) ? null : lang_text.Message.Validation_Telno,
                }, title.validation, audio.validation, transfer_audio.validation),
                link: {}
            }
        };
    },

    [CallType.RECORD]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const audio = getAudio(select_options, lang_text);
    
        return {
            data: Object.assign({
                record_cancel_number: select_options.push_number[0].value,
                record_max_seconds: 15,
                transfer_timeout_seconds: null,
                nexts: []
            }, title.data, audio.data),
    
            validation: {
                menu: Object.assign({
                    record_cancel_number: d => d.record_cancel_number ? null : lang_text.Message.Validation_Select_input,
                    record_max_seconds: d => Number.isInteger(d.record_max_seconds) ? null : lang_text.Message.Validation_Number_input,
                }, title.validation, audio.validation),
                link: {}
            }
        };
    },

    [CallType.COUNT]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
    
        return {
            data: Object.assign({
                nexts: []
            }, title.data),
    
            validation: {
                menu: Object.assign({}, title.validation),
                link: {}
            }
        };
    },

    [CallType.SMS]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
    
        return {
            data: Object.assign({
                display_number_id: null,
                message: "",
                nexts: []
            }, title.data),
    
            validation: {
                menu: Object.assign({
                    message: d => d.message ? null : lang_text.Message.Validation_string_input,
                }, title.validation),
                link: {}
            }
        };
    },

    [CallType.INCOMING]: (select_options, lang_text) => {
        const title = getTitle(select_options, lang_text);
        const queue_audio = getAudio(select_options, lang_text, "queue");
    
        return {
            data: Object.assign({
                groups: [],
                nexts: []
            }, title.data, queue_audio.data),
    
            validation: {
                menu: Object.assign({
                    groups: d => d.groups.length ? null : lang_text.Message.Validation_Select_input,
                }, title.validation, queue_audio.validation),
                link: {}
            }
        };
    }
};