import React from "react";
import {Grid, Row, Col, ButtonGroup, Glyphicon} from "react-bootstrap";
import ReactLoading from 'react-loading';
import Component from "./components/AscComponent";
import {withRouter} from "react-router-dom";
import Routes from "./AscRoutes";
import Header from "./containers/Elements/Header/Header";
import SideBar from "./containers/Elements/SideBar/SideBar";
import "./App.css";

// Reactで画像表示する場合css上でのbackgroundimage使用不可(画像が呼べない)
// headerとAppのみ特例でStyle直書きしてます

// BtoCは"./image/bg_c.jpg"使用。BtoBは"./image/bg_b.jpg"使用

// import loginImg from "./image/bg_c.jpg";
import loginImg_Mini from "./image/logInMini.png";


const tryRequire = path => {
    try {
        return require(path);
    } catch (e) {
        return null;
    }
};

class App extends Component {
    constructor(props) {
        super(props);

        // ヘッダー部の高さを固定
        let headerHeight = 65

        this.state = {
            lang: "ja",
            isAuthenticated: false,
            isAuthenticating: true,
            headerHeight: headerHeight,
            width: window.innerWidth,
            appHeight: window.innerWidth < 768 ? "100%" : window.innerHeight - headerHeight,
            tableHeight: window.innerHeight - headerHeight - 85,
            userInfo: {},
            historyLock: false,
            userCompanyControlParameters: {},
            systemParameters: {},
        }
        this.getSystemParameters();
    }

    async getSystemParameters() {

        let domain = window.location.href;

        //　ドメインの前の「https://」削除、「/SignIn」などコントロール名削除
        // https://dev.omni-cuscon.net/SignIn
        var pattern = /^(https?:\/\/)/;
        let replace_domain = domain.replace(pattern,'').split('/');
        
        await this.ascAxios('post',`/Sign/systemParametersCheck`, {
            parameter_type : "default",
        })
        .then(dafault_parameters_result=>{
            let dafault_parameters = dafault_parameters_result.data;

            this.ascAxios('post',`/Sign/systemParametersCheck`, {
                parameter_type : replace_domain[0],
            })
            .then(domain_parameters_result =>{
                let domain_search_parameters = domain_parameters_result.data;
             
                Object.assign(dafault_parameters, domain_search_parameters);
                this.setState({systemParameters: dafault_parameters});

            })
        })
    }

    getMyPath = () => {
        return this.props.location.pathname;
    }

    userHasAuthenticated = (authenticated, userInfo) => {
        let isAuthenticated = authenticated;
        userInfo = userInfo || {};

        this.setState({
            isAuthenticated,
            userInfo
        });
    };

    authenticating = authenticating => {
        this.setState({isAuthenticating: authenticating});
    };

    async componentDidMount() {
        try {
            
            
            let response = await this.ascAxios("post", "/Sign/check");

            if (response && response.data && response.data.user_id) {
                this.userHasAuthenticated(true, response.data);
                
                if (response.data.company_id) {
                    let cm12_id = response.data.company_id;
                    await this.ascAxios('post', `Common/getCompanyControlParameters`, {
                        cm12_id,
                    })
                    .then(result => {
                        this.setState({userCompanyControlParameters: result.data});
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                }

            } else {
                this.historyPush("/SignIn");
            }
        } catch (e) {
            console.error(e);
        }

        this.authenticating(false);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({
            width: window.innerWidth,
            appHeight: window.innerWidth < 768 ? "100%" : window.innerHeight - this.state.headerHeight,
            tableHeight: window.innerHeight - this.state.headerHeight - 85,
        });
    };

    setHistoryLock = (lock) => {
        this.setState({historyLock: lock});
    }

    historyPush = (target) => {
        if (!this.state.historyLock || window.confirm(this.getMyLangText(this.state.lang).Message.InputDataResetBack)) {
            this.setHistoryLock(false);
            this
                .props
                .history
                .push(target);
        }
    };

    getMyLangText = (lang) => {
        let langArr = [
            "Body",
            "Header",
            "Message",
            "MultiSelect",
            "SelectOption",
            "SideBar",
            "Table",
            "Time",
            "CompanyControlParametersOption",
        ];

        let langObj = {};

        langArr.forEach(row => {
            langObj[row] = tryRequire(`./lang/${lang}/Elements/${row}`) ? tryRequire(`./lang/${lang}/Elements/${row}`).default : require(`./lang/ja/Elements/${row}`).default;
        });

        return langObj;
    }
    getCharaLimit = () => {
        let limitObj = [];
        limitObj = require(`./chara_limit`).default;
        return limitObj;
    }

    getCurrentPermission = () => {
        if (this.state.userInfo.permission_json_data) {
            for (let i = 0; i < this.state.userInfo.permission_json_data.length; i++) {
                let controller_id = this.state.userInfo.permission_json_data[i].controller_id;
                let path = this.props.location.pathname.replace("/", "");
                if (controller_id.toLowerCase() === path.toLowerCase()) {
                    return this.state.userInfo.permission_json_data[i];
                }
            }
        }
        return {};
    }

    render() {
        let childProps = {
            currentPermission: this.getCurrentPermission(),
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            setHistoryLock: this.setHistoryLock,
            historyPush: this.historyPush,
            lang: this.state.lang,
            width: this.state.width,
            userInfo: this.state.userInfo,
            getMyPath: this.getMyPath,
            langText: this.getMyLangText(this.state.lang),
            charaLimit: this.getCharaLimit(),
            tableHeight: this.state.tableHeight,
            boardWidth: {

                xxsmall:70,
                xsmall:80,
                small: 100,
                smedium: 120,
                medium: 150,
                large: 170,
                Control: 90,
                LargeControl: 120,
                XLargeControl: 150,
                Date:120,
                modified:128,
                sId: 40,
                id: 60,
                CallNumber:120,
                week: 75,
                UserName:135,
            },
            userCompanyControlParameters: this.state.userCompanyControlParameters,
            systemParameters: this.state.systemParameters,
        };

        let style =
        {
            backgroundImage: window.innerWidth < 768 ? `url(${loginImg_Mini})`: `url(${this.state.systemParameters["lOGIN_BG"]})`,
        };
        let appContent = <Grid>
                <Col xs={12} sm={12} className="signIn-logo-overview" style={style}>
                    {/* <div className="signIn-logo-string"><img src={loginLogo} class="img-logo"></img></div> */}
                    <div className="signIn-logo-string"><img src={this.state.systemParameters["LOGO"]} class="img-logo"></img></div>
                    <Routes childProps={childProps}/>
                </Col>
                
            </Grid>
        let upperSide = <SideBar childProps={childProps} parentType="upper"/>
        let lowerSide = <SideBar childProps={childProps} parentType="lower"/>

        let permissionList = this.state.userInfo.permission_json_data;
        if (this.state.isAuthenticated) {
            switch (this.props.location.pathname) {
                case "/Forbidden":
                    break;
                case "/SignIn":
                case "/":
                    // ログイン直後もしくは初期画面は最初にread権限があるコンテナへ(一つもなければforbiddenへ)
                    if (permissionList.map(obj => obj.read).filter(value => value).length === 0) {
                        this.props.location.pathname = "/Forbidden";
                        alert(childProps.langText.Message.AuthCheckError);
                    } else {
                        this.props.location.pathname = "/" + permissionList.find(obj => obj.read).controller_id;
                        // ログイン直後の権限セット
                        childProps.currentPermission = this.getCurrentPermission();
                    }
                    break;
                default:
                    // アクセスコンテナのread権限が無い場合はforbidden(権限が存在しない場合{}も)
                    if (!childProps.currentPermission.read) {
                        this.props.location.pathname = "/Forbidden";
                        alert(childProps.langText.Message.AuthCheckError);
                    }
                    break;

            }

            appContent = <Grid>
                <Row className="row-upper">
                    <Col xs={12} md={12} id="header-app" className="header-app">
                        <Header childProps={childProps}>
                            {this.state.width < 768 && upperSide}
                        </Header>
                    </Col>
                </Row>
                <Row className="row-lower">
                    <Col xs={12} sm={2} id="sidebar-app" className="sidebar-app" style={{height: this.state.appHeight}}>
                        <ButtonGroup className="sidebar-group" vertical>
                            {this.state.width >= 768 && lowerSide}
                        </ButtonGroup>
                    </Col>
                    <Col xs={12} sm={10} id="routes-app" className="routes-app" style={{height: this.state.appHeight}}>
                        <Routes childProps={childProps}/>
                    </Col>
                </Row>
            </Grid>;
        }

        return (
            !this.state.isAuthenticating ?
            <div className = "App">
                {appContent}
                <div id="asc-block-ui" className="asc-block-ui asc-display-none z-index-1500">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{childProps.langText.Body.Wait}</label>
                    </div>
                </div>
            </div>
            :
            <div className = "App">
                <div className="asc-block-ui">
                    <div className="asc-block-icon-box">
                        <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#fff" />
                        <label className="asc-block-label">{childProps.langText.Body.Wait}</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(App);