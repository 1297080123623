import React, {Component} from "react";
import {Panel, Radio, Col, Glyphicon} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import Favi from "../Elements/Favi/Favi";
import InlineForm from "../Elements/FromGroup/InlineForm";
import MultiSelect from "../Elements/Select/MultiSelect";
import "../Elements/FromGroup/form_select.css";
import * as GlobalConst from "../../components/AscConstants";

export default class InboundGroupModal extends Component {
    gewtInboundGroupInfoItem = () => {
        let {
            state,
            propSetState,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            onRadioChange,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push(
                <InlineForm
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.department_id}
                    onChange = {e => onSelectChange(e, "department_id")}
                    options = {state.departmentSelect}
                    isClearable = {true}
                    validationState = {validationHandle("department_id")}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}>
                    {state.department_name}
                </InlineForm.static>
            );
        }
        if (this.props.state.floor_flg) {
            if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                itemArr.push(
                    <InlineForm
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}
                        type = "select"
                        options = {state.floorSelect}
                        value = {state.floor_id}
                        onChange = {e => onSelectChange(e, "floor_id")}
                        isClearable = {true}
                        placeholder = {langText.SelectOption.Placeholder}
                        validationState = {validationHandle("floor_id")}
                        placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floor_id[0]}/>
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}>
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "group_name"
                    controlId = "group_name"
                    label = {langText.Body.GroupName}
                    type = "text"
                    value = {state.group_name}
                    onChange = {e =>onTextChange_Limit(e, "group_name",charaLimit.InboundG_group_name)}
                    validationState = {validationHandle("group_name")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "group_name"
                    controlId = "group_name"
                    label = {langText.Body.GroupName}>
                    {state.group_name}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "group_type"
                    controlId = "group_type"
                    label = {langText.Body.GroupType}>
                    {state.inboundGroupTypeSelect.map((row, key) =>
                        <div key = {key}>
                            <Col xs = {10} className="groupType-text">
                                <Radio
                                    name = "group_type_radio"
                                    key = {key}
                                    onChange = {e => onRadioChange(e, "group_type")}
                                    checked = {row.value === state.group_type}
                                    value = {row.value}
                                >
                                    <p>
                                        {row.label}
                                    </p>
                                </Radio>
                            </Col>
                            {row.value === "all" ?
                            <Col xs = {2} className = "groupType-icon">  
                                <Favi
                                    className = "fa-fw fa-icon"
                                    icon = {"normal"}
                                />
                                <Favi
                                    className = "fa-fw fa-icon"
                                    icon = {"android"}
                                />
                            </Col>
                            :
                            <Col xs = {2} className = "groupType-icon">
                                <Favi
                                    className = "fa-fw fa-icon"
                                    icon = {row.value === "normal" ? "hardphone" : row.value}
                                />
                            </Col>
                            }
                        </div>
                    )}
                </InlineForm>
            );
        }

        itemArr.push(
            <InlineForm
                label = {langText.MultiSelect.ExtensionSelect}
            >
                <MultiSelect
                key = "extension_number"
                controlId = "extension_number"
                propSetState = {propSetState}
                multiSelectText = {langText.MultiSelect}
                searchText = {langText.MultiSelect.ExtensionAndMemoSearch}
                
                options = {state.extensionNumberSelect}
                value = {state.extension_number}
                type = {state.modalType}
                isDisabled = {state.modalType === "delete" || state.modalType === "read"}
                multiSelectMessage = {langText.MultiSelect.SelectDataNumStr_NDelete}
                multiSelectDeleteMessage = {langText.MultiSelect.SelectDataNumStr_Delete}
                />
            </InlineForm>
            
            
        );
        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.InboundGroupSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.gewtInboundGroupInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
