import React from 'react';
import { FormControl } from "react-bootstrap";

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

export default class Record extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "audio_type",
                    audio_file: "audio_file",
                    audio_file_id: "audio_file_id",
                    audio_gen_language: "audio_gen_language",
                    audio_gen_person: "audio_gen_person",
                    audio_gen_text: "audio_gen_text"
                })}
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptRecordCancelNumber}>
                    <ASSelect
                        value={this.state.data.record_cancel_number}
                        options={this.selectOptions.push_number}
                        onChange={e => {
                            this.state.data.record_cancel_number = e.value;
                            this.setState({data: this.state.data});
                        }}/>
                </ASFieldGroup>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptMaxRecordTime}>
                    <div className="form-inline">
                        <FormControl
                            type="number"
                            value={this.state.data.record_max_seconds}
                            onChange={e => {
                                let num_value = parseInt(e.target.value);
                                if (isNaN(num_value)) {
                                    num_value = 15;
                                }
                                this.state.data.record_max_seconds = num_value;
                                this.setState(this.state.data);
                            }}
                            min="1"/>
                        秒
                    </div>
                </ASFieldGroup>
            </>
        );
    }
}