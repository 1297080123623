import React from "react";
import Component from "../../../components/AscComponent";
import {NavItem, Button} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import "./SideBar.css";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import OmniphoneLogo from "../../../image/omniphone_logo.png"
import CusconLogo from "../../../image/cuscon_logo.png"
import * as GlobalConst from "../../../components/AscConstants";

export default class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: this
                .props
                .childProps
                .getMyPath()
        };
    }

    getSideBarItems() {
        this.sideBarButtonItems = [
            {
                value: "/Gateway",
                text: this.props.childProps.langText.SideBar.Gateway,
                icon: faIcon.faServer,
            }, {
                value: "/Pbx",
                text: this.props.childProps.langText.SideBar.Pbx,
                icon: faIcon.faHdd,
            }, {
                value: "/ExternalNumber",
                text: this.props.childProps.langText.SideBar.ExternalNumber,
                icon: faIcon.faFax,
            }, {
                value: "/ExtensionNumber",
                text: this.props.childProps.langText.SideBar.ExtensionNumber,
                icon: faIcon.faHeadset,
            }, {
                value: "/InboundGroup",
                text: this.props.childProps.langText.SideBar.InboundGroup,
                icon: faIcon.faUsers,
            },{
                value: "/GroupExtensionNumber",
                text: this.props.childProps.langText.SideBar.GroupExtensionNumber,
                icon: faIcon.faUserFriends
            },{
                value: "/OutboundReject",
                text: this.props.childProps.langText.SideBar.OutboundReject,
                icon: faIcon.faPhoneSlash,
            }, {
                value: "/InboundReject",
                text: this.props.childProps.langText.SideBar.InboundReject,
                icon: faIcon.faBan,
            }, {
                value: "/BusinessCalendar",
                text: this.props.childProps.langText.SideBar.BusinessCalendar,
                icon: faIcon.faCalendarAlt,
            },
            {
                value: "/Script",
                text: this.props.childProps.langText.SideBar.Script,
                icon: faIcon.faRandom,
            },
            {
                value: "/VoiceFile",
                text: this.props.childProps.langText.SideBar.VoiceFile,
                icon: faIcon.faFileAudio,
            }, {
                value: "/Company",
                text: this.props.childProps.langText.SideBar.Company,
                icon: faIcon.faCity,
            }, {
                value: "/Department",
                text: this.props.childProps.langText.SideBar.Department,
                icon: faIcon.faBuilding,
            }, {
                value: "/Floor",
                text: this.props.childProps.langText.SideBar.Floor,
                icon: faIcon.faBuilding,
            }, {
                value: "/User",
                text: this.props.childProps.langText.SideBar.User,
                icon: faIcon.faUser,
            }, {
                value: "/Customer",
                text: this.props.childProps.langText.SideBar.Customer,
                icon: faIcon.faAddressBook,
            },
            {
                value: "/CallHistory",
                text: this.props.childProps.langText.SideBar.CallHistory,
                icon: faIcon.faHistory,
            },
            {
                value: "/Permission",
                text: this.props.childProps.langText.SideBar.Permission,
                icon: faIcon.faUsersSlash,
            },
        ];

        let returnValue = [];
        let permissionList = this.props.childProps.userInfo.permission_json_data;

        this.sideBarButtonItems.forEach(row => {
            for (let i = 0; i < permissionList.length; i++) {
                if (permissionList[i].controller_id === row.value.replace('/', '') && permissionList[i].read) {
                    if (permissionList[i].controller_id !== 'Floor') {
                        returnValue.push(row);
                    } else if (permissionList[i].controller_id === 'Floor' &&
                        (this.props.childProps.userInfo.floor_flg
                            || (permissionList[i].scope_code === GlobalConst.SCOPE_OBJECT.system.name
                                && this.props.childProps.systemParameters.SYSTEM_FLOOR_FLG === "Y")))
                    {
                        // フロア管理はフロアフラグTrueかシステム範囲の権限を持ちSYSTEM_FLOOR_FLG=Yのとき
                        returnValue.push(row);
                    }
                }
            }
        });


        return returnValue;
    }

    handleClick = param => event => {
        event.preventDefault();
        let value = null;

        if (param === "button") {
            value = event.target.closest(".sidebar-child").value;
        } else {
            value = event.target.closest("a").getAttribute("value");
        }

        this.setState({active: value});
        this
            .props
            .childProps
            .historyPush(value);
    };

    getbtnValue = btn => {
        let btnvalue = btn.target.value;

        let manualURL = null;
        switch (btnvalue) {
            case "MANUAL_OMNIPHONE":
                manualURL =this.props.childProps.systemParameters["MANUAL_OMNIPHONE"];
                break;
            case "MANUAL_OMNI_CUSCON":
                manualURL =this.props.childProps.systemParameters["MANUAL_OMNI_CUSCON"];
                break;
            case "MANUAL_OMNI_CUSCON_FLOOR":
                manualURL =this.props.childProps.systemParameters["MANUAL_OMNI_CUSCON_FLOOR"];
                break;
            default:
                break;
        }
        if (manualURL !==null) {
            var openwindow = window.open("about:blank");
            openwindow.location.href = manualURL;
        } else {
            alert(this.getErrorString({code:42}));
        }
    };
    
    render() {
        
        let {active} = this.state;

        let sideBarElems = this
            .getSideBarItems()
            .map((item, key) => {
                let returnData = null;
                let activeValue = active;
                if (key === 0 && this.props.childProps.getMyPath() === item.value) {
                    activeValue = item.value;
                    active = activeValue;
                }

                if (this.props.parentType === "lower") {
                    let activeClass = "sidebar-child";

                    if (activeValue === item.value) {
                        activeClass += " cuscon-active";
                    }

                    returnData = (
                        <Button
                            key={key}
                            className={activeClass}
                            id={item.value}
                            value={item.value}
                            onClick={this.handleClick("button")}
                            bsStyle="default"
                            bssize="2x">
                            <FontAwesomeIcon
                                className="sidebar-icon fa-fw"
                                icon={item.icon}
                                size="lg"/>
                            {item.text}
                        </Button>
                    );
                } else if (this.props.parentType === "upper") {
                    let activeClass = "sidebar-child btn btn-default";

                    if (activeValue === item.value) {
                        activeClass += " cuscon-active";
                    }

                    returnData = (
                        <NavItem
                            key={key}
                            className={activeClass}
                            id={item.value}
                            value={item.value}
                            onClick={this.handleClick("nav")}>
                            <FontAwesomeIcon
                                className="sidebar-icon fa-fw"
                                icon={item.icon}
                                size="lg"/>
                            {item.text}
                        </NavItem>
                    );
                }

                return returnData;
            }
            
            
            );

        // カスコンマニュアル フロアあり版orフロアなし版かの判別
        let floor_flg = null;        
        floor_flg = this.props.childProps.userInfo.floor_flg;

        let cuscon_manual_btn_val = "MANUAL_OMNI_CUSCON";
        if (floor_flg) {
            cuscon_manual_btn_val = "MANUAL_OMNI_CUSCON_FLOOR";
        }
        let manualElems =[];


        let omni_phone_manual_view_flag = true;
        let omni_cuscon_manual_view_flag = true;
        let manual_download_div_view_flag = true;

        if (this.props.childProps.systemParameters){
            //オムニフォン
            if (this.props.childProps.systemParameters["MANUAL_OMNIPHONE"] == "") {
                omni_phone_manual_view_flag = false;
            }
            //オムニカスコンフロア無し
            if (!floor_flg && this.props.childProps.systemParameters["MANUAL_OMNI_CUSCON"] == "") {
                omni_cuscon_manual_view_flag = false;
            }
            //オムニカスコンフロアあり
            if (floor_flg && this.props.childProps.systemParameters["MANUAL_OMNI_CUSCON_FLOOR"] == ""){
                omni_cuscon_manual_view_flag = false;

            }
            //全部非表示の場合
            if (!omni_phone_manual_view_flag && !omni_cuscon_manual_view_flag) {
                manual_download_div_view_flag = false;
            }
        }
        if (manual_download_div_view_flag) {
            manualElems.push(
                <div className="dltext">
                    {this.props.childProps.langText.SideBar.ManualDL}
                </div>
            );
            if (omni_phone_manual_view_flag) {
                manualElems.push(
                    <Button
                        className="omniphonebtn"
                        value="MANUAL_OMNIPHONE"
                        onClick={this.getbtnValue}
                        >
                        <img src={OmniphoneLogo}></img>
                        {this.props.childProps.langText.SideBar.OmniPhone}
                    </Button>
                );
            }
            if (omni_cuscon_manual_view_flag) {
                manualElems.push(
                    <Button
                    id="cusconbtn"
                        className="cusconbtn"
                        value={cuscon_manual_btn_val}
                        onClick={this.getbtnValue}
                    >
                        <img src={CusconLogo}></img>
                        {this.props.childProps.langText.SideBar.OmniCusCon}
                    </Button>
                );
            }
        }
      
        return (
            [
                sideBarElems,
                manualElems,
            ]            
        );

        
    };
};