import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import Favi from "../Elements/Favi/Favi";
import GroupExtensionNumberModal from "./GroupExtensionNumberModal";
import * as GlobalConst from "../../components/AscConstants";

export default class GroupExtensionNumber extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            extensionNumberSelect: [],

            // data item
            group_extension_number_id: "",
            company_id: "",
            company_name: "",
            department_id: "",
            department_name: "",
            floor_id: "",
            floor_name: "",
            group_extension_number: "",
            memo: "",
            extension_number: [],
            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []

            },
        };
        this.floor_load_flag = false;
    }

    getColumnsData() {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.id,
        });
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //oem権限以上の場合、会社名を表示
            itemArr.push({
            Header: this.props.langText.Body.CompanyName,
            accessor: "cm13_departments.cm12_companies.company_name",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.cm12_companies.company_name")} />
            });
        }

        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)){
            //会社権限以上の場合、拠点名を表示
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: "cm13_departments.department_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
            });
        }

        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            //システム権限または拠点管理権限のフロアありの場合、フロア名を表示
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: "cm13_departments.floor_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} />
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.GroupExtensionNumberName,
            accessor: "memo",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.GroupExtensionNumber,
            accessor: "group_extension_number",
            width: this.props.boardWidth.large,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "group_extension_number")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.ExtensionNumberNum,
            filterable: false,
            width: this.props.boardWidth.large,
            Cell: data => data.row._original.cm51_extension_numbers.length,
        });

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={this.modalShow("update", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil" />
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("read", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="eye-open" />
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={this.modalShow("delete", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus" />
                        </Button>
                    );
                }

                return (rowData);
            }
        });

        return itemArr;
    }

    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();

        try {
            this.blockUI();
            this.floor_load_flag = false;

            let setData = {
                group_extension_number_id: "",
                company_id: "",
                company_name: "",
                department_id: "",
                department_name: "",
                floor_id: "",
                floor_name: "",
                group_extension_number: "",
                memo: "",
                extension_number: [],
                companySelect: [],
                departmentSelect: [],
                extensionNumberSelect: []
            };

            if (param && param.id) {
                let modalInfo = await this.getModalInfo(param.id);
                param = modalInfo.data ? modalInfo.data[0] : null;
            }

            if (param) {
                setData.group_extension_number_id = param.id
                    ? param.id
                    : setData.inbound_group_info_id;
                setData.company_id = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.id
                    ? param.cm13_departments.cm12_companies.id
                    : setData.company_id;
                setData.floor_flg = param && param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.floor_flg
                    ? param.cm13_departments.cm12_companies.floor_flg === "Y"
                    : null;
                setData.company_name = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.company_name
                    ? param.cm13_departments.cm12_companies.company_name
                    : setData.company_name;
                setData.department_id = param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : setData.department_id;
                setData.department_name = param.cm13_departments && param.cm13_departments.department_name
                    ? param.cm13_departments.department_name
                    : setData.department_name;
                setData.department_prefix = param.cm13_departments && param.cm13_departments.department_prefix
                    ? param.cm13_departments.department_prefix
                    : setData.department_prefix;
                setData.floor_id = param.cm13_departments && param.cm13_departments.id ?
                    param.cm13_departments.id :
                    setData.department_id;
                setData.floor_name = param.cm13_departments && param.cm13_departments.floor_name ?
                    param.cm13_departments.floor_name:
                    setData.floor_name;
                setData.group_extension_number = param.group_extension_number
                    ? param.group_extension_number
                    : setData.group_extension_number;
                setData.memo = param.memo
                    ? param.memo
                    : setData.memo;
                setData.extension_number = param.cm51_extension_numbers
                    ? param
                        .cm51_extension_numbers
                        .map(row => {
                            return row.id;
                        })
                    : setData.extension_number;
            } else {
                if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                    setData.floor_flg = this.props.userInfo.floor_flg;
                    setData.company_id = this.props.userInfo.company_id;
                    setData.company_name = this.props.userInfo.company_name;
                }

                if (this.props.currentPermission.scope_code === GlobalConst.SCOPE_OBJECT.department.name) {
                    //新規作成時に拠点情報とフロア選択肢をセットする
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                    this.departmentSelectChanged({
                        company_id: setData.company_id,
                        department_prefix: this.props.userInfo.department_prefix,
                        floor_flg: this.props.userInfo.floor_flg
                    });
                }

                if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                    setData.floor_id = this.props.userInfo.department_id
                    setData.floor_name = this.props.userInfo.floor_name
                }

            }

            this.setState(setData);

            this.getCompanySelect();
            this.getDepartmentSelect(setData);

            if (setData.company_id !== "" && setData.department_id !== "") {
                this.getExtensionNumberSelect(setData);
            }

            this.setState({show: true, modalType});
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    };

    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    onSelectChange(event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";
        const department_prefix = (event && event.department_prefix) ? event.department_prefix : "";

        switch (param) {
            case "company_id":
                this.setState({department_id: "", floor_id: "", floor_flg, extension_number: [], extensionNumberSelect: []});
                this.getDepartmentSelect({company_id: value, floor_flg});
                this.departmentSelectChanged({
                    company_id: this.state.company_id,
                    department_prefix,
                    floor_flg
                });
                break;

            case "department_id":
                this.floor_load_flag = true;
                this.setState({floor_id: ""});
                this.departmentSelectChanged({
                    company_id: this.state.company_id,
                    department_prefix,
                    floor_flg: this.state.floor_flg
                });
                //内線を一覧取得のため、breakせずに処理を継続させる
            case "floor_id":
                this.setState({extension_number: []});
                let department_id = value,
                    group_type = this.state.group_type;
                this.getExtensionNumberSelect({department_id, group_type});
                break;

            default:
                break;

        }
    }

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({companySelect: res.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    getDepartmentSelect = ({company_id, floor_flg}) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this
            .ascAxios('post', `Common/departmentSelect`, {
                container: this.reactContainerPath,
                company_id,
                floor_flg})
            .then(res => {
                if(this.state.company_id)
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                }
                this.setState({departmentSelect: res.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    departmentSelectChanged = ({company_id, department_prefix, floor_flg}, action) => {
        if (floor_flg) {
            if(this.floor_load_flag === true && department_prefix && company_id)
            {
                this.common_buttondisabledLengthCheck(`floor_load`,`push`);
            }
            else
            {
                this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
            }
            this.ascAxios('post', `Common/floorSelect`, {
                container: this.reactContainerPath,
                company_id,
                department_prefix
            })
            .then(res => {
                if(department_prefix)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                this.setState({
                    floorSelect: res.data
                })

                if (action === 'changed') {
                    this.setState({
                        floor_id: "",
                    })
                }
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    getExtensionNumberSelect = ({department_id}) => {
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/extensionNumberSelect`,
                {department_id}
            )
            .then(res => {
                let extensionNumberSelectData = res.data.cm51_extension_numbers.map(row => {
                    let labeldata = <div>
                                        {row.label}
                                        <Favi
                                            className="pull-right fa-fw fa-icon fa-icon-static"
                                            icon={row.type}
                                        />
                                    </div>;
                    return {
                        search: row.label,
                        label: labeldata,
                        value: row.value
                    }
                });
                this.setState({extensionNumberSelect: extensionNumberSelectData});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    onClickHandle = modalType => event => {
        let {
            group_extension_number_id,
            department_id,
            floor_id,
            group_extension_number,
            memo,
            extension_number
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        department_id: floor_id || department_id,
                        group_extension_number,
                        memo,
                        extension_number,
                    })
                    .then(res => {
                        alert(
                            `${this.props.langText.Message.DataInsertSuccess}\r\n   ${this.props.langText.Body.GroupExtensionNumberName}: ${res.data.memo}\r\n   ${this.props.langText.Body.ExtensionNumberNum}: ${res.data.extension_count}`
                        );
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        group_extension_number_id,
                        department_id: department_id,
                        group_extension_number,
                        memo,
                        extension_number,
                    })
                    .then(res => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        );
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        group_extension_number_id,
                        memo,
                    })
                    .then(res => {
                        alert(
                            this.props.langText.Message.DataDeleteSuccess
                        );
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({code: "modal"}));
        }

        this.setState({show: false});
    };

    validationHandle = param => {
        let nowState = null;

        switch (param) {
            case "company_id":
            case "department_id":
            case "floor_id":
            case "memo":
                nowState = this.state[param]
                    ? null
                    : "error"
                break;

            case "group_extension_number":
                nowState = this.state[param].length === 3
                    ? null
                    : "error"
                break;
            case "insert":
            case "update":
            case "delete":
                let {
                    company_id,
                    department_id,
                    floor_id,
                    floor_flg,
                    group_extension_number,
                    memo,
                } = this.state
                if(!floor_flg)
                {
                    nowState = (
                        company_id
                        && department_id
                        && group_extension_number.length === 3
                        && memo
                    )
                }
                else
                {
                    nowState = (
                        company_id
                        && floor_id
                        && group_extension_number.length === 3
                        && memo
                    )
                }
                break;

            default:
                break;

        }

        return nowState;
    };

    render() {
        return (
            <div className="GroupExtensionNumber">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="groupExtensionNumber-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>
                <GroupExtensionNumberModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        onKeyDownIntCheck = {this.onKeyDownIntCheck}
                        validationHandle = {this.validationHandle}
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                />
            </div>
        );
    }
}