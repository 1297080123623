import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import CompanyModal from "./CompanyModal";

export default class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            pbxSelect: [],
            outboundPrefixSelect: this.getSelectOption('outbound_prefix', this.props.langText.SelectOption),

            //会社パラメータ
            companyControlParameters:[],
            //全体のパラメータ
            system_parameters_all: this.getCompanyControlParametersOption('ALL_PARAMETERS', this.props.langText.CompanyControlParametersOption),
            //コール履歴操作パラメータ
            system_call_record_option: this.getCompanyControlParametersOption('CALL_RECORD_OPTION', this.props.langText.CompanyControlParametersOption),
            
            // data item
            companyId: "",
            companyName: "",
            prefix: "",
            outboundPrefix: "",
            pbx: [],
            password: "",
            autoPass: false,
            memo: "",
            omniURL: "",
            // button_disabled
            buttondisabledArr: {
                "pbx" : []
            },
            passreset_flag : false,
        };

        
    }

    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.CompanyName,
                accessor: "company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "company_name")} />
            }, {
                Header: this.props.langText.Body.Prefix,
                accessor: "company_prefix",
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "company_prefix")} />
            }, {
                Header: this.props.langText.Body.OutboundPrefix,
                accessor: "outbound_prefix",
                width: this.props.boardWidth.small,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "outbound_prefix")} />
            }, {
                Header: this.props.langText.Body.Pbx,
                accessor: "cm92_pbx_infos",
                filterable: false,
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let pbxInfos = data.row.cm92_pbx_infos;
                    let elemArr = [];

                    pbxInfos.forEach((pbxData, key) => {
                        elemArr.push(
                            <p key={key}>
                                {pbxData.pbx_name}
                            </p>
                        );
                    });

                    return elemArr;
                }
            }, {
                Header: this.props.langText.Body.Password,
                accessor: "common_password",
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "common_password")} />
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                onClick={this.modalShow('update', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="pencil"/>
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={this.modalShow('read', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        );
                    }

                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                onClick={this.modalShow('delete', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="minus"/>
                            </Button>
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        ]
    }

    getPbxSelect = (companyId) => {
        this
            .ascAxios('post', `Common/pbxSelect`, {
                container: this.reactContainerPath,
                companyId
            })
            .then(result => {
                this.common_buttondisabledLengthCheck(`pbx`,`push`);
                this.setState({pbxSelect: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };
    getCompanyControlParameters = (cm12_id) => {
        this
            .ascAxios('post', `Common/getCompanyControlParameters`, {
                cm12_id,
            })
            .then(result => {
                this.setState({companyControlParameters: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };
    

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault();
        //会社システムパラメータ取得
        //デフォルト設定
        let setData = {
            companyId: param && param.id
                ? param.id
                : "",
            companyName: param && param.company_name
                ? param.company_name
                : "",
            prefix: param && param.company_prefix
                ? param.company_prefix
                : "",
            outboundPrefix: param && param.outbound_prefix
                ? param.outbound_prefix
                : "",
            outboundPrefixView: param && param.outbound_prefix
                ? this.state.outboundPrefixSelect.find(row => row.value === param.outbound_prefix)
                : "",
            pbx: param && param.cm92_pbx_infos
                ? param.cm92_pbx_infos.map(row => {return row.id})
                : [],
            password: param && param.common_password
                ? param.common_password
                : "",
            memo: param && param.memo
                ? param.memo
                : "",
            omniURL: param && param.omni_contact_url
                ? param.omni_contact_url
                : "",
            call_record_operation: param && param.call_record_operation
                ? param.call_record_operation
                : this.state.system_call_record_option[0].value,
            autoPass: false,
            companyControlParameters: [],
            modalType,
            show: true
        };

        this.setState(setData);
        this.getPbxSelect(setData.companyId);
        if (modalType != "insert") {
            this.getCompanyControlParameters(setData.companyId);    
        }
    };

    onClickHandle = modalType => event => {
        let {
            companyId,
            companyName,
            prefix,
            outboundPrefix,
            pbx,
            password,
            autoPass,
            memo,
            omniURL,
            companyControlParameters,
        } = this.state;

        //floor_flgはシステムパラメーターを採用
        const floor_flg = this.props.systemParameters.SYSTEM_FLOOR_FLG;


        switch (modalType) {
            case "insert":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        autoPass,
                        memo,
                        omniURL,
                        companyControlParameters,
                        floor_flg
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        memo,
                        omniURL,
                        companyControlParameters
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        memo,
                        omniURL
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;
            default:
                alert(this.getErrorString({code: 'modal'}));
        }

        this.setState({
            show: false
        });
    };

    validationHandle = param => {
        let nowState = null;

        let floor_flg = this.props.systemParameters.SYSTEM_FLOOR_FLG;

        switch (param) {
            case "companyName":
            case "memo":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "prefix":
                if (floor_flg === "Y") {
                    nowState = this.state[param].length === 2
                        ? null
                        : "error";
                } else {
                    nowState = this.state[param].length === 3
                        ? null
                        : "error";
                }
                break;

            case "pbx":
                nowState = this.state[param] && this.state[param].length
                    ? null
                    : "error";
                break;

            case "insert":
            case "update":
            case "delete":
                let {companyName, prefix, pbx,password,autoPass} = this.state;
                nowState = (
                    companyName && prefix.length >= 2 && pbx && pbx.length && (autoPass == true || password)
                );
                break;

            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="Company">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="company-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <CompanyModal
                    systemFloorFlg = {this.props.systemParameters.SYSTEM_FLOOR_FLG}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onIntChange = {this.onIntChange}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange_Init = {this.onCheckBoxChange_Init}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                    getCompanyControlParametersOption = {this.getCompanyControlParametersOption}
                    />
            </div>
        );
    }
}
