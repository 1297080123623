import * as React from 'react';
import { Col } from "react-bootstrap";
import { PortModelAlignment, PortWidget } from '@projectstorm/react-diagrams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faChevronCircleRight, faEllipsisH, faTrashAlt, faCogs } from "@fortawesome/free-solid-svg-icons";
import styled from '@emotion/styled';

// NODE DIV CSS
const DivNode = styled.div`
	background-color: ${(p) => p.color || "white"};
	border: ${(p) => p.borderColor || ""};
	border-color: #818181;
	position: relative;
	box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
	border-radius: 5px;
	cursor: pointer;
	width: 130px;
	height: 70px;
	padding: 5px;
`;

const DivLT = styled(Col)`
	position: absolute;
	top: 0;
	left: 0;
	margin: 5px;
`;

const DivLB = styled(Col)`
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 5px;
`;

const DivCT = styled(Col)`
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -30%);
	-ms-transform: translate(-50%, -30%);
`;

const DivCB = styled(Col)`
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-bottom: 3px;
	transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
`;

const DivRT = styled(Col)`
	position: absolute;
	top: 0;
	right: 0;
	margin: 5px;
`;

const DivRB = styled(Col)`
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 5px;
`;

const FontAwesomeMainIcon = styled(FontAwesomeIcon)`
	color: ${(p) => p.color || "rgba(108, 117, 113)"};
	&:hover {
		cursor: default;
	}
`;

const FontAwesomeSubIcon = styled(FontAwesomeIcon)`
	color: ${(p) => p.color || "rgba(108, 117, 113)"};
	&:hover {
		cursor: pointer;
		color: #404040;
	}
`;

const LabelFont = styled.label`
	color: #606060;
	font-size: ${(p) => p.size || "10px"};
`;

const ItemClickUl = styled.ul`
    position: absolute;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    list-style-type: none;
	padding: 3px;
	margin-left: 90%;
	background: white;
`;

export class ASNodeWidget extends React.Component {
	constructor(props) {
		super(props);

		this.node = this.props.node;
		this.node.widget = this;

		this.number = this.node.getOptions().number;
		this.icon = this.node.getOptions().icon;

		this.state = {
			color: this.default_color,
			borderColor: null,
			setting_flag: false,
			setting_clicked: false,
			click_timer: new Date()
		};
	}

	get default_color() {
		return this.node.checkValidation("menu") ? "white" : "#c8c8c8";
	}

	cuttingTitle(title) {
		return (title && title.length > 6) ? `${title.substr(0, 5)}...` : title;
	}

	onClick() {
		if (this.node.getOptions().menu !== false) {
			this.node.widgetSettingShow(false, this.node.models);
			this.node.menuEvent();
		}
	}

	render() {
		return (
			<DivNode
				onMouseDown={e => {
					this.setState({click_timer: new Date()});
				}}
				onMouseUp={e => {
					const {setting_clicked, click_timer} = this.state;
					const end_date = new Date();
					if (!setting_clicked && (end_date - click_timer) <= 100) this.onClick();
				}}
				color={this.state.color}
				borderColor={this.state.borderColor}>
				<DivLT>
					{Number.isInteger(this.number) && 
					<LabelFont>#{this.number}</LabelFont>
					}
				</DivLT>
				<DivLB 
					style={{display: this.node.getOptions().left_port === false ? "none" : "block"}}
					onMouseDown={e => {
						this.setState({setting_clicked: true});
						setTimeout(() => {
							this.setState({setting_clicked: false});
						}, 300);
					}}>
					<PortWidget
						port={this.node.getPort(PortModelAlignment.LEFT)}
						engine={this.props.engine}>
						<FontAwesomeSubIcon icon={faCircle} className="fa-fw fa-lg ivr-fa" color="#606060"/>
					</PortWidget>
				</DivLB>
				<DivCT>
					<FontAwesomeMainIcon icon={this.icon} className="fa-fw fa-2x" color="#606060"/>
				</DivCT>
				<DivCB>
					<LabelFont title={this.node.c_data.title}>{this.cuttingTitle(this.node.c_data.title)}</LabelFont>
				</DivCB>
				{this.node.getOptions().setting !== false &&
					<DivRT
						onMouseDown={e => {
							this.setState({setting_clicked: true});
							setTimeout(() => {
								this.setState({setting_clicked: false});
							}, 300);
						}}>
						<FontAwesomeSubIcon
							color="#606060"
							icon={faEllipsisH}
							className="fa-fw fa-lg"
							onClick={e => {
								this.node.widgetSettingShow(false, this.node.models);
								this.node.widgetSettingShow(!this.state.setting_flag);
							}}/>
					</DivRT>
				}
				<DivRB
					onMouseDown={e => {
						this.setState({setting_clicked: true});
						setTimeout(() => {
							this.setState({setting_clicked: false});
						}, 300);
						this.node.linkEvent();
					}}>
					<PortWidget
						port={this.node.getPort(PortModelAlignment.RIGHT)}
						engine={this.props.engine}>
						<FontAwesomeSubIcon icon={faChevronCircleRight} className="fa-fw fa-lg" color="#606060"/>
					</PortWidget>
				</DivRB>
				{this.state.setting_flag &&
					<ItemClickUl
						onMouseDown={e => {
							this.setState({setting_clicked: true});
							setTimeout(() => {
								this.setState({setting_clicked: false});
							}, 300);
						}}>
						<li onClick={e => this.node.menuEvent()}><FontAwesomeSubIcon icon={faCogs} className="fa-fw fa-16" color="#606060"/></li>
						<li onClick={e => this.node.removeEvent()}><FontAwesomeSubIcon icon={faTrashAlt} className="fa-fw fa-16" color="#606060"/></li>
					</ItemClickUl>
				}
			</DivNode>
		);
	}
}