import React, { Component } from "react";
import "./SetBreadCrumb.css";

/**
 * パンくずリストを表示
 * @props {Array} displayItems
 * displayItems={[
 *  {link: リンク先, name:表示名},//リンク付きで表示
 *  {name:表示名}//リンクなしで表示
 * ]}
 */
export default class SetBreadCrumb extends Component {
    /**
     * パンくずリストの作成
     */
    createBreadCrumb = () => {
        const BreadCrumb = [];
        this.props.displayItems.forEach((item, Index) => {
            if (item.link || item.onClick) {
                let elem;
                if (item.onClick) {
                    elem = <li key={Index}>
                        <a onClick={item.onClick}>{item.name}</a>
                    </li>;
                } else {
                    elem = <li key={Index}>
                        <a href={item.link}>{item.name}</a>
                    </li>;
                }
                BreadCrumb.push(elem);
            } else {
                BreadCrumb.push(<li key={Index}>{item.name}</li>);
            }
        });

        return (
            <ol className="breadcrumb">{BreadCrumb}</ol>
        );
    }

    render() {
        return (
            <nav aria-label="breadcrumb">
                {this.createBreadCrumb()}
            </nav>
        );
    }
}
