import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";

export default class SignInModal extends Component {
    getVerifyItem = () => {
        let {
            state,
            langText,
            onTextChange,
            validationHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm
                key = "emailVerify"
                controlId = "emailVerify"
                label = {langText.Body.EmailAddress}
                type = "text"
                value = {state.emailVerify}
                onChange = {e => onTextChange(e, "emailVerify")}
                validationState = {validationHandle("emailVerify")}/>
        );

        itemArr.push(
            <InlineForm
                key = "signUpCode"
                controlId = "signUpCode"
                label = {langText.Body.VerificationCode}
                type = "text"
                value = {state.signUpCode}
                onChange = {e => onTextChange(e, "signUpCode")}
                validationState = {validationHandle("signUpCode")}/>
        )

        return itemArr;
    }

    getResetPasswordItem = () => {
        let {
            state,
            langText,
            onTextChange,
            validationHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm
                key = "emailReset"
                controlId = "emailReset"
                label = {langText.Body.EmailAddress}
                type = "text"
                value = {state.emailReset}
                onChange = {e => onTextChange(e, "emailReset")}
                validationState = {validationHandle("emailReset")}/>
        );

        return itemArr;
    }

    getSubmitNewPassword = () => {
        let {
            state,
            langText,
            onTextChange,
            validationHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "emailReset"
                controlId = "emailReset"
                label = {langText.Body.EmailAddress}>
                {state.emailReset}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                key = "resetPasswordCode"
                controlId = "resetPasswordCode"
                label = {langText.Body.VerificationCode}
                type = "text"
                value = {state.resetPasswordCode}
                onChange = {e => onTextChange(e, "resetPasswordCode")}
                validationState = {validationHandle("resetPasswordCode")}/>
        );

        itemArr.push(
            <InlineForm
                key = "newPassword"
                controlId = "newPassword"
                label = {langText.Body.PasswordNew}
                type = "password"
                value = {state.newPassword}
                onChange = {e => onTextChange(e, "newPassword")}
                validationState = {validationHandle("newPassword")}/>
        );

        itemArr.push(
            <InlineForm
                key = "newPasswordRe"
                controlId = "newPasswordRe"
                label = {langText.Body.PasswordNewRe}
                type = "password"
                value = {state.newPasswordRe}
                onChange = {e => onTextChange(e, "newPasswordRe")}
                validationState = {validationHandle("newPasswordRe")}/>
        );

        return itemArr;
    }

    render() {
        let signInItem = null;

        if (this.props.state.modalType === "verify") {
            signInItem = this.getVerifyItem();
        } else if (this.props.state.modalType === "resetPassword") {
            signInItem = this.getResetPasswordItem();
        } else if (this.props.state.modalType === "submitNewPassword") {
            signInItem = this.getSubmitNewPassword();
        }

        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>
                        {signInItem}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}