import React from 'react';
import { ControlLabel, Panel, Checkbox } from "react-bootstrap";

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

export default class Number extends AscMenu {
    constructor(props) {
        super(props);

        this.audio_select = [{value: "none", label: this.props.langText.SelectOption.AudioNonePlay}, ...this.selectOptions.audio_type];

        if (this.state.data.timeout_audio_type === "file" && !this.state.data.timeout_audio_file.length) {
            this.state.data.timeout_audio_type = this.audio_select[0].value;
        }
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "audio_type",
                    audio_file: "audio_file",
                    audio_file_id: "audio_file_id",
                    audio_gen_language: "audio_gen_language",
                    audio_gen_person: "audio_gen_person",
                    audio_gen_text: "audio_gen_text"
                })}
                <ASFieldGroup
                    type="number"
                    label={this.props.langText.Body.ScriptNumLength}
                    value={this.state.data.digits}
                    onChange={e => {
                        let num_value = parseInt(e.target.value);
                        if (isNaN(num_value)) {
                            num_value = 1;
                        }
                        this.state.data.digits = num_value;
                        this.setState({data: this.state.data});
                    }}
                    min="1"/>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptInputDataCheck}>
                    <Checkbox
                        inline
                        style={{marginLeft: "10px"}}
                        checked={this.state.data.input_check_flag}
                        onChange={e => {
                            this.state.data.input_check_flag = !this.state.data.input_check_flag;
                            this.setState({data: this.state.data});
                        }}>
                        &nbsp;
                    </Checkbox>
                </ASFieldGroup>
                {this.state.data.input_check_flag &&
                    <Panel key={1} bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title><ControlLabel className="label-color-333333">{this.props.langText.Body.ScriptInputDataCheckSetting}</ControlLabel></Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <p style={{fontSize: "13px", color: "red"}}>{this.props.langText.Message.Script_input_check_help}</p>
                            {this.getAudioElem({
                                key: 1,
                                audio_label: this.props.langText.Body.Audio,
                                synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                                audio_type: "input_check_audio_type",
                                audio_file: "input_check_audio_file",
                                audio_file_id: "input_check_audio_file_id",
                                audio_gen_language: "input_check_audio_gen_language",
                                audio_gen_person: "input_check_audio_gen_person",
                                audio_gen_text: "input_check_audio_gen_text"
                            })}
                            <ASFieldGroup
                                label={this.props.langText.Body.ScriptRepeat}>
                                <ASSelect
                                    value={this.state.data.input_check_decision_number}
                                    options={this.selectOptions.select_number}
                                    onChange={e => {
                                        this.state.data.input_check_decision_number = e.value;
                                        this.setState({data: this.state.data});
                                    }}/>
                            </ASFieldGroup>
                        </Panel.Body>
                    </Panel>
                }
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title><ControlLabel className="label-color-333333">{this.props.langText.Body.ScriptTimeoutSetting}</ControlLabel></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getAudioElem({
                            key: 2,
                            audio_type_select: this.audio_select,
                            audio_label: this.props.langText.Body.Audio,
                            synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                            audio_type: "timeout_audio_type",
                            audio_file: "timeout_audio_file",
                            audio_file_id: "timeout_audio_file_id",
                            audio_gen_language: "timeout_audio_gen_language",
                            audio_gen_person: "timeout_audio_gen_person",
                            audio_gen_text: "timeout_audio_gen_text"
                        })}
                        <ASFieldGroup
                            label={this.props.langText.Body.RunType}>
                            <ASSelect
                                value={this.state.data.timeout_event}
                                options={this.selectOptions.next_session_event}
                                onChange={e => {
                                    this.state.data.timeout_event = e.value;
                                    this.setState({data: this.state.data});
                                }}/>
                        </ASFieldGroup>
                        <ASFieldGroup
                            type="number"
                            label={this.props.langText.Body.Second}
                            value={this.state.data.timeout_seconds}
                            onChange={e => {
                                let num_value = parseInt(e.target.value);
                                if (isNaN(num_value)) {
                                    num_value = 1;
                                }
                                this.state.data.timeout_seconds = num_value;
                                this.setState({data: this.state.data});
                            }}
                            min="1"/>
                    </Panel.Body>
                </Panel>
            </>
        );
    }
}