// item
import React from "react";
import {Route, Switch} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

// controller
import Gateway from "./containers/Gateway/Gateway";
import Pbx from "./containers/Pbx/Pbx";
import SignIn from "./containers/SignIn/SignIn";
import ExternalNumber from "./containers/ExternalNumber/ExternalNumber";
import ExtensionNumber from "./containers/ExtensionNumber/ExtensionNumber";
import BusinessCalendar from "./containers/BusinessCalendarV2/BusinessCalendar";
import InboundGroup from "./containers/InboundGroup/InboundGroup";
import GroupExtensionNumber from "./containers/GroupExtensionNumber/GroupExtensionNumber";
import OutboundReject from "./containers/OutboundReject/OutboundReject";
import InboundReject from "./containers/InboundReject/InboundReject";
import Company from "./containers/Company/Company";
import Department from "./containers/Department/Department";
import Floor from "./containers/Floor/Floor";
import User from "./containers/User/User";
import VoiceFile from "./containers/VoiceFile/VoiceFile";
import Customer from "./containers/Customer/Customer";
import CallHistory from "./containers/CallHistory/CallHistory";
import Permission from "./containers/Permission/Permission";
import Script from "./containers/Script/Script";

import Forbidden from "./containers/Forbidden/Forbidden";


/**
 * コントローラー追加したらsrc/components/AscConstants.jsにも記載すること
 * @param childProps
 * @returns {JSX.Element}
 */
export default ({childProps}) =>
    <Switch>
            <AppliedRoute path="/Gateway" exact component={Gateway} props={childProps}/>
            <AppliedRoute path="/Pbx" exact component={Pbx} props={childProps}/>
            <AppliedRoute path="/SignIn" exact component={SignIn} props={childProps}/>
            <AppliedRoute path="/ExternalNumber" exact component={ExternalNumber} props={childProps}/>
            <AppliedRoute path="/ExtensionNumber" exact component={ExtensionNumber} props={childProps}/>
            <AppliedRoute path="/BusinessCalendar" exact component={BusinessCalendar} props={childProps}/>
            <AppliedRoute path="/InboundGroup" exact component={InboundGroup} props={childProps}/>
            <AppliedRoute path="/GroupExtensionNumber" exact component={GroupExtensionNumber} props={childProps}/>
            <AppliedRoute path="/OutboundReject" exact component={OutboundReject} props={childProps}/>
            <AppliedRoute path="/Company" exact component={Company} props={childProps}/>
            <AppliedRoute path="/Department" exact component={Department} props={childProps}/>
            <AppliedRoute path="/Floor" exact component={Floor} props={childProps}/>
            <AppliedRoute path="/InboundReject" exact component={InboundReject} props={childProps}/>
            <AppliedRoute path="/User" exact component={User} props={childProps}/>
            <AppliedRoute path="/VoiceFile" exact component={VoiceFile} props={childProps}/>
            <AppliedRoute path="/Customer" exact component={Customer} props={childProps}/>
            <AppliedRoute path="/CallHistory" exact component={CallHistory} props={childProps}/>
            <AppliedRoute path="/Permission" exact component={Permission} props={childProps}/>
            <AppliedRoute path="/Script" exact component={Script} props={childProps}/>

            <Route component={Forbidden}/>
    </Switch>
